#dashboard.User {
	.main-wrapper {
		padding-top: 148px;
		@include media-breakpoint-down(lg) {
			padding-top: 74px;
		}
	}
	.scrollFixed {
		top: 148px;
	}
	.info-feed-section-active {		
		@include media-breakpoint-up(lg) {
			padding-top: 218px;
		}		
		.scrollFixed {
			top: 218px;
			@include media-breakpoint-down(lg) {
				top: 70px;
			}
		}
		.info-feed-section {
			display: block;
		}
	}
	.modal-header-sm {
		border-bottom: 1px solid $border-color;
		padding: .5rem 1rem;
		.close {
			width: 25px;
			height: 25px;
		}
	}
	.modal-footer {
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}
	.user-details-popup {
		.btn-modal-header {
			.btn-close {
				width: 50px !important;
				height: 50px !important;
				background-color: $white !important;
				&::before {
					content: none;
				}
			}
		}
	}
	.tab-content {
		@include media-breakpoint-down(lg) {
			background-color: transparent;
			box-shadow: none;
		}
	}
	#courseSearch {
		@include media-breakpoint-up(xl) {
			width: 385px;
		}
	}
	.event-slide {
		.carousel-item {
			backface-visibility: inherit;
		}
		@include media-breakpoint-down(lg) {
			/*.media {
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}*/
			.flex-grow-1 {
				padding: $spacer;
				text-align: center;
				.d-inline {
					display: block !important;
				}
			}
		}
	}
	.carousel-control-section {
		background-color: $gray-100;
		display: flex;
		align-items: center;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		.carousel-control {
			padding: 0 .639rem;
			margin-bottom: 2px;
			.feather {
				stroke: $gray-400;
				width: 20px;
				height: 20px;
			}
		}
	}
} 

.modal-quote-description {
	max-height: 30vh;
    overflow: auto;
}

// User setting crop image and form style
#user_settings.User {
	.modal-header-sm {
		border-bottom: 1px solid $border-color;
		padding: .5rem 1rem;
		.close {
			width: 25px;
			height: 25px;
		}
	}
	.form-row { 
		> .col,
		> [class*="col-"] {
		  padding-right: $form-grid-gutter-width;
		  padding-left: $form-grid-gutter-width;
		}
	}
}

// Choose files
.upload-images-view,
.upload-images-video,
.upload-images-view-exp {
	max-height: 250px;
	overflow-y: auto;
	//padding-bottom: 1rem;
	margin: 1rem 0;
	border-bottom: $border-width solid $border-color;
	@include media-breakpoint-down(lg) {
		max-height: 138px;
		overflow: auto;
	}
}

.upload-images-view-exp-new {
	max-height: 250px;
	overflow-y: auto;
	//padding-bottom: 1rem;
	margin: 1rem 0;
	border-bottom: $border-width solid $border-color;
	@include media-breakpoint-down(lg) {
		max-height: 138px;
		overflow: auto;
	}
}

.list-upload-images,
.list-upload-video,
.list-upload-images-exp {
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
	.upload-image-cover {
		overflow: hidden;
    	position: relative;
	}
	.list-upload-image-item {
		display: inline-flex;
		overflow: hidden;
		border-radius:$border-radius ;
		position: relative;
		//width: 175px;
		//height: 120px;
		margin-right: 1rem;
		margin-bottom: 1rem;
		//object-fit: cover;
		background-color: $gray-100;
		.img-delete {
			width: 25px;
			height: 25px;			
			position: absolute;
			right: 1rem;
			top: 1rem;
			background-color: $white;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.feather {
				stroke: $gray-400;
				width: 18px;
				height: 18px;
			}
		}
		.list-upload-file-icon {
			width: 169px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.icon-file {
			width: 48px;
			height: 48px;
			stroke: $gray-400;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		.list-upload-image {
			max-width: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 120px;
		}
	}
}

.list-upload-images-exp-new {
	display: inline-block;
	list-style: none;
	padding: 0;
	margin: 0;
	.upload-image-cover {
		overflow: hidden;
		position: relative;
	}
	.list-upload-image-item-new {
		display: inline-flex;
		overflow: hidden;
		border-radius:$border-radius ;
		position: relative;
		//width: 175px;
		//height: 120px;
		margin-right: 1rem;
		margin-bottom: 1rem;
		//object-fit: cover;
		background-color: $gray-100;
		.img-delete {
			width: 25px;
			height: 25px;
			position: absolute;
			right: 1rem;
			top: 1rem;
			background-color: $white;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.feather {
				stroke: $gray-400;
				width: 18px;
				height: 18px;
			}
		}
		.list-upload-file-icon {
			width: 169px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.icon-file {
			width: 48px;
			height: 48px;
			stroke: $gray-400;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		.list-upload-image {
			max-width: none;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			height: 120px;
		}
	}
}

#ideabox,
#discussions {
	&.User {
		.upload-images-view,.upload-images-video,.upload-images-view-exp {
			max-height: inherit;
			overflow-y: hidden;
			border-bottom: none;
		}
	}
}

.form-share-experience {
	@include media-breakpoint-down(lg) {
		max-height: 230px;
	}
}

.modal-share {
	.modal-header {
		padding: 1rem 1rem 0 !important;
		height: 54px;
		@include media-breakpoint-down(lg) {
			display: flex !important;
		}
	}
	.modal-body {
		background-color: #fff !important;
		border-radius: 8px !important;		
		padding: 1rem !important;
		//@include media-breakpoint-up(lg) {
			overflow: initial !important;
			max-height: inherit !important;
		//}
		.modal-avatar {
			margin-bottom: 1rem;
			//@include media-breakpoint-up(lg) {
				position: absolute;
				top: -41px;
				margin-bottom: 0;
			//}
		}
	}
	.mobile-share-form {
		@include media-breakpoint-down(lg) {
			height: 100%;
		}
	}

	.list-course-module {
		@include media-breakpoint-between(md, lg) {
			display: block;
		}
		@include media-breakpoint-down(md) {
			justify-content: center;
		}
		.list-course-module-item {
			@include media-breakpoint-between(md, lg) {
				margin-right: 0;
				margin-bottom: $spacer;
			}
		}

	}
}

// user setting page
.upload-user-thumbnail {
	position: relative;
	.feather {
		position: absolute;
		stroke: $white;
		bottom: 1rem;
		left: 1rem;
		margin: 0;
		width: 22px;
		height: 22px;
	}
}

// twitter and what's new feeds
.info-feed-section {
		display: none;
		position: fixed;
		z-index: $zindex-sticky;
		top: 148px;
		left: 0;
		right: 0;
		background-color: $body-bg;
		padding: 10px 0 15px;
		@include media-breakpoint-down(lg) {
			display: none !important;
		}
}

// Popup Courses, Events, Month Courses and Month Events in dashboard
.events-text-header h3 {
	font-size: $font-size-lg;
	@include media-breakpoint-down(lg) {
		font-size: $font-size-sm;
	}
}
.media-icon-item { 
	height: 180px;
	min-width: 200px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: $gray-200;
	border-top-left-radius: $border-radius-sm;
	border-bottom-left-radius: $border-radius-sm;
	@include media-breakpoint-down(lg) {
		margin: $spacer 0 0 !important;
		border-radius: $border-radius;
		height: 110px;
		min-width: 150px;
		width: 150px;
	}
	@include media-breakpoint-down(xs) {
		height: 82px;
		min-width: 110px;
		width: 110px;
	}
	/*@include media-breakpoint-down(xxs) {
		height: 72px;
		width: 82px;
	}*/
	.feather {
		stroke: $gray-400;
		width: 60px;
		height: 60px;
		@include media-breakpoint-down(lg) {
			width: 50px;
			height: 50px;
		}
		@include media-breakpoint-down(xs) {
			width: 42px;
    		height: 42px;
		}
	}
}

.profile-img {
	@include media-breakpoint-up(xxxl) {
		min-height: 278px;
		//background: url('../../img/preloader.svg') center center no-repeat;
		position: relative;	
	}
}

// User details popup box
.user-details-popup {
	background-color: transparent;
	@include media-breakpoint-down(lg) {
		top: 70px !important;
	}
	.modal-header {
		padding: 0 !important;
		height: inherit !important;
	}
	.modal-body {
		padding: 1rem !important;
		@include border-radius($border-radius);
	}
	.user-details-thumbnail {
		border-radius: $border-radius;
		font-size: $h1-font-size;
		//font-weight: $display4-weight;
		color: $white;
		width: 100%;
		height: 100%;
		&:focus {
			text-decoration: none;
		}
	}
}
.list-user-detail {
	list-style: none;
	padding: 0;
	margin: 0;
	.list-user-detail-items {
		font-size: $font-size-lg;
		line-height: 1;
		margin-bottom: 23.5px;
		@include media-breakpoint-down(lg) {
			font-size: $font-size-base;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.click-disable-mobile {
	@include media-breakpoint-down(lg) {
		pointer-events: none;
	}
}

.list-user-assigned {
	list-style: none;
	padding: 0;
	margin: 0;
	.list-user-assigned-item {
		padding: 1rem 0;
		font-size: $h4-font-size;
		//line-height: 1;
		border-bottom: $border-width solid $ghost;
		&:last-child {
			border-bottom: transparent;
			padding-bottom: 0;
		}
		.chevron-right {
			stroke:$gray-400;
		}
	}
	.icon-assigned-item {
		background-color: $white;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
	}
}

.user-assigned-view {
	max-height: 264px;
	overflow-x: auto;
	@include media-breakpoint-down(xl) {
		max-height: 260px;
	}
}

// User details view
.user-details-view {
	margin-bottom: 1rem;
	.icon-user-details {
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		max-width: 40px;
		max-height: 40px;
		background-color: $gray-200;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.feather {
		stroke: $gray-400;
		width: 20px;
		height: 20px;
	}
}

//
.list-lable-due-date {
	border-radius: $border-radius-sm;
	background-color: $iron;
	color: $pale-sky;
	padding: .572rem;
}

.list-lable-past-due {
	border-radius: $border-radius-sm;
	background-color: $we-peep;
	color: $hibiscus;
	padding: .572rem;
}

// admin dashboard card min height
.card-widgets-min-height-sm {
	min-height: 258px;
}

.card-widgets-min-height {
	min-height: 263px;
}

#admin_dashboard {
	&.Admin-panel {
		.breadcrumb-panel-fixed {
			@include media-breakpoint-down(lg) {
				display: block;
				position: relative;
				top: auto;
			}
			.container {
				@include media-breakpoint-down(lg) {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}
		.btn-dropdown-toggle {
			box-shadow: none !important;
            @include media-breakpoint-down(lg) {
                width: 100%;
            }
        }
	}
}

#admin_leaderboard {
	&.Admin-panel {
		.btn-dropdown-toggle {
			box-shadow: none !important;
			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}
	}
}

// layupX
#dashboard {
	&.due-dashboard {
		.main-wrapper {
			@include media-breakpoint-down(md) {
				padding-top: 130px;
			}
		}
		.due-dashboard-tab-view {
			@include media-breakpoint-down(md) {
				position: fixed;
				top: 60px;
				padding: $grid-gutter-width;
				left: 0;
				right: 0;
			}
			.nav-tabs {
				@include media-breakpoint-down(md) {
					width: 100%;
				}
				.nav-item {
					@include media-breakpoint-down(md) {
						flex: 1 1 auto;
						&:first-child {
							.nav-link {
								border-top-left-radius: $nav-pills-border-radius;
								border-bottom-left-radius: $nav-pills-border-radius;
							}
						}
						&:last-child {
							.nav-link {
								border-top-right-radius: $nav-pills-border-radius;
								border-bottom-right-radius: $nav-pills-border-radius;
							}
						}
					}
				}
				.nav-link {
					@include media-breakpoint-down(md) {						
						background-color: $gray-400;
						color: $white;
						line-height: 1;
						padding: .929rem 1.45rem;
						border-radius: 0;
						font-size: $font-size-xs;
					}
					&.active {
						@include media-breakpoint-down(md) {
							background-color: $gray-500;
						}
					}
				}
			}			
		}
	}
}

// global search view page
#global_search_post_display {
	.global-search-view {
		padding-top: 148px;
	}
	
	.scroll-fixed-global {
		top: 148px;
	}
}