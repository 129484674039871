#quizup_question.User {
	.main-wrapper {
		@include media-breakpoint-up(lg){
			padding-top: 48px !important;
		}
	}
	.main-nav {
		@include media-breakpoint-up(lg){
			display: none !important;
		}
	}
}

.quizup-user-name {
	@include media-breakpoint-up(lg){
		font-size: $h3-font-size;
	}
}

.quizup-user-max-width{
	max-width: calc(100% - 60px);
	@include media-breakpoint-up(lg){
		max-width: calc(100% - 167px);
	}
}

.quizup-user-points {
	width: 70px;
	height: 70px;
	border-radius: 100%;
	display: flex;
	align-items: center;
    justify-content: center;
	font-size: $font-size-xl;
	//font-weight: $font-weight-semibold;
	font-family: 'Poppins SemiBold';
}

.user-points-secondary {
	background-color: $french-pass;
	color: $blue;
}

.user-points-success {
	background-color: $jagged-ice;
	color: $green;
}

.card-quizup-points {
	min-height: 68px;
	@include media-breakpoint-up(lg){
		min-height: 98px;
	}
}

.btn-quizup-lg {
	min-width: 175px;
}

.img-quizup {
	width: 380px;
}

.quizup-select-user-name {
	max-width: 100px;
	@include media-breakpoint-up(xs){
		max-width: 120px
	}
	@include media-breakpoint-up(md){
		max-width: 180px
	}
}

.quizup-question-points {
	font-size: $h3-font-size;
	@include media-breakpoint-up(lg){
		font-size: $h2-font-size;
	}
}

#quizup_user_list.User {
	.modal-header {
		display: none;
	}
}