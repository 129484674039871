.table {
    background-color: $white;
    box-shadow: $box-shadow;
    thead {
        th,
        td {
            //border-bottom-width: 0;
            font-size: $font-size-base;
            //font-weight: $font-weight-medium;
            font-family: 'Poppins Medium';
            height: 40px;
            line-height: 1;
            padding: 0.95rem 1rem;
            border-bottom: 1px solid $table-border-color !important;
        }
    }
    tbody {
        td {
            font-size: $font-size-base;
            vertical-align: middle;
            height: 50px;
            padding: $table-cell-padding-y $table-cell-padding-x;
        }
    }
}

.tableExtract,
.tableExtractCourse {
    cursor: pointer;
}


/*.innerModules > td {
    border: 0;
}*/

.table-status {
    width: 180px !important;
}

//points breakdown

#pointsBreakdown, #PointsTabContent{
    .table {
        thead {
            th{
                min-width: 200px;
            }
        } 
    }   
}

//quizup 
.table-quizup-view {
    @include media-breakpoint-down(lg) {
        background-color: transparent;
    }
    @include media-breakpoint-up(md) {
        box-shadow: $box-shadow;
    }
}

.table-quizup {
    @include media-breakpoint-down(lg) {
        background-color: transparent;
        border-collapse: separate;
        border-spacing: 0 1rem;
    }
    thead {
        th {
            padding: .95rem 0.75rem;
            line-height: 1;
            height: auto;
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    th.status {
        width: 140px;
    }
    tbody {
        tr {
            @include media-breakpoint-down(lg) {
                background-color: $white;
            }
        }
        td {
            padding: 1rem 0.75rem;
            height: auto;
            @include media-breakpoint-down(lg) {
                border: none;
                padding: 1rem;
                &:first-child {
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;
                }
                &:last-child {
                    border-top-right-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }
        }
    }
    .mobile-td-hide {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
    .mobile-td-padding-sm {
        @include media-breakpoint-down(lg) {
            padding: 5px;
        }
    }
    .mobile-td-right-padding-hide {
        @include media-breakpoint-down(lg) {
            padding-right: 0;
        }
    }
    /*tr.table-separator {
		display: none;
		@include media-breakpoint-down(lg) {
			display: block;
			background-color: transparent;
		}
	}*/
}

.points-breakdown-table-line {
    border-right: 1px solid $table-border-color;

}

//discussions and ideabox datatables

#discussions, #ideabox{
    .dataTable{
        width: 100% !important;

        .wall-card-header{
                max-width: 95%;
        }
    }
}