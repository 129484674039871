.imgs-grid {
  /*max-width: 800px;
  margin: 0 auto 1rem;
  font-size: 0;*/
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;
}
.imgs-grid.imgs-grid-1 .imgs-grid-image {
  width: 100%;
  text-align: center;
}
.imgs-grid.imgs-grid-2 .imgs-grid-image,
.imgs-grid.imgs-grid-4 .imgs-grid-image {
  width: 50%;
}
.imgs-grid.imgs-grid-3 .imgs-grid-image,
.imgs-grid.imgs-grid-6 .imgs-grid-image {
  width: 33.333333333333336%;
}

.imgs-grid-image {
    padding: 7px;

    @include media-breakpoint-down(sm) {
      padding: 4px;
    }

}

.imgs-grid-1,
.imgs-grid-2,
.imgs-grid-3,
.imgs-grid-4,
.imgs-grid-5,
.imgs-grid-6 {
  .imgs-grid-image {
    margin-bottom: 1rem;
    }
}

.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(1),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(2),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(3) {
  width: 33.333333333333336%;
}
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(4),
.imgs-grid.imgs-grid-5 .imgs-grid-image:nth-child(5) {
  width: 50%;
}
.imgs-grid .imgs-grid-image {
  position: relative;
  display: inline-block;
  //padding: 1px;
  box-sizing: border-box;
  text-align: center;
}
/*
.imgs-grid .imgs-grid-image:before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  background-color: #f0f0f0;
}
*/

.imgs-grid .imgs-grid-image:hover {
  cursor: pointer;
}
.imgs-grid .imgs-grid-image .image-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: $border-radius;
}
.imgs-grid .imgs-grid-image .image-wrap img {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: $border-radius;
  overflow: hidden;
}

.image-type-gif {
  position: absolute;
  bottom: 9px;
  left: 9px;
  background-color: rgba($black,.5);
  color: $white;
  font-size: $font-size-xs;
  padding: 1px 9px;
  border-radius: $border-radius-sm;
}

/*
//
#discussions,
#ideabox {
  &.User {
    .commentsWrapper{
      .image-wrap img {
        top: 0 !important;
        height: 90px;
      }
    }
  }
}
// activity feed
.comment-attachment-list {
  .image-wrap img {
    top: 0 !important;
    height: 90px !important;
  }
}*/

.imgs-grid .imgs-grid-image .view-all {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.imgs-grid .imgs-grid-image .view-all:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  height: 100%;
}

.imgs-grid .imgs-grid-image .view-all:hover {
  cursor: pointer;
}
.imgs-grid .imgs-grid-image .view-all:hover .view-all-text {
  text-decoration: none;
}
.imgs-grid .imgs-grid-image .view-all .view-all-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blue;
  opacity: 0.7;
}
.imgs-grid .imgs-grid-image .view-all .view-all-text {
  position: relative;
  font-size: $h2-font-size;
  color:$white;
  //font-weight: $font-weight-semibold;
	font-family: 'Poppins SemiBold';
  top: 7px;
}
@media (max-width: 350px) {
  .imgs-grid .imgs-grid-image .view-all .view-all-text {
    font-size: 10px;
  }
}
.imgs-grid-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($modal-backdrop-bg,1);
  //opacity: $modal-backdrop-opacity;
  z-index: 2500;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.imgs-grid-modal .modal-caption {
  padding: 30px 50px;
  text-align: center;
  color: white;
}
.imgs-grid-modal .modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 35px;
  height: 35px;
  background-image: url(../../img/imagesGrid/imgs-grid-icons.png);
  background-repeat: no-repeat;
  background-position: -100px;
  z-index: 1195;
}
.imgs-grid-modal .modal-close:hover {
  cursor: pointer;
}
.imgs-grid-modal .modal-inner {
  position: absolute;
  top: 60px;
  bottom: 60px;
  left: 0;
  right: 0;
}
.imgs-grid-modal .modal-inner .modal-control {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 70px;
}
.imgs-grid-modal .modal-inner .modal-control:hover {
  cursor: pointer;
}
.imgs-grid-modal .modal-inner .modal-control.left {
  left: 0;
}
.imgs-grid-modal .modal-inner .modal-control.right {
  right: 0;
}
.imgs-grid-modal .modal-inner .modal-control .arrow {
  margin: 0 auto;
  height: 100%;
  width: 40px;
  background-repeat: no-repeat;
  background-image: url(../../img/imagesGrid/imgs-grid-icons.png);
}
.imgs-grid-modal .modal-inner .modal-control .arrow.left {
  background-position: 2px center;
}
.imgs-grid-modal .modal-inner .modal-control .arrow.right {
  background-position: -42px center;
}
.imgs-grid-modal .modal-inner .modal-image {
  position: absolute;
  top: 0;
  left: 70px;
  right: 70px;
  bottom: 0;
  text-align: center;
}
.imgs-grid-modal .modal-inner .modal-image:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  height: 100%;
}
.imgs-grid-modal .modal-inner .modal-image img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
.imgs-grid-modal .modal-inner .modal-image img:hover {
  cursor: pointer;
}
.imgs-grid-modal .modal-inner .modal-loader {
  display: inline-block;
  vertical-align: middle;
  color: silver;
  font-size: 14px;
}
@media (max-width: 800px) {
  .imgs-grid-modal .modal-inner .modal-control {
    width: 40px;
  }
  .imgs-grid-modal .modal-inner .modal-control .arrow {
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }
  .imgs-grid-modal .modal-inner .modal-image {
    left: 0;
    right: 0;
  }
}
.imgs-grid-modal .modal-indicator {
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  text-align: center;
}
.imgs-grid-modal .modal-indicator ul {
  margin: 0;
  padding: 0;
}
.imgs-grid-modal .modal-indicator ul li {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 100%;
  margin: 0 3px !important;
  vertical-align: middle;
}
.imgs-grid-modal .modal-indicator ul li:hover {
  cursor: pointer;
}
.imgs-grid-modal .modal-indicator ul li.selected {
  background-color: white;
  width: 14px;
  height: 14px;
  margin: 0;
}

// admin modarate section
#admin_approvals {
  &.Admin-panel {
    .imgs-grid-modal {
      background-color: rgba($primary, .2);
      .modal-indicator,
      .modal-caption {
        display: none;
      }
      .modal-inner {
        .modal-image {
          width: fit-content;
          //max-width: $modal-lg;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);

          min-width: 70vh;
          min-height: 70vh;

          @include media-breakpoint-down(lg) {
            min-width: auto;
            min-height: auto;
          }

            &.doc{
              width: 75vw;
              height: 80vh;
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;
            }
           
            &.video{
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;

              &::before{
                display: none;
              }
              span{
                position: relative;
  
                #videoPlayer{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          
            img{
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;
            }

            .modal-loader{
              position: absolute;
              left: 50%;
              top: 50%;
              width: 400px !important;
              height: 300px;
              background-color: #fff;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;

              img{
                background: transparent;
                padding: 0;
              }

            }
            

        }
      }
      .modal-control {
        width: 50px;
        &.left {
          left: 2rem;
        }
        &.right {
          right: 2rem;
        }
        .arrow {
          width: 50px;
          &.left {
            background-image: url(../../img/imagesGrid/arrow-left.png);
            background-position: 0 center;
          }
          &.right {
            background-image: url(../../img/imagesGrid/arrow-right.png);
            background-position: 0 center;
          }
        }        
      }
      .modal-close {       
        right: 2rem;
        top: 2rem;
        background-image: none;
        width: 50px;
        height: 50px;
        &::before {
          content: url(../../img/x.svg);
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 1195;
          width: 24px;
          height: 24px;
        }
        &::after {
          content:"";
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: $white;
          position: absolute;
        }
      }
    }
    .imgs-grid {
      margin-top: 1rem;
      flex-wrap: nowrap;
      &.imgs-grid-1,
      &.imgs-grid-2,
      &.imgs-grid-3,
      &.imgs-grid-4,
      &.imgs-grid-5 {
        .imgs-grid-image {
          text-align: left;          
          width: auto; 
        }
      }
    }
  }
}                 

#discussions, 
#ideabox,
.comment-attachment-list {

  .disAttachments{
    .imgs-grid-1{
      @include media-breakpoint-up(lg) {
        max-width: 20%;
      }
    }
    .imgs-grid-2{
      @include media-breakpoint-up(lg) {
        max-width: 40%;
      }
    }
    .imgs-grid-3{
      @include media-breakpoint-up(lg) {
        max-width: 60%;
      }
    }
    .imgs-grid-4{
      @include media-breakpoint-up(lg) {
        max-width: 80%;
      }
    }
  }

  .imgs-grid{
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    &.imgs-grid-1{
      
      .imgs-grid-image{
        text-align: left;
        width: auto;
        
      }
    }
    &.imgs-grid-2{
      
      .imgs-grid-image{
        text-align: left;
        width: auto;         
      }
    }
    &.imgs-grid-3{
      
      .imgs-grid-image{
        text-align: left;
        width: auto;        
      }
    }
    &.imgs-grid-4{
      
      .imgs-grid-image{
        text-align: left;
        width: auto;
      }
    }
    &.imgs-grid-5{
      
      .imgs-grid-image{
        text-align: left;
        width: auto;
       
      }
    }
  }

}

.comment-attachment-list {
  .imgs-grid-image {
    margin-bottom: 0;
  }
}

#discussions, #ideabox, #dashboard, #library_item, #global_search_post_display {
    .imgs-grid-modal {
      background-color: rgba($primary, .2);
      .modal-indicator,
      .modal-caption {
        display: none;
      }
      .modal-inner {
        .modal-image {
          width: fit-content;
          //max-width: $modal-lg;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);

          min-width: 70vh;
          min-height: 70vh;
          
          @include media-breakpoint-down(lg) {
            min-width: calc(100vw - 60px);
          }

          @include media-breakpoint-down(sm) {
            min-width: calc(100vw - 40px);
          }

            &.doc{
              width: 75vw;
              height: 80vh;
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;
            }
           
            &.video{
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;

              &::before{
                display: none;
              }
              span{
                position: relative;
  
                #videoPlayer{
                  width: 100%;
                  height: 100%;
                }
              }
            }
          
            img{
              border-radius: $border-radius;       
              background-color: $white;
              padding: 1rem;
            }

            .modal-loader{
              position: absolute;
              left: 50%;
              top: 50%;
              width: 400px !important;
              height: 300px;
              background-color: #fff;
              transform: translate(-50%, -50%);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;

              img{
                background: transparent;
                padding: 0;
              }

            }
            

        }
      }
      .modal-control {
        width: 50px;
        &.left {
          left: 2rem;
          @include media-breakpoint-down(lg) {
            left: 1rem;
          }
          @include media-breakpoint-down(sm) {
            left: 0rem;
          }
        }
        &.right {
          right: 2rem;
          @include media-breakpoint-down(lg) {
            right: 1rem;
          }
          @include media-breakpoint-down(sm) {
            right: 0rem;
          }
        }
        .arrow {
          width: 50px;
          &.left {
            background-image: url(../../img/imagesGrid/arrow-left.png);
            background-position: 0 center;
          }
          &.right {
            background-image: url(../../img/imagesGrid/arrow-right.png);
            background-position: 0 center;
          }
        }        
      }
      .modal-close {       
        right: 2rem;
        top: 2rem;
        background-image: none;
        width: 50px;
        height: 50px;
        &::before {
          content: url(../../img/x.svg);
          position: absolute;
          right: 12px;
          top: 12px;
          z-index: 1195;
          width: 24px;
          height: 24px;
        }
        &::after {
          content:"";
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background-color: $white;
          position: absolute;
        }
      }
    }

    
}    

//
.ideabox-img-gif {
  .image-wrap {
    img {
      width: 175px !important;
      min-width: 175px;
      height: 120px !important;
      min-height: 120px;
    }
  }
}