.orange {
  background-color: #f60; }

.purple {
  background-color: #8B2788; }

.blue {
  background-color: #3b97c7; }

.green {
  background-color: #2bb673; }
  
/*
New Color Codes
*/
/*
.bg-non-avator-a {background-color: #F44336;}
.bg-non-avator-b {background-color: #E91E63;}
.bg-non-avator-c {background-color: #9C27B0;}
.bg-non-avator-d {background-color: #673AB7;}
.bg-non-avator-e {background-color: #3F51B5;}
.bg-non-avator-f {background-color: #2196F3;}
.bg-non-avator-g {background-color: #03A9F4;}
.bg-non-avator-h {background-color: #00BCD4;}
.bg-non-avator-i {background-color: #009688;}
.bg-non-avator-j {background-color: #4CAF50;}
.bg-non-avator-k {background-color: #8BC34A;}
.bg-non-avator-l {background-color: #CDDC39;}
.bg-non-avator-m {background-color: #FDD835;}
.bg-non-avator-n {background-color: #FFC107;}
.bg-non-avator-o {background-color: #FF9800;}
.bg-non-avator-p {background-color: #FF5722;}
.bg-non-avator-q {background-color: #795548;}
.bg-non-avator-r {background-color: #9E9E9E;}
.bg-non-avator-s {background-color: #607D8B;}
.bg-non-avator-t {background-color: #9E9E9E;}
.bg-non-avator-u {background-color: #4F81C7;}
.bg-non-avator-v {background-color: #83B582;}
.bg-non-avator-w {background-color: #01D28E;}
.bg-non-avator-x {background-color: #35B0AB;}
.bg-non-avator-y {background-color: #12D3CF;}
.bg-non-avator-z {background-color: #756C83;}
*/
/*
Course percentage color
*/
// progress icon color
@for $z from 0 through 0 {
  .activity-0#{$z}-icon-color {
    background-color: $gray-200;
    .feather {
      stroke: $gray-400;
    }
  }
}

@for $i from 0 through 99 {
  .activity-#{$i*1}-icon-color {
    background-color: $gray-200;
    .feather {
      stroke: $gray-400;
    }
  }
}

@for $c from 100 through 100 {
  .activity-#{$c}-icon-color {
    background-color: $green;
    .feather {
      stroke: $white;
    }
  }
}

// progress percentage
@for $z from 0 through 0 {  
    .activity-0#{$z}-complete {
      color:$white;
      border: 1px solid $secondary;
      background-color: $secondary;
    }  
}

@for $i from 1 through 99 {
  .activity-#{$i*1}-complete {
    color:$white;
    border: 1px solid $secondary;
    background-color: $secondary;
  }
}

@for $c from 100 through 100 {
  .activity-#{$c}-complete {
    color:$white;
    border: 1px solid $green;
    background-color: $green;
  }
}

/*
Course inner page , Points table
*/
.list-color-codes {
	.list-color-codes-item:nth-child(1) {
		.list-color-codes-bg {
			background-color: rgba(#F44336, .3);
			.feather {
				stroke: #F44336;
			}
		}
	}
	.list-color-codes-item:nth-child(2) {
		.list-color-codes-bg {
			background-color: rgba(#E91E63, .3);
			.feather {
				stroke: #E91E63;
			}
		}
	}
	.list-color-codes-item:nth-child(3) {
		.list-color-codes-bg {
			background-color: rgba(#9C27B0, .3);
			.feather {
				stroke: #9C27B0;
			}
		}
	}
	.list-color-codes-item:nth-child(4) {
		.list-color-codes-bg {
			background-color: rgba(#673AB7, .3);
			.feather {
				stroke: #673AB7;
			}
		}
	}
	.list-color-codes-item:nth-child(5) {
		.list-color-codes-bg {
			background-color: rgba(#3F51B5, .3);
			.feather {
				stroke: #3F51B5;
			}
		}
	}
	.list-color-codes-item:nth-child(6) {
		.list-color-codes-bg {
			background-color: rgba(#2196F3, .3);
			.feather {
				stroke: #2196F3;
			}
		}
	}
	.list-color-codes-item:nth-child(7) {
		.list-color-codes-bg {
			background-color: rgba(#03A9F4, .3);
			.feather {
				stroke: #03A9F4;
			}
		}
	}
	.list-color-codes-item:nth-child(8) {
		.list-color-codes-bg {
			background-color: rgba(#00BCD4, .3);
			.feather {
				stroke: #00BCD4;
			}
		}
	}
	.list-color-codes-item:nth-child(9) {
		.list-color-codes-bg {
			background-color: rgba(#009688, .3);
			.feather {
				stroke: #009688;
			}
		}
	}
	.list-color-codes-item:nth-child(10) {
		.list-color-codes-bg {
			background-color: rgba(#4CAF50, .3);
			.feather {
				stroke: #4CAF50;
			}
		}
	}
	.list-color-codes-item:nth-child(11) {
		.list-color-codes-bg {
			background-color: rgba(#8BC34A, .3);
			.feather {
				stroke: #8BC34A;
			}
		}
	}
	.list-color-codes-item:nth-child(12) {
		.list-color-codes-bg {
			background-color: rgba(#CDDC39, .3);
			.feather {
				stroke: #CDDC39;
			}
		}
	}
	.list-color-codes-item:nth-child(13) {
		.list-color-codes-bg {
			background-color: rgba(#FDD835, .3);
			.feather {
				stroke: #FDD835;
			}
		}
	}
	.list-color-codes-item:nth-child(14) {
		.list-color-codes-bg {
			background-color: rgba(#FFC107, .3);
			.feather {
				stroke: #FFC107;
			}
		}
	}
	.list-color-codes-item:nth-child(15) {
		.list-color-codes-bg {
			background-color: rgba(#FF9800, .3);
			.feather {
				stroke: #FF9800;
			}
		}
	}
	.list-color-codes-item:nth-child(16) {
		.list-color-codes-bg {
			background-color: rgba(#FF5722, .3);
			.feather {
				stroke: #FF5722;
			}
		}
	}
	.list-color-codes-item:nth-child(17) {
		.list-color-codes-bg {
			background-color: rgba(#795548, .3);
			.feather {
				stroke: #795548;
			}
		}
	}
	.list-color-codes-item:nth-child(18) {
		.list-color-codes-bg {
			background-color: rgba(#9E9E9E, .3);
			.feather {
				stroke: #9E9E9E;
			}
		}
	}
	.list-color-codes-item:nth-child(19) {
		.list-color-codes-bg {
			background-color: rgba(#607D8B, .3);
			.feather {
				stroke: #607D8B;
			}
		}
	}
	.list-color-codes-item:nth-child(20) {
		.list-color-codes-bg {
			background-color: rgba(#9E9E9E, .3);
			.feather {
				stroke: #9E9E9E;
			}
		}
	}
	.list-color-codes-item:nth-child(21) {
		.list-color-codes-bg {
			background-color: rgba(#4F81C7, .3);
			.feather {
				stroke: #4F81C7;
			}
		}
	}
	.list-color-codes-item:nth-child(22) {
		.list-color-codes-bg {
			background-color: rgba(#83B582, .3);
			.feather {
				stroke: #83B582;
			}
		}
	}
	.list-color-codes-item:nth-child(23) {
		.list-color-codes-bg {
			background-color: rgba(#01D28E, .3);
			.feather {
				stroke: #01D28E;
			}
		}
	}
	.list-color-codes-item:nth-child(24) {
		.list-color-codes-bg {
			background-color: rgba(#35B0AB, .3);
			.feather {
				stroke: #35B0AB;
			}
		}
	}
	.list-color-codes-item:nth-child(25) {
		.list-color-codes-bg {
			background-color: rgba(#12D3CF, .3);
			.feather {
				stroke: #12D3CF;
			}
		}
	}
	.list-color-codes-item:nth-child(26) {
		.list-color-codes-bg {
			background-color: rgba(#756C83, .3);
			.feather {
				stroke: #756C83;
			}
		}
	}
}