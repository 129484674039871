#dashboard.top-nav-hide {
    @include media-breakpoint-up(lg) {
        .top-nav,
        .info-feed-section {
            display: none !important;
        }
        .main-nav {
            top: 0 !important;
        }
        .main-wrapper {
            padding-top: 100px !important;
        }
        .scrollFixed {
            top: 100px !important;
        }
    }
}

.nav-mobile-fake {
    display: none; 
}

.top-nav-hide {
    @include media-breakpoint-up(lg) {
        .top-nav,
        .info-feed-section,
        .sidebar-wrapper {
            display: none !important;
        }
        .main-nav {
            top: 0 !important;
        }
        .main-wrapper {
            padding-top: 169px !important;
        }
        .scrollFixed {
            top: 100px !important;
        }
        .breadcrumb-panel-fixed {
            top: 100px !important;
        }
        .object-view {
            top: 110px;
        }        
    }
    @include media-breakpoint-down(lg) {
        .navbar-mobile-toggleable {
            display: none !important;
        }
        .nav-mobile-fake {
            width: 24px;
            height: 24px;
            margin-left: 1rem;
            display: block !important;
        }
    }    
}