#emp_directory.User {
	.breadcrumb-panel-fixed {
		@include media-breakpoint-down(lg) {
			display: block;
			top: 60px;
			padding: 15px 0;
			.form-control-search {
				display: none;
			}
		}
	}
	.main-wrapper {
		@include media-breakpoint-down(lg) {
			padding-top: 130px;
		}
	}	
}

.dropdown-emp-dir {
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
    button {
        height: 40px;
		padding: 12px $input-btn-padding-x-lg;
		@include media-breakpoint-down(lg) {
			width: 100%;
			//padding: 10px 12px;
			//height: inherit;
		}
        /*@media screen and (-webkit-device-pixel-ratio: 1.25) {
            height: 36px;
        }*/
    }
}


#List {
	#desc-search-button {
		width: 385px;
	}

}


/*#ListContainer{
	.row > div:first-child{
		.dir-admin-position {
			@include media-breakpoint-down(lg) {
				//top: 8px;
			}
		}
	}
}*/

.dir-admin-position {
	position: absolute;
	position: absolute;
    left: 0px;
    top: 0px;
    z-index: 1;
    overflow: hidden;
    width: 60px;
	height: 60px;
	@include media-breakpoint-down(lg) {
		//top: 8px;
	}
	@include media-breakpoint-down(sm) {
		//top: 0px;
	}
		
	
	span {
		font-size: $font-size-sm;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		color: $white;
		text-align: center;
		line-height: 28px;
		transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: $success;
		position: absolute;
		top: 7px;
		left: -31px;
	}
}



.dir-inner-admin-position {
	position: absolute;
	position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 60px;
	height: 60px;
	span {
		font-size: $font-size-sm;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		//
		color: $white;
		text-align: center;
		line-height: 28px;
		transform: rotate(-45deg);
		width: 100px;
		display: block;
		background: $success;
		position: absolute;
		top: 7px;
		left: -31px;
	}
}

.user-dir-img-section {
	display: flex;
		@include media-breakpoint-up(lg) {
			    flex-direction: column;
				align-items: center;
		}
		@include media-breakpoint-down(lg) {
			justify-content: start;
		}
	.admin-lable {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.Admin-panel {
	.user-dir-img-section {
		position: relative;
	}
}

.Admin-panel {
	.user-dir-img-section {
		position: relative;
	}
}

.dir-main-details-custom-width {
	width: 80%;
		@include media-breakpoint-down(lg) {
			width: 72%;
		}
}
/*
.ud-filter {
	.nav-item {
		min-width: 220px;
		margin: 0 $spacer $spacer 0;
		@include media-breakpoint-down(lg) {
			min-width: auto;
			width: 100%;
			margin: 0 0 $spacer 0;
		}
	}
	
	.nav-link {
		background-color: $white;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		padding: ($spacer * 1.072) $nav-link-padding-x;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
	}
}
*/
.user-dir-thumbnail {
	min-height: 250px;
	width: 100%;
	border-radius: $border-radius;
    font-size: $h1-font-size;
    //font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
    color: $white !important;
	&:focus {
			text-decoration: none;
	}
}

.nav-mobile-page-search {
	position: absolute;
    right: 1rem;
    top: 20px;
    display: flex;
    width: 70px;
    height: 24px;
	justify-content: space-between;
	z-index: 1040;
}

/*
List View
*/
.user-list-scroll {
	overflow-y: scroll;	
	scrollbar-width: thin !important;
	@include media-breakpoint-up(lg) {
		max-height: 410px;
	}
	@include media-breakpoint-up(xl) {
		max-height: 550px;
	}	
}

.user-list-details {
	.feather {
		width: 12px;
		height: 12px;
		stroke: $navbar-light-color-icon;
	}
}
.user-learning-rewards-list {
	.icons-view {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 100%;
	}
	.bg-info {
		@extend .icons-view;
		background-color: rgba($info, .3) !important;
		.feather {
			stroke: $info;
		}
	}
	.bg-secondary {
		@extend .icons-view;
		background-color: rgba($secondary, .3) !important;
		.feather {
			stroke: $secondary;
		}
	}
	.bg-quick-info {
		@extend .icons-view;
		background-color: rgba($quick-info, .3) !important;
		.feather {
			stroke: $quick-info;
		}
	}
}

.user-info-icons {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
	border-radius: 100%;
	background-color:$gray-200;
	.feather {
		stroke: $gray-400;
	}
}

.avatar-user-dir {
    width: 210px;
    height: 210px;
    min-width: 210px;
    min-height: 210px;
    max-width: 210px;
    max-height: 210px;    
}

.non-avatar-user-dir {
    width: 210px;
    height: 210px;
    min-width: 210px;
    min-height: 210px;
    max-width: 210px;
    max-height: 210px;
    display: flex !important;
    align-items: center;
	justify-content: center !important;
	font-size: $h1-font-size;
    //font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
}

.user-list-details {
	.non-ava {
		width: 40px !important;
		min-width: 40px !important;
		max-width: 40px !important;
		height: 40px !important;
		min-height: 40px !important;
		max-height: 40px !important;
	}
}

.user-list-item.active {
	background-color: #F5F6F7;
}

.dir-all-u-user-name {
    width: 40%;
}

.dir-emp-dir-divider {
    background-color: #F0F2F5;
    margin: 1rem 0 0 0;
    height: 1px;
}

.user-dir-user-view {
	.icon-assigned-item {
		background-color: $athens-gray !important;
		.feather {
			stroke: #8C9BAC !important;
		}
	}
}

.user-dir-user-view {
	.list-user-assigned {
		.list-user-assigned-item {
			padding: 1rem 0;
			font-size: 1.286rem;
			line-height: 1;
			border-bottom: 1px solid #F0F2F5 !important;
		}
		.list-user-assigned-item:last-child {
			border-bottom: none !important;
		}
	}
}

// ots
.ots-user-list {
	padding-bottom: 143px;
	@include media-breakpoint-up(lg) {
		padding-bottom: 100px;
	}
}