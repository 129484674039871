@font-face {
    font-family: 'Poppins Regular';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Poppins Regular'), url('../../fonts/poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Poppins Light';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Poppins Light'), url('../../fonts/poppins/Poppins-Light.woff') format('woff');
}

@font-face {
    font-family: 'Poppins Medium';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Poppins Medium'), url('../../fonts/poppins/Poppins-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Poppins SemiBold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Poppins SemiBold'), url('../../fonts/poppins/Poppins-SemiBold.woff') format('woff');
}

@font-face {
    font-family: 'Poppins Bold';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: local('Poppins Bold'), url('../../fonts/poppins/Poppins-Bold.woff') format('woff');
}