.alert {
	@include media-breakpoint-up(lg) {
		min-width: 400px;
		max-width: 50%;
	}
	position: fixed;
	z-index: $zindex-alert-box;
	left: 50%;
	transform: translateX(-50%);
	white-space:nowrap;
	top: 24px;
	display: flex;
	align-items: center;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';	
	.feather {
		width: 20px; 
		height: 20px;
		min-width: 20px; 
		min-height: 20px;
		stroke: $white;
		margin-right: $spacer;		
	}

	.btn-close,
	.close {
		width: 16px;
		height: 20px;
		border-radius: inherit;
		background-color: transparent !important;
		opacity: .7;
		margin-left: ($spacer*2);
		&:hover,
		&:focus {
			background-color: transparent !important;
			cursor: pointer;
		}
		.feather {
			width: 20px;
			height: 20px;
			min-width: 20px; 
			min-height: 20px;
			stroke: $white;
			margin-right: 0;
		}
	}
	
	@include media-breakpoint-down(lg) {
		width: 93%;	
		min-width: auto;	
	}
	@include media-breakpoint-down(sm) {		
		white-space: inherit;
		align-items: flex-start;
		.me-auto.pt-1 {
			padding-top: 0 !important;
		}
	}
	.me-auto {
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		display: -webkit-box;
		white-space: normal;
	}
}

#index {
	&.User {
		.alert {
			.me-auto {
				-webkit-line-clamp: 6;
			}
		}
	}
}

.alert-primary-points {
	background-color: rgba($primary, .2);
	color: $primary;
	padding: .45rem 1.75rem;
}

.alert-gray-points{
	//background-color: $iron;
	//color: $trout;
	padding: .45rem 1.75rem;
}

.alert-danger,
.alert-info, 
.alert-secondary, 
.alert-success, 
.alert-warning {
	color: $white;
}

.alert-payment {
	background-color: $punch;
	color: $white;
	top: 48px;
    height: 60px;
	position: fixed;
    z-index: 1030;
    right: 0;
    left: 0;
	padding: 1rem;
}

.alert-payment-active {
	@include media-breakpoint-up(lg) {
    	top: 108px;
	}
}

.alert-payment-active-v2 {
    .breadcrumb-panel-fixed {
		@include media-breakpoint-up(lg) {
			top: 208px;
		}
	}
	.table-filter-fixed {
		@include media-breakpoint-up(lg) {
			top: 273px;
		}
	}
}

.alert-module {
	z-index: inherit !important;
	background-color: $selago;
	color: $blue-violet;
	position: relative;
    top: 0;
    min-width: 100%;
    max-width: 100%;
    white-space: normal;
	padding: 15px 30px 15px 20px;
	.alert-close {
		cursor: pointer;
	}
}