.list-activity-group {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
}
.list-activity-group-item {
  position: relative;
  display: block;
  color: $list-group-color;
  border-bottom: $list-group-border-width solid $list-group-border-color;
  padding: $list-group-item-padding-y 0;
  
  &:first-child {
	  padding-top: 0;
  }
  
  &:last-child {
    padding-bottom: 0;
    border-bottom: $list-group-border-width solid transparent;
  }

}

.activity-question-text {
	max-width: 250px;
	@include media-breakpoint-down(xxl) {
		max-width: 160px;
	}
	@include media-breakpoint-down(lg) {
		max-width: 250px;
	}
}

.list-media-img {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 140px;
	height: 80px;
	max-width: 140px;
	max-height: 80px;
	min-width: 140px;
	min-height: 80px;
	background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
	margin-right: $spacer;
	@include border-radius($border-radius-sm);
	img {
		max-width: 100%;
		height: auto;
		overflow: hidden;
		@include border-radius($border-radius-sm);
	}
	.feather {
		stroke: $white;
		position: absolute;
		z-index: 1;
	}
	&:before {
      content: "";
      position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%; 
		border-radius: $border-radius-sm;
		overflow: hidden;
		background: rgba($primary, 0.3);
		//@include linearGradient (rgba($primary,0.3), rgba($primary,0.3));
    } 
}

.list-media-icon {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 85px;
	height: 85px;
	background-color: $input-bg;
    border: $input-border-width solid $input-border-color;
	margin-right: $spacer;
	@include border-radius($border-radius);
	i {
		font-size: $spacer*2.5;
		color: $primary;
	}
}


.list-group-flush {
  .list-group-item {
	  //border: 0;
	  display: flex;
	  justify-content: space-between;
	  //font-weight: $font-weight-medium;
	  font-family: 'Poppins Medium';
	  //padding-bottom: 0;
	  &:last-child {
		   //padding-bottom: $list-group-item-padding-y;
	  }
  }
  .item-answers {
	  max-width: 100px;
  }
  .item-discussion {
	  width: calc(100% - 100px);
  }
}

//  Course
.list-course-module {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		.list-course-module-item {
			display: flex;
			align-items: center;
			margin-right: 2.3rem;
			@include media-breakpoint-down(lg) {
				font-size: $font-size-sm;
				/*flex-direction: column;*/
			}
		}
		i {
			color: $primary;
			//font-weight: $font-weight-medium;
			font-family: 'Poppins Medium';
			margin-right: $spacer;
			font-size: $h3-font-size;
			@include media-breakpoint-down(lg) {
				font-size: $h4-font-size;
				margin-right: 0;
				margin-bottom: $spacer*.45;
			}
		}
}
 
.list-activtie-header {
  /*position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
  box-shadow: $box-shadow;
  list-style: none;
  padding: 0;
  margin: 0;
  @include media-breakpoint-down(lg) {
	   border: none;
	   box-shadow: none;
  }*/
  .list-activtie-header-item {
		/*flex: 1 1 auto;
		padding: $card-spacer-y ($spacer*1.5);
		background-color: $card-bg;*/
		border-bottom: $border-width solid $border-color;		
		&:last-child {
			//border-bottom: $border-width solid transparent;
			.list-activties-view {
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			}
		}
  }
}

.md-activity-item {
	@include media-breakpoint-down(lg) {
		display: flex;
		align-items: center;
		.md-points-gain {
			font-size: $font-size-sm;
			//font-weight: $font-weight-medium;
			font-family: 'Poppins Medium';
			margin-left: $spacer;
		}
	}
}

.list-activties {
	list-style: none;
	margin: 0;
	padding: 0;
	.list-activties-item {
		padding: $spacer ($spacer*1) 0;
		&:last-child {
			padding-bottom: $spacer;
		}
		.links {
			margin-top: $spacer;
		}
	}
}

.text-activtie-header {
	font-size: $font-size-lg;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	color: $gray-600;
	@include media-breakpoint-down(lg) {
	   font-size: $font-size-base;
  }
}

.list-activtie-link {
	text-decoration: none;
	color: inherit;
	overflow: hidden;
    display: block;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
}

.list-activtie-sub-link {
	text-decoration: none;
	color: inherit;
	margin-bottom: 1rem;
	overflow: hidden;
	display: block;
	&:hover {
		text-decoration: none;
		color: inherit;
	}
	@include media-breakpoint-down(lg) {
		overflow: hidden;
		display: block;
		padding-bottom: 1rem;		
		//border-bottom: 1px dotted $ghost;
	}
	&:last-child {
		border-bottom: 1px dotted transparent;
		padding-bottom: 0;
		margin-bottom: 0;
	}
}

.list-activtie-collapse {
	padding: 1rem;
	align-items: center;
	display: flex;
}

.list-instance {
	@extend .list-highest-ranked;
	max-height: 524px;
    overflow-y: auto;
	.list-instance-item {
		@extend .list-activity-group-item;
	}
}

// admin
.list-group-admin {
	.list-group-item {
		padding: $list-group-admin-item-padding-y $list-group-admin-item-padding-x;
	}
}

.list-group-admin-activity {
	.list-group-item {
		padding: .61rem $list-group-admin-item-padding-x;
	}
}

.list-action {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	.list-action-item {
		//border-right: $border-width $border-color solid;
		padding: 0 $spacer;
		font-size: 19px;
		line-height: 1;
		position: relative;
		a {
			//color:  $trout;			
		}
		&:hover {
				.btn-assign {
					color:  $blue;
				}
				.btn-edit {
					color:  $green;
				}
				.btn-delete {
					color:  $red;
				}
				.btn-copy {
					color:  $yellow;
				}
				.btn-view {
					color:  $orange;
				}
				.btn-view-report {
					color: $purple;
				}
		}
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			border-right: 0;
			padding-right: 0;
			&:after {
				content : none;
			}
		}
		&:after {
		  content : "";
		  position: absolute;
		  right    : 0;
		  z-index: 100;
		  top  : 5px;
		  width  : 1px;
		  height   : 50%;  /* or 100px */
		  //background: $ghost;
		}		
	}
}

// badge
.list-badge-complete {
	display: flex;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	.list-badge-complete-item {
		  position: relative;
		  display: block;
		  color: $list-group-color;
		  border-bottom: $list-group-border-width solid $list-group-border-color;
		  padding: $list-group-item-padding-y 0;
		  
		  &:first-child {
			  padding-top: 0;
		  }
		  
		  &:last-child {
			padding-bottom: 0;
			border-bottom: $list-group-border-width solid transparent;
		  }
	}
}

.list-badge {
	width: 38px;
	height: 38px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $font-size-base;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
}

// Comments attachment-list
.attachment-list {
		margin: 0;
		padding: 0;
		display: inline-block;
		//overflow-x: auto;
		//max-width: 500px;
		@include media-breakpoint-down(lg) {
			max-width: 400px;
		}
		@include media-breakpoint-down(xs) {
			max-width: 230px;
		}
		.attachment-list-item {
			list-style: none;
			margin-right: .75rem;
			display: inline-block;
			img {
				overflow: hidden;
				border-radius: $border-radius;
				border: $border-width solid $border-color;
			}
		}
		.file-attachment {
			border-radius: $border-radius;
			border: $border-width solid $border-color;
			width: 125px;
			height:90px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
}



// ideabox
#ideabox.User {
	.list-group-item-action {
		display: flex;
		align-items: center;
		justify-content: start;
		i {
			font-size: $font-size-xl;
		}
	}
}


.list-group-icons {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.card-list-groups {
	.list-group-item {
		padding-left: 0;
		padding-right: 0;
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			padding-bottom: 0;
		}
	}
}

// course unlock rewards
.list-unlock-rewards {
	list-style: none;
    padding: 0;
    margin: 0;
	.list-unlock-rewards-item {
		border-bottom: $list-group-border-width solid $list-group-border-color;
		padding: $list-group-item-padding-y 0;
		
		&:first-child {
			padding-top: 0;
		}
		
		&:last-child {
			padding-bottom: 0;
			border-bottom: $list-group-border-width solid transparent;
		}		
	}
	.list-media-item {
		width: 220px;
		min-width: 220px;
		height: 195px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $ghost;
		border-radius: $border-radius-sm;
	}
	.media {
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			text-align: center;
		}
	}
} 

// select user dropdown in discussion popup
.ui-menu {
	z-index: 100000000 !important;
	position: fixed !important;
	background: $white;
	list-style: none;
	max-height: 150px;
	overflow: auto;
	padding: 0;
	.ui-menu-item {
		padding: .5rem 1rem;
		cursor: pointer;
		&:hover,
		&:focus {
			color: $secondary;
		}
	}
	.ui-menu-item-wrapper {
		//font-weight: 500;
		font-family: 'Poppins Medium';
		transition-duration: .3s;
	}
}

.ui-helper-hidden-accessible {
	display: none !important;
}

// wall emoji
.list-emoji-wrapper {
	display: flex;
    flex-wrap: wrap;
	overflow-y: scroll;
	max-height: 234px;
	.list-emoji-item {
		flex: 0 0 16.666667%;
    	max-width: 16.666667%;
		padding: 3px;
		text-align: center;
	}
	.list-emoji-item-link {
		font-size: 22px;
	}
} 

.mcq-question-wrapper {
	list-style: none;
	padding: 0;
	margin: 0;
	.mcq-answer-icons {
		width: 21px;
		height: 21px;
		content: "";
		position: absolute;
		left: 0;
		top: 0;
	}
	.mcq-question-item {
		padding-left: 40px;
		font-weight: 500;
		margin-bottom: 15px;
		position: relative;
		@include media-breakpoint-up(lg) {
			width: 90%;
		}
		&:last-child {
			margin-bottom: 0;
		}
		&::before {			
			background: url("../../img/question-answer/check.svg") no-repeat 0 0;
			@extend .mcq-answer-icons;
		}
	}
	.right-answer {
		color: $ocean-green;
		font-weight: 700;
		&::before {
			background: url("../../img/question-answer/right.svg") no-repeat 0 0;
			@extend .mcq-answer-icons;
		}
	}
	.wrong-answer {
		color: $radical-red;
		font-weight: 700;
		&::before {
			background: url("../../img/question-answer/wrong.svg") no-repeat 0 0;
			@extend .mcq-answer-icons;
		}
	}
}

.mcq-answer-status {
	@include media-breakpoint-up(lg) {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
	}
	@include media-breakpoint-down(lg) {
		text-align: right;
		margin-top: 1rem;
	}
	&.right-answer {
		color: $ocean-green;
		font-weight: 700;
	}
	&.wrong-answer {
		color: $radical-red;
		font-weight: 700;
	}
}


// surey
.list-mcq-question {
	list-style: none;
	padding: 0;
	margin: 0;
	.list-mcq-question-item {
		padding: 1.25rem;
		border-bottom: $border-width solid $border-color;
		font-size: $font-size-lg;		
		&:last-child {
			border-bottom: $border-width solid transparent;
		}
	}
}