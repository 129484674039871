//
.text-wrap {
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
}

// 
.card {
    margin-bottom: $grid-gutter-width;
    border: none;
    .video-js {
        width: 100%;
        height: 426px;
    }
}

.card-header {
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    line-height: 1;
    @include media-breakpoint-down(lg) {
        line-height: inherit;
    }
}

.wall-card-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    h5 {
        @include media-breakpoint-down(lg) {
            font-size: $font-size-base;
        }
    }
    .media {
        max-width: 90%;        
    }
    .feather {
        stroke: $navbar-light-color-icon;
    }
    .dropdown-menu {
        min-width: 160px;
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        .feather {
            width: 20px;
            height: 20px;
            stroke: $navbar-light-color-icon;
        }
        .dropdown-icon {
            fill: $navbar-light-color-icon;
        }
        /*&:focus { this code add to header file
            .feather {
                stroke: $navbar-light-active-color-icon;
            }
            .dropdown-icon {
                fill: $navbar-light-active-color-icon;
            }
        }*/
        /*&:hover {
            .dropdown-icon {
                fill: $navbar-light-active-color-icon;
            } 
        }*/
    }
}
 
.card-secondary {
    background-color: rgba($gray-200, 0.1);
    border-color: rgba($gray-200, 0.1);
}

// USER CARD
.mobile-user-name {
    color: $primary;
    //font-weight: $font-weight-bold;
    font-family: 'Poppins Bold';
    margin-bottom: ($spacer * .25);
    -webkit-line-clamp: 1;
    @extend .text-wrap;
}

.mobile-num-points {
    color: $gray-800;
    font-size: $h4-font-size;
    margin-bottom: 0;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
}

.mobile-points-text {
    color: $gray-800;
    margin-bottom: 0;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
}

.user-card {
    .user-name {
        font-size: $h6-font-size;
        color: $gray-800;
        line-height: 1;
        /*font-weight: $display4-weight;
		margin-top: $font-size-base;*/
        margin-bottom: 0;
    }
    .user-status {
        //font-weight: $display2-weight;
        color: $gray-200;
        margin-bottom: ($spacer * .25);
        //font-size: $font-size-lg;
    }
    .num-points {
        color: $primary;
        //font-weight: $display5-weight;
        line-height: 1;
        margin: 0;
        font-size: $h3-font-size;
    }
    .points-text {
        color: $gray-800;
        //font-size: $font-size-md;
        //font-weight: $display4-weight;
        margin-bottom: ($spacer * 1.25);
    }
    .user-rank-view {
        //padding: $card-list-group-item-padding-y 0;
        text-align: center;
        border-top: $list-group-border-width solid $list-group-border-color;
    }
    .card-list-group {
        padding: $card-list-group-item-padding-y 0;
        //border-top: $list-group-border-width solid $list-group-border-color;
        display: flex;
        margin-bottom: 0;
    }
    .card-list-item {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        position: relative;
        padding: .25rem .25rem 0;
        text-align: center;
        list-style: none;
        a {
            &:focus {
                text-decoration: none;
                color: inherit;
            }
        }
        .icons-view {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
        .bg-info {
            @extend .icons-view;
            background-color: rgba($info, .3) !important;
            .feather {
                stroke: $info;
            }
        }
        .bg-secondary {
            @extend .icons-view;
            background-color: rgba($secondary, .3) !important;
            .feather {
                stroke: $secondary;
            }
        }
        .bg-quick-info {
            @extend .icons-view;
            background-color: rgba($quick-info, .3) !important;
            .feather {
                stroke: $quick-info;
            }
        }
    }
    /*.icor,
	.icos {
		font-size: $h2-font-size;
		margin-right: .65rem;
	}
	.icon-sm {
		//font-size: $font-size-xxs !important;
	}
	.icon-arrow-down {
		color: theme-color("danger") !important;
	}
	.icon-arrow-up {
		color: theme-color("success") !important;
	}
    .card-footer {
        padding: $spacer;
		//background-color: $porcelain;
    }*/
    .user-card-rank-table {
        margin-bottom: 0;
        .px-2-5 {
            padding-left: .85rem !important;
            padding-right: .85rem !important;
        }
    }
    .card-body {
        position: relative;
        .camera {
            position: absolute;
            bottom: 30px;
            left: 30px;
            margin: 0;
            stroke: $white;
            width: 22px;
            height: 22px;
        }
    }
}

.user-card-mobile {
    display: none;
    @include media-breakpoint-down(lg) {
        display: flex;
        margin-bottom: 4px;
    }
    .card-list-group {
        padding: 0;
        //border-top: $list-group-border-width solid $list-group-border-color;
        display: flex;
        margin-bottom: 0;
    }
    .card-list-item {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        position: relative;
        padding: $list-group-item-padding-y .25rem 0;
        text-align: center;
        list-style: none;
        a {
            &:focus {
                text-decoration: none;
                color: inherit;
            }
        }
        .icons-view {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
        .bg-info {
            @extend .icons-view;
            background-color: rgba($info, .3) !important;
            .feather {
                stroke: $info;
            }
        }
        .bg-secondary {
            @extend .icons-view;
            background-color: rgba($secondary, .3) !important;
            .feather {
                stroke: $secondary;
            }
        }
        .bg-quick-info {
            @extend .icons-view;
            background-color: rgba($quick-info, .3) !important;
            .feather {
                stroke: $quick-info;
            }
        }
        a.active {
            .bg-info {
                background-color: $info !important;
                .feather {
                    stroke: $white;
                }
            }
            .bg-secondary {
                background-color: $secondary !important;
                .feather {
                    stroke: $white;
                }
            }
            .bg-quick-info {
                background-color: $quick-info !important;
                .feather {
                    stroke: $white;
                }
            }
        }
    }
}

.user-learning-rewards-list {
    .bg-info {
        @extend .icons-view;
        background-color: rgba($info, .3) !important;
        .feather {
            stroke: $info;
        }
    }
    .bg-secondary {
        @extend .icons-view;
        background-color: rgba($secondary, .3) !important;
        .feather {
            stroke: $secondary;
        }
    }
    .bg-quick-info {
        @extend .icons-view;
        background-color: rgba($quick-info, .3) !important;
        .feather {
            stroke: $quick-info;
        }
    }
    a.active {
        .bg-info {
            background-color: $info !important;
            .feather {
                stroke: $white;
            }
        }
        .bg-secondary {
            background-color: $secondary !important;
            .feather {
                stroke: $white;
            }
        }
        .bg-quick-info {
            background-color: $quick-info !important;
            .feather {
                stroke: $white;
            }
        }
    }
}

.user-card-popup-box {
    display: flex;
    @extend .user-card-mobile;
}

.mobile-rank-text {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: $ochre;
    background-color: rgba($ochre, .3);
}

// user directory
.card-user-details {
    text-decoration: none;
    color: inherit;
    &:focus {
        cursor: pointer;
        text-decoration: none;
        color: inherit;
    }
    @include media-breakpoint-down(lg) {
        min-height: 330px;
    }
}

// twitterWrap
.twitterWrap {
    .card-body {
        padding: $card-spacer-y $card-spacer-x;
    }
}

//
.user-thumbnail {
    border-radius: $border-radius;
    font-size: $h1-font-size;
    //font-weight: $display4-weight;
    color: $white !important;
    &:focus {
        text-decoration: none;
    }
}

.user-thumbnail-md {
    border-radius: $border-radius;
    font-size: 9rem;
    //font-weight: $display4-weight;
    color: $white !important;
    width: 100%;
    height: 100%;
    &:focus {
        text-decoration: none;
    }
    @include media-breakpoint-down(lg) {
        font-size: 6rem;
    }
}

.user-thumbnail-popup-box {
    @extend .user-thumbnail;
    font-size: $h1-font-size;
}

// RIGHT CARD - 
.right-card {
    .list-group-item {
        border-left: none;
        border-right: none;
        &:first-child {
            border-top: 0;
        }
        &:last-child {
            border-bottom: 0;
        }
    }
    /*&:first-child {
    .list-group-item:first-child {
      border-top: 0;
    }
  }*/
    .activity-header {
        //font-weight: $display4-weight;
        color: $gray-500;
        @include text-truncate;
        margin-bottom: .75rem;
        width: 100%;
        display: block;
    }
    /*.btn {
		font-weight: $display4-weight;
	}*/
    .member-list-inline {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .justify-content-between {
        >[class*="col-"] {
            padding-left: 0;
            @include text-truncate;
        }
    }
}

// THINK CARD
.card-think-header {
    padding: $card-spacer-y $card-spacer-x;
    background-color: $card-cap-bg;
    @include border-radius($card-border-radius);
    position: relative;
    display: flex;
    flex-direction: column;
    border: $card-border-width solid $card-border-color;
}

// WALL CARD
.wall-card {
    /*.non-avatar {
		width: $avatar-size;
		height: $avatar-size;
		align-items: center;
		color: $white;
		justify-content: center !important;
		@include border-radius($card-border-radius-sm);
	}*/
    color: #333;
    .h6 {
        color: $gray-800;
        margin-bottom: 0;
        line-height: normal;
        //font-size: $font-size-md;
        .user-name {
            color: $gray-500;
            //font-weight: $display5-weight;
            &:focus {
                color: $navbar-light-hover-color;
            }
        }
        span {
            color: $gray-500;
            //font-weight: $display3-weight;
        }
    }
    /*.post-time {
		color: $gray;
	}*/
    .card-containt {
        //padding: $card-spacer-x 0;
        word-break: break-all;
        padding: $card-spacer-y $card-spacer-x;
        margin: 1rem 0;
        background-color: $gray-200;
        border: $card-border-width solid $card-border-color;
        @include border-radius($border-radius-sm);
        .list-inline-item {
            color: $gray-700;
        }
    }
    // Nav complete list
    .nav-list {
        .nav-link {
            //padding: $card-nav-list-padding;
            &:first-child {
                padding-left: 0;
            }
        }
    }
    // Footer Nav list
    .card-footer {
        .nav-list {
            .nav-link {
                //padding: $card-nav-footer-list-padding;
                font-size: $font-size-sm;
                /*&:first-child {
					padding-left: 0;
				}*/
            }
        }
        .media {
            .flex-grow-1 {
                textarea {
                    padding: .8rem .75rem;
                }
            }
        }
    }
    // Comment section
    .comment-block {
        border-top: $list-group-border-width solid $list-group-border-color;
        .nav-link {
            //padding: $card-nav-link-padding 0;
            //font-weight: $display4-weight;
            color: $gray-400;
            cursor: pointer;
            &:focus,
            &:hover {
                color: $link-hover-color;
            }
            &:first-child {
                padding-left: 0;
            }
            .icor,
            .icos {
                margin-right: 10px;
            }
        }
        .liked {
            .icos-thumbs-up:before {
                color: $primary;
                content: "\e967" !important;
            }
        }
        .comments-count,
        .count {
            padding-right: 5px;
        }
    }
    .others-comment {
        margin-top: $card-spacer-x;
        margin-bottom: 0;
    }
    .comment-count {
        float: right;
        color: $gray-200;
    }
    .wall-card-icons {
        font-size: $font-size-base * 8;
        display: block;
        margin: 1.75rem 0;
    }
    .jumbotron {
        padding: $card-spacer-y $card-spacer-x;
        background-color: $card-cap-bg;
        border: $card-border-width solid $card-border-color;
    }
}

.commentsWrapper {
    .user-comments {
        //margin-bottom: $spacer;
        padding: 0.5rem 1rem;
        border-bottom: 1px dashed $athens-gray-four;

        &:first-child {
            padding-top: 1rem;
        }

        &:last-child {
            padding-bottom: 1rem;
            border-bottom: 0px;
        }


    }
}

.user-comments {
    position: relative;
    //cursor: pointer;
    display: flex;
    justify-content: space-between;
    .btn-comment-delete {
        display: none;
        transition: 2s ease-out;
    }
    .comment-view {
        width: 100%;
    }
    &:hover {
        .btn-comment-delete {
            display: block;
            text-decoration: none !important;
            overflow: hidden;
            height: 22px;
            width: 16px;
            z-index: 1019;
            position: absolute;
            right: 1rem;
        }
    }
    .btn-comment-delete {
        .feather {
            width: 16px;
            height: 16px;
            /*&:focus {
                stroke: $danger !important;
            }*/
        }
        &:focus,
        &:hover {
            .feather {
                stroke: $danger !important;
            }
        }
    }
    .comment-user-details-wrapper {
        max-width: 96%;
    }
}

.user-comment-text {
    //color: $trout;
    word-break: break-all;
}

.non-avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    min-width: $avatar-size-lg;
    min-height: $avatar-size-lg;
    max-width: $avatar-size-lg;
    max-height: $avatar-size-lg;
    display: flex!important;
    align-items: center;
    color: $white;
    justify-content: center !important;
    font-size: $h3-font-size;
    //font-weight: $font-weight-bold;
    font-family: 'Poppins Bold';
    //@include border-radius($border-radius-sm);
}

.non-avatar-xl {
    width: 60px;
    min-width: 60px;
    height: 60px;
    display: flex!important;
    align-items: center;
    color: $white;
    justify-content: center !important;
    font-size: $font-size-lg;
    @include border-radius($border-radius-sm);
}

#admin_compare_users {
    .non-avatar-xl {
        width: 70px;
        min-width: 70px;
        height: 70px;
    }   
} 

.non-avatar-xxl {
    width: 230px;
    height: 250px;
    min-width: 230px;
    min-height: 250px;
    max-width: 230px;
    max-height: 250px;
    display: flex!important;
    align-items: center;
    color: $white;
    justify-content: center !important;
    font-size: $h1-font-size;
    @include border-radius($border-radius-sm);
    @include media-breakpoint-down(lg) {
        width: 120px;
        height: 120px;
        font-size: $font-size-base;
    }
}

.non-avatar {
    width: $avatar-size;
    height: $avatar-size;
    min-width: $avatar-size;
    min-height: $avatar-size;
    max-width: $avatar-size;
    max-height: $avatar-size;
    display: flex!important;
    align-items: center;
    color: $white;
    justify-content: center !important;
    @include border-radius($border-radius-sm);
    &:focus {
        color: $white !important;
        text-decoration: none;
    }
}

.non-avatar-mobile-nav {
    width: $avatar-size-mobile;
    height: $avatar-size-mobile;
    min-width: $avatar-size-mobile;
    min-height: $avatar-size-mobile;
    max-width: $avatar-size-mobile;
    max-height: $avatar-size-mobile;
    display: flex!important;
    align-items: center;
    color: $white;
    justify-content: center !important;
    @include border-radius($border-radius-sm);
    &:focus {
        color: $white !important;
        text-decoration: none;
    }
}

.avatar {
    width: $avatar-size;
    height: $avatar-size;
}

.avatar-lg {
    width: $avatar-size-lg;
    height: $avatar-size-lg;
    min-width: $avatar-size-lg !important;
    min-height: $avatar-size-lg !important;
}

.avatar-xl {
    width: $avatar-size-xl;
    height: $avatar-size-xl;
}

.non-avatar-leader-board {
    width: $avatar-size-leaderboard;
    height: $avatar-size-leaderboard;
    align-items: center;
    display: flex!important;
    color: $white;
    justify-content: center !important;
    //font-size: $h1-font-size; 
    @include border-radius($card-border-radius);
    &:focus {
        color: $white !important;
        text-decoration: none;
    }
}

.non-avatar-leader-board-lg {
    width: 100%;
    height: $avatar-size-lg-leaderboard;
    align-items: center;
    display: flex!important;
    color: $white;
    justify-content: center !important;
    font-size: $h1-font-size;
    @include border-radius($card-border-radius);
    &:focus {
        color: $white !important;
        text-decoration: none;
    }
}

.icon-avatar {
    width: $avatar-size;
    height: $avatar-size;
    min-width: $avatar-size;
    min-height: $avatar-size;
    max-width: $avatar-size;
    max-height: $avatar-size;
    display: flex!important;
    align-items: center;
    background-color: $gray-200;
    justify-content: center !important;
    @include border-radius($border-radius-sm);
    .feather {
        stroke: $gray-400;
    }
}

.post-time {
    color: $gray-500;
    font-size: $font-size-sm;
}

.post-body {
    //border: 1px solid $ghost;
    //background-color: $porcelain;
    @include border-radius($border-radius);
    @include media-breakpoint-down(xl) {
        .media-course-card {
            display: block !important;
        }
    }
}

.media {
    position: relative;
    display: flex;
    .delete-card {
        color: $gray-200;
        position: absolute;
        right: .25rem;
        top: .25rem;
        //font-weight: $font-weight-bold;
        font-family: 'Poppins Bold';
        &:focus {
            color: $danger;
        }
    }
}

.media-img {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 270px;
    width: 270px;
    height: 160px;
    background-color: $input-bg;
    margin-right: $spacer;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    overflow: hidden;
    @include media-breakpoint-down(xl) {
        min-width: inherit;
        width: 100%;
        margin-right: 0;
        border-radius: $border-radius;
        margin-bottom: 1rem;
    }
    img {
        max-width: 100%;
        height: auto;
        //overflow: hidden;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        @include media-breakpoint-down(lg) {
            @include border-radius($border-radius);
        }
    }
    .feather {
        width: 50px;
        height: 50px;
        stroke: $white;
        stroke-width: 1;
        position: absolute;
        z-index: 1;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.3);
        //@include linearGradient (rgba(0,0,0,0.3), rgba(0,0,0,0.3));
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        @include media-breakpoint-down(lg) {
            border-radius: $border-radius;
        }
    }
}

.rewards-feed-img {
    @extend .media-img;
    &:before {
        content: none;
    }
    .img-bg-rewards {
        padding: 7px;
        overflow: initial;
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
        }
    }
}

.post-image-view {
    position: relative;
    overflow: hidden;
}

.media-activitiy-card,
.media-course-card {
    align-items: center;
    @include media-breakpoint-up(xl) {
        border: 1px solid $border-color;
        @include border-radius($border-radius);
    }
    @include media-breakpoint-down(xl) {
        display: block !important;
    }
}

.media-course-card {
    .media-img {
        &:before {
            @include media-breakpoint-down(xl) {
                border-radius: $border-radius;
            }
        }
    }    
}

.img-animation {
    position: absolute;
    /*top:0;*/
    bottom: 21px;
    left: 0;
    width: 100%;
    max-height: 338px;
    /*video {
	  width: 100%;
	 height: auto;
	  display: block;
	}*/
    background-repeat: no-repeat;
    height: 338px;
    background-position: bottom;
    z-index: 1;
    @include media-breakpoint-up(xl) {
        background-image: url(../../img/badge-animation.gif);
    }
}

// Custom card block height - TAGS - 
.card-body-height-xs {
    min-height: 160px;
    overflow: auto;
}

.card-body-height-sm {
    height: 316px;
    overflow: auto;
}

// Library CARD
.library-card {
    .card-body {
        height: 130px;
        max-height: 130 px;
        overflow-y: hidden;
    }
    .card-title {
        @extend .text-wrap;
        max-height: $spacer * 3.45;
        /* exactly three lines */
    }
    .lib-item-txt {
        display: block;
        color: $gray-800;
        //max-width: 600px;
        max-height: 36px;
        margin: 0;
        /*line-height: 1.4;*/
        @extend .text-wrap;
        padding: 0;
    }
    .list-item-footer {
        //font-weight: $display4-weight;
    }
    .tumbwrapper {
        //min-height: 310px;
        .loader {
            margin-top: 4.25rem;
            margin-bottom: 4.25rem;
        }
    }
}

.library-iframe {
    iframe {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            min-height: 600px;
        }
    }
}

// Course
.table-module {
    .card-disabled {
        opacity: .40;
        pointer-events: none;
    }
    th,
    td {
        //padding: 0; 
        border-top: 1px solid rgba(0, 0, 0, 0) !important;
    }
    .card-row {
        min-height: 229px;
        max-height: 229px;
    }
    .card-body-course {
        height: 100%;
        max-height: 229px;
    }
    .card-body-course-description {
        color: $gray-800;
        margin-bottom: $spacer;
        min-height: 82px;
        max-height: 82px;
        overflow: hidden;
        ul {
            margin: 6px 0 0;
            padding: 0;
            li {
                //margin-bottom: $spacer;
                list-style: none;
                padding-left: 9px;
                text-indent: -.7em;
                &::before {
                    content: "• ";
                }
            }
        }
    }
    .course-description {
        flex-grow: 1;
        flex-direction: column;
        display: flex;
        height: 100%;
        /*padding: $spacer* 2;*/
        /*@include media-breakpoint-down(lg) {
		height: 134px;
		padding: ($spacer*1) 0 ($spacer*1) ($spacer*1);
	}*/
    }
    .course-description-badge {
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
        padding: $spacer* .5;
        display: flex;
        align-items: center;
        border-radius: $border-radius;
        @include media-breakpoint-down(lg) {
            font-size: $font-size-sm;
        }
        .icor {
            font-size: $h6-font-size;
            @include media-breakpoint-down(lg) {
                font-size: $font-size-sm;
            }
        }
    }
    .field-tip-course-description {
        color: $gray-800;
        ul {
            margin: 6px 0 0;
            padding: 0;
            li {
                margin-bottom: $spacer;
                list-style: none;
                padding-left: 9px;
                text-indent: -.7em;
                &::before {
                    content: "• ";
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .card-popup-course-description {
        @extend .card-body-course-description;
        max-height: inherit;
    }
    .card-body-assessment {
        position: relative;
        margin-top: ($spacer * 1.75);
        height: 25px;
    }
    .card-body-deadline {
        position: absolute;
        height: 25px;
        padding: 4px 10px;
        background-color: $gray-500;
        right: -2.857rem;
        top: 0;
        color: $white;
        font-size: $font-size-sm;
        //font-weight: $display4-weight;
    }
    .course-points-gain {
        position: relative;
        height: 100%;
        /*border-left: $card-border-width $course-card-color solid;*/
        .points-gain {
            width: 100%;
            /*align-items: center !important;
        text-align: center;*/
            //color: $pickled-bluewood;
            @include media-breakpoint-down(lg) {
                padding: 0 $spacer;
            }
        }
        .points-gain-num {
            display: inline-block;
            font-size: $h6-font-size;
            font-family: 'Poppins SemiBold';
            line-height: $line-height-normal;
            color: $green;
        }
        .points-gain-text {
            display: inline-block;
            font-family: 'Poppins SemiBold';
            line-height: $line-height-normal;
            padding: 0 3px;
            /*text-transform: uppercase;*/
            color: $green;
        }
    }
    .card-body-left-course-icon {
        height: 100%;
        width: 100%;
    }
    .card-body-left-course-modules {
        //height: 100%;
        min-width: 118px;
        max-width: 120px;
        background-color: $white;
        //border-right: 1px $ghost solid;
    }
    .card-body-left-bg {
        //background-color: $course-card-left-bg;
    }
    .card-body-half-box {
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
        height: 50%;
        padding: $spacer * .5;
    }
}

.mobile-card-title {
    font-size: $font-size-lg;
    @include media-breakpoint-down(sm) {
        font-size:$font-size-sm;
    }
    //color: $pickled-bluewood;
    /*@media (max-width: 413px) {
        font-size: $font-size-base;
    }
    @media (max-width: 320px) {
        font-size: $font-size-sm;
    }*/
}

.module-cover {
    height: 100%;
}

// Game 
.card-games {
    /*@include media-breakpoint-down(lg) {
		flex-direction: row;
	}*/
    /*.card-body{
		@include media-breakpoint-down(lg) {
			flex: 0 0 66.66667%;
			max-width: 66.66667%;
			height: 134px;
			padding: ($spacer*1) 0 ($spacer*1) ($spacer*1);
			margin-right: auto;
			.btn {
				font-size: $font-size-sm;
			}
		}
	}*/
}

.learning-img-loader {
    //background: url('../../img/preloader.svg') center center no-repeat;
    max-height: 185px;
    position: relative;
}

@include media-breakpoint-up(lg) {
    .user-rank-count {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.module-img {
    //clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);  
    position: relative;
    overflow: hidden;
    height: 100%;
    @include media-breakpoint-down(lg) {
        max-height: 134px;
        display: flex;
    }
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: rgba($primary, 0.3);
        //@include linearGradient (rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    }
    /*img {
		background-size: cover;
		background-position: 50% center;
		width: 100%;
		height: 100%;
	}*/
    /*.img-cover {
		max-width: none;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}*/
    i {
        font-size: $font-size-xxl;
        margin: 0 auto;
        align-self: center;
        //color: $trout;
        @include media-breakpoint-down(lg) {
            font-size: $h2-font-size;
        }
    }
    .course-icon {
        position: absolute;
        color: $white;
        left: 42%;
        z-index: 1;
    }
}

.user-rank-round {
    min-width: 33px !important;
    width: 33px !important;
    height: 33px !important;
    border-radius: 50%;
    font-size: $font-size-base;
    display: flex;
    align-items: center;
    justify-content: center;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
}

// course card image
.card-module-img {
    width: 100%;
    height: 250px;
    z-index: 1;
    position: absolute;
    /*&:before {
      content: "";
      position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%; 
		@include linearGradient (rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    }*/
}

.card-module-img-gray {
    background: rgba($primary, 0.3);
    //@include linearGradient (rgba(0,0,0,0.3), rgba(0,0,0,0.3));
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;
}

.module-text {
    width: 100%;
    color: $white;
    z-index: 1022;
    align-self: center !important;
    padding-left: 5px;
    //font-size: $font-size-md;
    //font-weight: $font-weight-bold;
    font-family: 'Poppins Bold';
}

.tag-items {
    .tag-items-link {
        //color: $pickled-bluewood;
    }
    @include media-breakpoint-down(lg) {}
}

// Tag CARD
#tags.User #ActivityTable {
    background-color: transparent;
}

.tag-card {
    th,
    td {
        padding: 0;
        border-top: $table-border-width solid transparent;
    }
    .h6 {
        color: $gray-500;
        margin-bottom: 0;
        line-height: normal;
        .user-name {
            color: $gray-500
        }
        span {
            color: $gray-500;
        }
    }
    .tag-details {
        background-color: $white;
        padding: 0.9286rem 1.143rem;
    }
}

.view-channels {
    max-height: 360px;
    overflow: auto;
}

//  Card Learn
.card-learn {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);
    margin-bottom: $spacer *2.857;
    transition: all .15s ease-in-out;
    @include media-breakpoint-down(sm) {
        margin-bottom: $spacer;
    }
    &:hover {
        box-shadow: 0 0 20px 4px rgba(0, 0, 0, .1) !important;
    }
    .card-title {
        @include media-breakpoint-down(sm) {
            font-size: $font-size-base;
        }
        a {
            @extend .text-wrap;
            -webkit-line-clamp: 2;
            display: block;
            max-height: 48px;
        }
    }
    .card-learn-footer {
        position: absolute;
        bottom: 14px;
        width: 91%;
        @include media-breakpoint-down(xl) {
            width: 90%;
        }
    }
    .card-learn-info {
        position: relative;
        width: 100%;
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        overflow: hidden;
        &:before {
            //@include linearGradient (rgba(255,255,255,0), rgba(0,0,0,0.5));
            background: rgba($primary, 0.3);
            content: "";
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
        .card-text-option {
            position: absolute;
            text-align: center;
            padding: 0 ($spacer*0.7143);
            margin: 0;
            top: 0;
            left: 0;
            z-index: 3;
            width: 100%;
            height: 100%;
            color: $white;
            //font-size: $font-size-md;
            text-transform: uppercase;
            @extend .text-wrap;
            -webkit-line-clamp: 2;
            align-items: center !important;
            justify-content: center !important;
            display: flex !important;
            text-shadow: 1px 1px 3px rgba(51, 51, 51, 1);
            @include media-breakpoint-down(sm) {
                font-size: $font-size-base;
            }
        }
    }
    .card-body {
        height: 260px;
        min-height: 260px;
        max-height: 260px;
        position: relative;
        @include media-breakpoint-down(xxl) {
            height: 250px;
            min-height: 250px;
            max-height: 250px;
        }
        @include media-breakpoint-down(xl) {
            height: 232px;
            min-height: 232px;
            max-height: 232px;
        }
        @include media-breakpoint-down(sm) {
            height: 115px;
            min-height: 115px;
            max-height: 115px;
            padding: $spacer * .75;
        }
        .card-text {
            color: $gray-500;
            @extend .text-wrap;
            display: block;
            height: 56px;
            @include media-breakpoint-down(xxl) {
                margin-bottom: $spacer*1.25;
            }
            @include media-breakpoint-down(xl) {
                height: 38px;
                margin-bottom: $spacer;
            }
        }
    }
    .card-body-library {
        height: 180px !important;
        min-height: 180px !important;
        max-height: 180px !important;
    }
    .grid-card-view {
        height: 240px;
        @include media-breakpoint-between(lg,
        xxl) {
            height: 208px;
        }
    }
    .card-tag-name {
        color: $primary;
        font-size: $font-size-sm;
        @include media-breakpoint-down(sm) {
            margin-bottom: $spacer * .75;
        }
    }
    .card-time {
        color: $gray-300;
        @extend .card-tag-name;
    }
    .article-publish-date {
        color: $text-muted;
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
    }
}

.item.list-group-items {
    float: none;
    max-width: 100%;
    flex: 100%;
    .grid-hide {
        display: none !important;
    }
    .list-show {
        display: flex !important;
        .card-tag-name {
            margin-right: $spacer;
            @include media-breakpoint-down(xxl) {
                margin-bottom: 0;
            }
        }
    }
    .card {
        display: flex;
        align-items: flex-start;
        flex-direction: inherit;
        .card-body {
            padding: 0.65rem 1.25rem;
            flex: 0 0 73%;
            max-width: 73%;
            height: inherit;
            min-height: inherit;
            max-height: inherit;
            .card-text {
                margin-bottom: 0;
                margin-top: 7px;
                height: 18px;
                width: 90%;
            }
            @include media-breakpoint-down(xxl) {
                flex: 0 0 70%;
                max-width: 70%;
                padding: .7rem 1.25rem;
            }
            @include media-breakpoint-down(xl) {
                padding: 0.825rem 1.25rem;
            }
            @include media-breakpoint-down(lg) {
                flex: 0 0 65%;
                max-width: 65%;
                padding: 0.325rem 1.25rem;
            }
            .grid-card-view {
                height: inherit;
            }
        }
        .card-title {
            max-width: 98%;
            display: inline-flex;
            margin-bottom: 0 !important;
            a {
                -webkit-line-clamp: inherit;
                height: auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 75%;
                display: inline-block;
            }
        }
        .tip-content {
            top: -70px !important;
            left: -533px !important;
            @include media-breakpoint-between(lg,
            xxl) {
                left: -450px !important;
            }
        }
    }
    .card-learn {
        margin-bottom: 15px;
        .card-body-library {
            height: inherit !important;
            min-height: inherit !important;
            max-height: inherit !important;
        }
    }
    .card-learn-info {
        //margin-right: 10px;
        max-width: 15%;
        flex: 0 0 15%;
        @include media-breakpoint-down(xl) {
            max-width: 17%;
            flex: 0 0 17%;
        }
    }
}


/*xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1800px*/

// media breakpoint
@include media-breakpoint-down(sm) {
    /*body#dashboard.User .main-wrapper .container-mobile .user-card .card-body {
            padding: 0;        
	}*/
    body#dashboard.User {
        .main-wrapper {
            .container-mobile {
                .user-card {
                    border-left: transparent;
                    border-right: transparent;
                    border-top: transparent;
                    .card-body {
                        padding: 0;
                        margin-bottom: 2rem;
                    }
                }
            }
        }
    }
    .card-think-header {
        //background-color: transparent; 
        //border: $card-border-width solid transparent;
        margin-bottom: $spacer;
        .form-control {
            padding: 1rem .75rem;
        }
        .form-control-file {
            font-size: 12px;
        }
    }
    /*#ShareExpInside {
		.btn {
			//padding: inherit;
			font-size: inherit;
			line-height: inherit;
			//border-radius: inherit;
			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}
	}*/
    .wall-card {
        //border-left: transparent;
        //border-right: transparent;
        //border-top: transparent;
        //margin-bottom: 0;
        //.card-body {
        //	padding: $spacer*1.538 $card-spacer-x;
        //}
        .h6 {
            //font-size: $font-size-md;
            margin-bottom: 0;
        }
        .nav-link {
            font-size: $font-size-base;
        }
        .card-containt {
            font-size: $font-size-base;
        }
    }
    .user-card {
        .card-footer {
            font-size: $font-size-base * 1.385;
            padding: $card-spacer-x;
        }
        .user-name {
            font-size: $font-size-lg;
        }
        .user-status {
            //font-size: $font-size-md;
        }
        .num-points {
            font-size: $font-size-base * 3.692;
        }
        .points-text {
            font-size: $font-size-base * 1.077;
        }
        .icor,
        .icos {
            font-size: $font-size-base * 2.154;
            margin-bottom: $spacer;
        }
    }
    .library-card {
        .tumbwrapper {
            min-height: auto !important;
        }
        .card-body {
            height: 125px;
            max-height: 125px;
        }
        .card-title {
            font-size: $font-size-lg;
        }
        .card-footer.justify-content-end {
            flex-direction: initial !important;
            font-size: $font-size-base * 0.7692;
        }
    }
}

@include media-breakpoint-up(xs) {
    .user-thumbnail {
        min-height: 400px;
    }
}

@include media-breakpoint-up(sm) {
    .user-thumbnail {
        min-height: 320px;
    }
}

@media (max-width: 1024px) {
    .card-list-item {
        .icor,
        .icos {
            display: block;
        }
    }
}

@include media-breakpoint-up(md) {
    .user-thumbnail {
        min-height: 200px;
    }
}

@include media-breakpoint-up(lg) {
    .user-thumbnail {
        min-height: 180px;
    }
}

@include media-breakpoint-up(xl) {
    .user-thumbnail {
        min-height: 180px;
    }
}

@include media-breakpoint-up(xxl) {
    .user-thumbnail {
        min-height: 200px;
    }
}

@include media-breakpoint-up(xxxl) {
    .user-thumbnail {
        min-height: 280px;
    }
}

@include media-breakpoint-down(xxl) {
    .user-card {
        .user-name {
            font-size: $font-size-lg;
        }
        .user-status {
            //font-size: $font-size-md;
            //margin-bottom: $spacer;
        }
        .num-points {
            font-size: $h5-font-size;
        }
        .points-text {
            //font-size: $font-size-xs;
        }
        .card-footer {
            padding: $spacer 0;
        }
    }
}

// Admin filter cards
.card-mobile {
    display: flex;
    position: relative;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
        min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
        word-wrap: break-word;
        background-color: $card-bg;
        background-clip: border-box;
        border: $card-border-width solid $card-border-color;
        @include border-radius($card-border-radius);
        flex: 1 1 auto;
        padding: $card-spacer-x;
        color: $card-color;
    }
}

.right-fixed-card-scroll {
    max-height: 930px;
    overflow-y: auto;
}

.card-idea {
    width: 720px;
    height: 400px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
}

//
.card-rate-summary {
    @include media-breakpoint-down(lg) {
        .display-4 {
            font-size: $h2-font-size;
        }
        .h4 {
            font-size: $font-size-base;
        }
    }
}

//
.card-media {
    position: relative;
    margin-bottom: $spacer*2;
}

.card-image {
    .ico-ellipsis-h,
    .card-media-icon {
        color: $white;
    }
}

.card-file {
    .card-media-icon {
        //color: $trout;
    }
    .ico-ellipsis-h {
        //color: $athens-gray;
    }
}

.media-action-button {
    position: absolute;
    top: 0;
    right: 0;
    .ico-ellipsis-h {
        font-size: $h6-font-size;
        padding: .25rem .65rem;
        //color: $white;
    }
}

.card-media-icon {
    font-size: $font-size-xxl;
    //color: $white;
    height: 40px;
    width: 40px;
    margin: auto;
    position: absolute;
    top: -6px;
    left: 0;
    bottom: 0;
    right: 0;
}

.card-media-img {
    height: 180px;
    position: sticky;
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        //@include linearGradient (rgba($pickled-bluewood,0.5), rgba($pickled-bluewood,0.5));
    }
    img {
        -webkit-mask-image: url('../../img/mask.svg');
        mask-image: url('../../img/mask.svg');
        background-position: 50% 50%;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
}

.card-media-file {
    background-color: $white;
    height: 180px;
    width: 100%;
}

//
.card-thumbnail-preview {
    height: 110px;
    width: 200px;
    //background-color: $porcelain;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        //color: $bali-hai;
        font-size: $h2-font-size;
    }
}

// upload image view
.upload-thumbnail-preview {
    @extend .card-thumbnail-preview;
    display: inline-flex;
}

.mobile-points-gained-view {
    display: flex;
    .point-gained {
        display: inline-block;
        font-family: $btn-font-family;
        //font-weight: $btn-font-weight;
        text-align: center;
        white-space: $btn-white-space;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        @include button-size($btn-padding-y,$btn-padding-x,$btn-font-size,$btn-border-radius);
    }
    .points-gained-completed {
        @extend .point-gained;
        color: $blue;
        border: $btn-border-width solid $blue;
        @include media-breakpoint-down(lg) {
            background-color: rgba($blue, .3);
            border: $btn-border-width solid transparent;
        }
    }
    .points-gained-progress {
        @extend .point-gained;
        color: $purple;
        border: $btn-border-width solid $purple;
        @include media-breakpoint-down(lg) {
            background-color: rgba($purple, .3);
            border: $btn-border-width solid transparent;
        }
    }
}

.mobile-activitiy-body {
    @include media-breakpoint-down(lg) {
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        padding: 1rem;
    }
}

//

/*
.card-active-score {	
	@include media-breakpoint-up(lg) {
		display: flex;
	}
	.card-active-score-item {
		flex-basis: 0;
		flex-grow: 1;
		max-width: 100%;
		background-color: $white;
		text-align: center;	
		padding: $spacer;	
		border-radius: $border-radius;
		margin-bottom: $spacer;
		@include media-breakpoint-up(lg) {
			margin-left: $spacer;
			&:first-child {
				margin-left: 0;
			}
		}
	}
}
*/

// single discussion
.single-discussion-tag {
    display: inline-block;
    margin: ($spacer*.5) ($spacer*.5) 0 0;
    font-size: $font-size-sm;
}

// card overlap
.card-overlap {    
    position: relative;
    .card-overlap-item {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: $zindex-card-overlap;
        .form-control-search {
            width: 100%;
        }
    }
    .card-overlap-body-scroll {
        max-height: 340px;
        overflow-y: auto;        
        @include media-breakpoint-up(xl) {
            max-height: 360px;
            @media screen and (-webkit-device-pixel-ratio: 1.25) {
                max-height: 310px;
            }
        }
        @include media-breakpoint-only(lg) {
            max-height: 320px;
        }
    }
}

.card-overlap-mobile-top-align {
    @include media-breakpoint-down(lg) {  
        top: 50% !important;
        left: 50%;
        transform: translate(-50%,-43%);
        padding: 0 $grid-gutter-width;
    }
}

.card-overlap-modal-backdrop {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1079;
    background-color: $modal-backdrop-bg;
    opacity: $modal-backdrop-opacity;
}



// dashboard card list
.card-list {
    .list-group {
        .list-group-item {
            padding: 1rem $list-group-item-padding-x;
        }
    }
}

.card-tab-content {
    background-color: transparent;
    box-shadow: inherit;
    border-bottom-right-radius: inherit;
}

// ILT and VLIT icons
.card-inner-icon-list {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    background-color: $french-pass;
    border-radius: $border-radius-pill;
}