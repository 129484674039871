.globle-search-wrapper {
    display: none !important;
}

.globle-search-block {
    display: none;
}

#dashboard,
#global_search {
    &.User {
        .globle-search-wrapper {
            display: flex !important;
        }
    }
}

#dashboard {
    .globle-search-block {
        width: 778px;
        position: fixed;
        top: 95px;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

#global_search {
    .globle-search-block-fixed {
        position: fixed;
        width: 100%;        
        background-color: $body-bg;
        z-index: $zindex-popover;
        padding: 1rem 0;
        top: 60px;
        @include media-breakpoint-up(lg) {
            top: 48px;
        }
    }
    .globle-search-block {
        display: block !important;
        position: relative;
    }
    .list-activtie-sub-link {
        margin-bottom: 0;
        border-top: $border-width solid $border-color;
        padding: $spacer;
    }
}

.globle-search-block {
    z-index: $zindex-modal;
    background-color: $white;
    border-radius: $border-radius;
    .form-control {
        padding-right: 3rem;
    }
    .btn-search-filter {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: 253px;
        }
        border: $border-width solid $border-color;
        background-color: $white;
        text-align: start;
    }
    .dropdown-menu {
        width: 253px;
    }
    .dropdown-toggle {
        &::after {
            float: right;
        }
    }
    .close-globle-search-block {
        position: absolute;
        right: 10px;
        top: 25%;
        cursor: pointer;
    }
}

// Search page
.search-result-wrapper {
    padding-top: 210px;
    @include media-breakpoint-up(sm) {
        padding-top: 156px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 146px;
    }
}