.see-more-badges-content,
.see-more-certificates-content {
        .badge-icon,
        .certificate-icon {
            background: rgba($gray-100, .2);
            align-items: center;
            justify-content: center;
            display: flex;
            border-top-left-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
            width: 220px;
            height: 195px;
            min-width: 220px;
            min-height: 195px;
        }
        .media-card {
            border: 1px solid $gray-200;
            border-radius: $border-radius;
            align-items: center;
        }
}
