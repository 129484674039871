.custom-scrollbar {
    height: 300px;
    overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}

// firefox scroll width fix
.firefox-scroll {
    scrollbar-width: thin !important;
}