.btn {
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    line-height: 1;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 12px $input-btn-padding-x-lg !important;
    font-size: $input-btn-font-size-lg !important;
    border-radius: $btn-border-radius-sm;
}

.btn-group {
    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .dropdown-toggle-split {
        border-left-color: $white;
        &::after {
            content: $navbar-toggle-icon-lg;
            vertical-align: -0.25em;
        }
    }
}

.btn-block+.btn-block {
    margin-top: 0 !important;
}

.btn-secondary {
    color: $white;
    &:focus,
    &:hover {
        color: $white;
        background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10));
    }
    &:disabled,
    &.disabled {
      color: $white;
    }
}

.btn-danger {
    color: $white;
    &:focus,
    &:hover {
        color: $white;
    }
}

.btn-success,
.btn-quick-info {
    color: $white;
    &:focus,
    &:hover {
        color: $white;
    }
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
    background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10));
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
    box-shadow: none;
}

.btn-mw {
    min-width: 170px;
    @include media-breakpoint-down(xs) {
        min-width: 120px;
    }
}

.btn-mw-md {
    min-width: 135px;
}

.btn-mw-sm {
    min-width: 100px;
}

.btn-light {
    color: $btn-light-color;
    &:focus,
    &:hover {
        color: $white;
    }
}

.btn-link {
    color: $btn-light-color;
}

.btn-choose {
    min-width: 150px;
}

.btn-notification-view {
    min-width: 180px;
}

.dropdown-menu-sm {
    min-width: $dropdown-min-width-sm;
    @include media-breakpoint-down(lg) {
        min-width: fit-content;
    }
}

.btn-comment-attachment {
    position: absolute;
    right: 0;
    top: 0;
    .btn {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label { 
        padding: .643rem .45rem .5rem;
        white-space: nowrap;
        cursor: pointer;
        color:$gray-400;
        line-height: 1;
        margin: 0;
        //@include transition($btn-transition);
        /*&:hover,
        &:focus {
            background: linear-gradient(rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.10));
        }*/
    }
}

.btn-primary {
    &:hover {
        color: $white;
        background-color: $gray-900;
        border-color: $gray-900;
    }
    &:not(:disabled):not(.disabled):active {
        color: $white;
    }
}

.btn-warning {
    color: $white !important;
    &:hover {
        color: $white;
    }
    &:not(:disabled):not(.disabled):active {
        color: $white;
    }
}

.btn-outline-warning-premium {
    color: $orange-peel;
    border-color: $orange-peel;
    background-color: rgba($orange-peel,.15) !important;
    &:hover,
    &:focus {
        color: $gray-900;
    }
}

.btn-title {
    padding: $btn-padding-x-lg;
    font-size: $btn-font-size-lg;
    border-radius: $btn-border-radius-lg;
    line-height: 1;
}

.btn-white {
    color: $gray-400;
    border: 1px solid transparent;
    /*&:focus,
    &:hover {
        color: $white;
        background-color: $gray-600;
        border-color: $gray-600;
    }*/
}

// button mobile min width
.btm-mw {
    @include media-breakpoint-up(md) {
        min-width: 180px;
    }
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

// button mobile full width
.btn-m {
    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}



// button tab full width
.btn-md {
    @include media-breakpoint-down(lg) {
        width: 100%;
    } 
}

[class*="btn-outline-"] {
    background-color: $white;
}

.dropdown {
    .dropdown-menu.show {
        @include media-breakpoint-down(lg) {
            width: 100%;
            margin: 0;
        }
    }
}

//
.btn-mobile-add {
    display: none;
    @include media-breakpoint-down(lg) {
        position: fixed;
        right: 36px;
        top: 14px;
        z-index: 1040;
        display: block;
        font-size: 20px;
    }
}

// Question mobile button full width
.btn-question-section {
    @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: space-between;
        .btn {
            flex: 1 1 auto;
        }
    }
}

.btn-answer-section {
    @include media-breakpoint-down(lg) {
        //justify-content: space-between;
        flex-direction: column;
        >.btn,
        form>.btn,
        div>.btn {
            display: block;
            width: 100%;
            margin: 0 0 .75rem 0 !important;
        }
    }
}

// jplist
.btn-jplist-dropdown {
    display: flex;
    box-shadow: $input-box-shadow !important;
    background-color: $white !important;
    border: $input-border-width solid $white !important;
    border-radius: $border-radius-sm !important;
    color: $input-color !important;
    padding: 1.072rem 2rem;    
    &:after {
        margin-left: 0.85em;
    }
    @include media-breakpoint-down(lg) {
        justify-content: space-between;
        width: 100%;
    } 
}

.jplist-dropdown-menu {
    margin-top: 6px !important;
    min-width: 189px;
} 

// quizup
.btn-quizup {
    min-width: 140px;
    @include media-breakpoint-down(sm) {
        font-size: 12px !important;
        padding: .75rem !important;
        min-width: 78px;
    }
}

.QuizupBtn {
    //min-width: ;
}

.btn-expire-course {
    color: $red !important;
    background-color: $white !important;
    border-color: $red !important;
}

// dashboard
.btn-send {
    display: none;
    opacity: 0;
    transition: 0.3s;
    @include media-breakpoint-down(lg) {
        position: absolute;
        right: 1rem;
        padding: .5rem .5rem .5rem 0;
        .feather {
            stroke: $gray-400;
        }
    }
}


#dashboard,
#library_item,
#tag_posts_view {
    &.User {
        .btn-send {
            @include media-breakpoint-down(md) {
                top: 0;
                right: 0;
                display: block;
                opacity: 1;
            }
        }        
    }
}

#dashboard,
#library_item {
    &.User {
        .btn-comment-attachment {
            @include media-breakpoint-down(md) {
                right: 2.25rem !important;
            }
        }
    }
}

#discussions,
#ideabox {
    &.User {
        .btn-plus {
            @include media-breakpoint-down(md) {
                top: 0;
                right: 0;
                display: block;
                opacity: 1;
            }
        }
        .btn-comment-attachment {
            @include media-breakpoint-down(md) {
                right: 2.25rem !important;
            }
        }
    }
}

.btn-send-active {
    display: block;
    opacity: 1;
    z-index: $zindex-fixed;
}

// ideabox
.btn-send-ideabox {
    @extend .btn-send;
    @include media-breakpoint-down(lg) {
        right: 2.75rem;
        top: 0;
    }
    @include media-breakpoint-down(md) {
        display: block;
        opacity: 1;
        right: 0;
    }
}

// lpl
.btn-lpl-start {
            position: absolute;
            left: 50%;
            bottom: 5px;
            transform: translate(-50%, -5px);
            z-index: $zindex-fixed;
        }
.btn-share-post {
    height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    .spinner-border {
        width: 10px;
        height: 10px;
    }
}

//ots

#ots_assign_training, #ots_confirm_users{
    .btn-train-user, .btn-untrain-user{

        &:checked+.btn-outline-success{
            color: $white;
            background-color: $gray-500;
            border-color: $gray-500;
        }

    }

    .trainUserBtn, .unTrainUserBtn{
        box-shadow: none;
        @include media-breakpoint-down(sm) {
            font-size: 0.875rem !important;
            padding: 0.75rem !important;
        }

        &:hover{
            color: $white;
            background-color: $gray-500;
            border-color: $gray-500;
        }

    }
    
   
}

.ots-btn{

        font-size: 1rem !important;
        padding: 12px 1rem !important;

    @include media-breakpoint-up(lg) {

        font-size: 1.5rem !important;
        padding: 1.25rem 4.5rem !important;

    }
}

//ots disable btn

#ots_training_session{
    .btn-danger.disabled{
        opacity: 0.4;
        color: #fff;
    }
}

.btn-dropdown-toggle {
    &::after {
        margin-left: 0.75em !important;
        vertical-align: 0;
    }
}

//schedule post btn

.btn-dropdown-schedule{
    border-left: 1px solid !important;
    border: 0;
    margin-left: 0;

    &::after{
        filter: brightness(2);
        transform: scale(1.8);
    }
}

#btnSchedule{
    &.btn-success.disabled{
        color: $white !important;
    }
}

.btn-back-dashboard {
    position: absolute;
    left: 0;
    background-color: $white;
    padding: ($spacer*1.125) $grid-gutter-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: $zindex-fixed;
    border-radius: 45px;
    width: 55px;
    margin-left: 1rem;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.btn-emoji {
    position: absolute;
    right: 42px;
    top: 0;
    padding: 9px 0;
    cursor: pointer;
    @include media-breakpoint-down(md) {
        right: 5rem;
        opacity: 1;
        transition: 0.3s;
    }
    .dropdown-menu {
        width: 334px !important;
        padding: 1rem 0 1rem 1rem;
    }
}

.btn-plus {
    @extend .btn-send;
    @include media-breakpoint-down(lg) {
        right: 2.75rem !important;
        padding: .5rem;
        opacity: 1;
        transition: 0.3s;    
    }
}

.btn-download {
    padding: 1rem;
    border-radius: 50%;
    background-color: #F1F3F4;
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    .feather {
        color: $black;
    }
}

.btn-download-audio {
    position: absolute;
    left: 316px;
    top: 10px;
}

.audio-js-wrapper {
    padding-bottom: 1rem;
}