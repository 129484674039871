//  highest ranked
.list-highest-ranked {
	padding: 0;
	margin: 0;
}

.list-highest-ranked-item {
	@extend .list-activity-group-item;
	&:last-child {
		   margin-right: 0;
	  }
	  .img-reward {
		  width: 30px;
		  height: 30px;
		  margin: 0 0 0 ($spacer*.75);
	  }
	  .text-reward {
		  width: 30px;
		  height: 30px;
		  margin: 0 0 0 ($spacer*.75);
		  background-color: $oasis;
		  color: $ochre;
		  border-radius: 100%;
		  display: flex;
		align-items: center;
		justify-content: center;
		//font-weight: $font-weight-semibold;
		font-family: 'Poppins SemiBold';
		font-size: $font-size-lg;
	  }
	  .feather.chevron-right {
		  stroke: $gray-400;
		  width: 12px;
		  height: 12px;
	  }
	  .ranked-user-details {
		  width: 40%;
		  @include media-breakpoint-down(lg) {
			  width: 30%;
		  }
	  }
	.form-check-input[type=checkbox] {
		min-width: 20px;
	}
}

.highest-rank-card-body-scroll {
    max-height: 260px;
    overflow-y: auto; 
	overflow-x: hidden;       
    @include media-breakpoint-up(xl) {
        @media screen and (-webkit-device-pixel-ratio: 1.25) {
            max-height: 310px;
        }
    }
    @include media-breakpoint-only(lg) {
        max-height: 260px;
    }
}

.highest-rank-widgets {
	@include media-breakpoint-up(lg) {
		min-height: 260px;
	}
}