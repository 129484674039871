$unchecked-star: url(../../img/question-answer/linear-star.svg);
$unchecked-color: #182331;
$checked-star: url(../../img/question-answer/fill-star.svg);
$checked-color: #FFB02E;

.star-cb-group {
  /* flip the order so we can use the + and ~ combinators */
  //unicode-bidi: bidi-override;
  direction: ltr;
  display: flex; 
  justify-content: flex-end;
  gap: 10px;
  @include media-breakpoint-up(sm) {
    flex-direction: row-reverse;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
  & > input {
    display: none;
    & + label {
      /* only enough room for the star */
      display: inline-block;
      overflow: hidden;
      text-indent: 9999px;
      min-width: 25px;
      width: 25px;
      height: 27px;
      white-space: nowrap;
      cursor: pointer;
      line-height: 1;
      padding: 12px 13px;
      box-sizing: content-box;
      border-radius: 10px;
      border: 1px solid #182331;
      //margin: 0 5px;
      &:before {
        display: inline-block;
        text-indent: -9999px;
        content: $unchecked-star;
        //color: $unchecked-color;
        //font-size: 30px;
      }
    }
    &:checked ~ label:before,
      & + label:hover ~ label:before,
      & + label:hover:before {
      content: $checked-star;
      //color: $checked-color;
    }
  }
  
  /* the hidden clearer */
  /*& > .star-cb-clear + label {
    text-indent: -9999px;
    width: .5em;
    margin-left: -.5em;
  }
  & > .star-cb-clear + label:before {
    width: .5em;
  }*/

  &:hover > input + label:before {
    content: $unchecked-star;
    //color: $unchecked-color;
    //text-shadow: none;
  }
  &:hover > input + label:hover ~ label:before,
  &:hover > input + label:hover:before {
    content: $checked-star;
    //color: $checked-color;
    //text-shadow: 0 0 1px #333;
  }
}

.number-cb-group {
    direction: ltr;
    display: flex;
    gap: 10px;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    & > input {
      display: none;
      & + label {
        /* only enough room for the star */
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        //text-indent: 9999px;
        min-width: 25px;
        width: 25px;
        height: 27px;
        //white-space: nowrap;
        cursor: pointer;
        line-height: 1;
        padding: 12px 13px;
        box-sizing: content-box;
        border-radius: 10px;
        border: 1px solid #182331;
        margin: 0 2px;
        margin-bottom: 10px;
        &:hover {
            background-color: #182331;
            color: $white;
        }
      }
      //&:checked ~ label,
      &:checked + label{
            background-color: #182331;
            color: $white;
        }
    }
    
    /* the hidden clearer */
    /*& > .number-cb-clear + label {
      text-indent: -9999px;
      width: .5em;
      margin-left: -.5em;
    }
    & > .number-cb-clear + label:before {
      width: .5em;
    }*/

}

.number-cb-group-10 {
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.rating-block {
    border-radius: 10px;
    border: 1px solid #182331;
    padding: 20px;
    display: inline-flex;
}
 
.number-rating-block {
    display: flex; 
    box-sizing: border-box;
}

.number-rating-item {
    border-radius: 10px;
    border: 1px solid #182331;
    padding: 13px;
    display: flex;
    margin: 0 5px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    font-size: 16px;
    font-weight: 600;
    color: #182331;
    +.active {
        color: #fff;
        background-color: #182331;
    }
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}
/*
.rank-value {
    display: flex;    
    align-items: center;
    gap: 20px;
    margin-left: 20px;
}

.rank-value-item {
    width: 300px;
    box-sizing: border-box;
    input {
        width: 100%  !important;
        box-sizing: border-box;
        height: auto;
        height: auto;
        margin-bottom: 0;
    }
}*/

.title-line-height {
  line-height: 30px;
  @include media-breakpoint-up(lg) {
    line-height: 40px;
  }
}