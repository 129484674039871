.jplist-drop-down {
	height: 35.03px !important;
	color: $input-color !important;
	background-color: $input-bg !important;
	border: $input-border-width solid $input-border-color !important;
	@include border-radius($input-border-radius, 0);
	background-image: none !important;
	text-align: left;
	.jplist-dd-panel {
		height: 35.03px !important;
		line-height: 35.03px !important;
		text-align: left;
		&:after {
			content: '\e93a' !important;
			color: $gray-600 !important;
			font-family: icomoon !important;
			font-size: $font-size-sm !important;
			right: 10px !important;
			
		}
	}
}

.jplist-panel  {	
	.jplist-pagination {
		float: inherit !important;
		@include media-breakpoint-down(lg) {
			display: none;
		}
		.jplist-current {
			color: $primary !important;
			border: 1px solid $primary;
			margin-right: 1px;
		}
		button {
			//color: $bali-hai !important;
			width: 40px !important;
			height: 40px !important;
			margin-left: -1px !important;
		}
	}
}

.jplist-pagesbox {
    display: inline-flex;
    vertical-align: middle;
    position: relative;
}

@include media-breakpoint-down(lg) {
	#quizup_category.User {
		.library {
			.text-filter-box {
				width: 100%;
			}
		}
	}
}

