.card-library-item {
	
}

.library-item-title {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	font-size: $h4-font-size;
	margin-bottom: $spacer * .5;
	line-height: initial;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	@include media-breakpoint-down(lg) {
		font-size: $font-size-base;
	}
}


.library-item-description {
	font-size: $font-size-lg;
	color: $gray-500;
	@include media-breakpoint-down(lg) {
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		font-size: $font-size-sm;
		//color: $bali-hai;
		.two-lines-wrap-min-height {
			   height: 32px;
		}
	}
}

.module-img-library-item {
	//background: url('../../img/preloader.svg') center center no-repeat;
	overflow: hidden;
	position: relative;
	padding-bottom: calc(50%);
	// @include media-breakpoint-up(sm) {
	// 	min-height: 170px;
	// }
	/*@include media-breakpoint-up(md) {
		min-height: 185px;
	}*/
}

.library-img {
	width: 100%;	
	height: 100%;
	object-fit: cover;
    object-position: center center;
	left: 0;
    top: 0;
    position: absolute;    
    visibility: visible;
	@include media-breakpoint-down(lg) {
		height: auto;
	}
}

#library_item {
	&.User {
		overflow-y: scroll;
		@include media-breakpoint-down(sm) {
			overflow: auto;
			h4 {
				text-align: inherit;
			}
		}
		/*.user-comments {
			.media {	
				max-width: 90%;
			}
		}	*/
		.libraryView {
			height: 80vh !important;
			@include media-breakpoint-down(lg) {
				height: 68vh !important;
				//margin-top: 1rem;
			}	
		}
		.documentView {
			height: 78vh !important;		
		}
		.carousel-item {
			padding-top: 0;
			a {
				height: inherit;
				width: inherit;
			}
		}
		.comment-view {
			a {
				height: auto;
				width: auto;
			}	
		}
		.video-js {
			height: 576px;
			margin-bottom: 0;
		}
		.container {
			@include media-breakpoint-down(lg) {
				margin-top: 2.75rem;
			}
		}	
		.breadcrumb-panel-fixed {
			@include media-breakpoint-down(lg) {
				display: block;
				position: relative;
				top: 0;
				padding-top: 0;
				.container {			
						margin-top: inherit;
						padding: 0;
					}
			}
		}
		#PageWrapper {
			&.container {
				@include media-breakpoint-down(lg) {
					margin-top: inherit;
				}
			}
		}
	}
}

.libray-item-control-section {
	background-color: $white;
	display: flex;
	align-items: center;
	border-radius: $border-radius;
	.carousel-control {
		padding: .78rem;
		.feather {
			stroke: $gray-400;
			width:20px;
			height: 20px;
		}
	}
}

.list-library-tag {
	margin: 0;
	padding: 0;
	.list-library-tag-item {
		display: inline-block;
		align-items: center;		
		border-radius: $border-radius-sm;
		border: $border-width solid $gray-200;
		margin-right: .35rem;
		margin-bottom: .35rem;
		padding: 0.5rem;
		line-height: 1;
	}
	.list-library-tag-item-link {
		color: $gray-400;
		font-size: $font-size-sm;		
	}
}

/*
Library List and Grid view
*/

.library-list-view {
	.card-list-body {
		display: flex;
		align-items: center;
		.module-img-library-item {
			padding-bottom: calc(11%);
			min-width: 357px;
			min-height: 180px;
			margin-bottom: 0 !important;
			margin-right: 2rem;
		}
		.dashed-divider {
			display: none !important;
		}
		.btn {
			width: 170px !important;
			margin-top: auto !important;
		}
		.library-description {
			width: 64%;
			height: 170px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex-grow: 1;
    		padding-right: 2rem;
		}
		.mb-auto {
			padding-top: 1.5rem;
		}
	}
	.two-lines-wrap {
		-webkit-line-clamp: 3;
		max-height: inherit;
    	height: auto;
	}
}

// library user bubbles
.library-user-bubbles {
	position: relative;
	width: 196px;
	min-height: 210px;
	.library-user-bubbles-items {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.library-user-bubbles-items:nth-child(1) {
		right: 0;
		top: 0;
		width: 67%;
		background-color: $malibu;
		border-radius: $border-radius-pill;
		font-size: $h2-font-size;
	}
	.library-user-bubbles-items:nth-child(2) {
		left: 0;
		bottom: 42%;
		width: 30%;
		background-color: $green;
		border-radius: $border-radius-pill;
		font-size: $font-size-lg;
	}
	.library-user-bubbles-items:nth-child(3) {
		left: 10%;
		bottom: 0;
		width: 46%;
		background-color: $portage;
		border-radius: $border-radius-pill;
		font-size: $font-size-lg;
	}
}

.card-search-view {
	.card {
		&.shadow {
			box-shadow: none !important;
			border: $border-width solid $bali-hai !important;
		}
	}
}
