// true or false
.answer-title {
    display: flex;
    justify-content: flex-end;
    .answer-column {
        width: 41px;
        text-align: center;
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
    }
}

.question-block-border {
    @include media-breakpoint-down(lg) {
        border-radius: $border-radius;
        border: $border-width solid $border-color;
    }
}

.question-block-border-item {
    @include media-breakpoint-up(lg) {
        border-radius: $border-radius;
        border: $border-width solid $border-color;
        max-width: 62%;
    }
    @include media-breakpoint-down(lg) {
        line-height: 1.5;
    }
}

.btn-group-checkbox {
    .checkbox_answer_label {
        line-height: 1.2;
    }
    .btn {
        font-size: $font-size-lg;
        //font-weight: $font-weight-semibold;
        font-family: 'Poppins SemiBold';
        padding: 1rem $btn-padding-x;
        max-width: 250px;

        &:nth-of-type(1) {
            border: 1px solid $success;
            color: $success;
        }

        &:nth-of-type(2) {
            border: 1px solid $gray-400;
            color: $gray-600;
            margin-left: .5rem;
            @include media-breakpoint-down(lg) {
                margin-left: 2%;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 0;
            }
        }
        @include media-breakpoint-down(lg) {
            margin-bottom: 1rem;
            padding: .75rem $btn-padding-x;
        }
        @include media-breakpoint-down(lg) {
            max-width: 49%;
            min-width: 49%;
        }
        @include media-breakpoint-down(sm) {
            display: block;
            max-width: 100%;
            min-width: 100%;
        }
    }
    .btn-check:active+.btn,
    .btn-check:checked+.btn {
        box-shadow: none !important;
        &:nth-of-type(1) {
            background-color: $green;
            color: $white;
        }
        &:nth-of-type(2) {
            background-color: $gray-600;
            color: $white;
        }
    }
}

.btn-group-toggle-block {
    flex: inherit;
    display: flex;
    @include media-breakpoint-up(lg) {
        margin-left: 4rem;
    }
    @include media-breakpoint-down(sm) {
        display: block;
    }
}

// drag and drop
.droppable.blank {
    border-bottom: 2px dotted $black;
    width: 250px;
    height: 48px;
    display: inline-block;
    margin-bottom: -3px;
}

.text-drop-section {
    vertical-align: baseline;
    min-height: 30px;
    @include media-breakpoint-down(lg) {
        line-height: 1.8;
    }
}


/*@include media-breakpoint-down(lg) {
	select.selectApplied {
		font-size: $font-size-sm;
		padding: 3px 6px;
	}
}*/

.card-drop {
    border: $border-width solid $border-color;
    padding: $card-spacer-x;
    min-height: 60px;
    //margin-bottom: $spacer*2.5;
    background-color: $input-bg;
    width: 100%;
    display: block;
}

.card-droppable {
    width: 25%;
    padding-right: 7px;
    padding-left: 7px;
    margin-bottom: $spacer;
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

.select-image {
    min-height: 280px;
    max-height: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ui-draggable-handle {
    max-width: 250px;
    line-height: 1.4;
    @include media-breakpoint-down(lg) {
        max-width: 200px;
    }
    @include media-breakpoint-down(lg) {
        max-width: inherit;
        width: 100%;
        margin: 0 auto;
    }
}

// Cross Match
.match-words-section.ui-draggable-handle {
    max-width: 80%;
    .btn {
        line-height: $line-height-lg;
    }
} 

#WordsContainer {
    .match-words-section {
        .btn {
            text-align: left;
        }
    }
}

#AnswersContainer {
    .match-words-section {
        .btn {
            text-align: right;
        }
    }
}

// jumble words
.jumble_words {
    .words {
        display: inline;
    }
    .sortable {
        cursor: move;
    }
    .ui-sortable-handle {
        margin-right: 5px;
        @include media-breakpoint-down(lg) {
            line-height: 2;
        }
    }
    .list-num {
        float: left;
        margin-right: 5px;
    }
}

// Highlight Words
.simillar {
    //display: flex;
    .mark_word {
        cursor: pointer;
        //margin-right: $spacer*.25;	
    }
    .active {
        color: $secondary;
    }
}

// cut off words
.cut_off_words {
    .cut-word {
        display: flex;
        cursor: pointer;
        .cut {
            text-decoration: line-through;
            color: $secondary;
        }
    }
}

// Type in Blanks
.answersInput {
    border-top: 0;
    border-right: 0;
    border-bottom: $input-border-width solid $input-border-color;
    border-left: 0;
    color: $input-color;
    margin: $spacer*.5;
}

// Match Words to Group
// Drop box
.mw-group {
    .multi_drop {
        .ui-draggable-handle,
        .selections {
            min-width: 200px;
            margin: $spacer $spacer 0 0;
            @include media-breakpoint-down(lg) {
                min-width: inherit;
                width: 100%;
                margin: $spacer 0 0;
            }
        }
    }
    .muliDrag {
        .ui-droppable {
            &:first-child {
                @include media-breakpoint-up(lg) {
                    margin-top: 34px;
                }
            }
            .ui-draggable-handle {
                max-width: inherit;
            }
        }
    }
    .label_wrapper {
        min-height: 60px;
        padding: 0 $input-btn-padding-x $input-btn-padding-x;
    }
}

._jsPlumb_connector path {
    stroke: $secondary;
    fill: $secondary;
}

#question_task.multi_drop {
    .label_wrapper {
        margin-bottom: 1rem;
    }
}

.label_wrapper {
    min-height: 100px;
    width: 100%;
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    display: block;
    border-radius: $border-radius;
    padding: $input-btn-padding-x;
    /*margin-top: $spacer * .25;
	margin-bottom: $spacer;*/
    @include media-breakpoint-down(lg) {
        min-height: 150px;
    }
    .ui-draggable-dragging {
        margin-right: $spacer * .5;
        @include media-breakpoint-down(lg) {
            width: 100% !important;
            margin-bottom: $spacer * .5;
            //text-align: left;
        }
    }
}

.muliDrag {
    .droppable {
        @include media-breakpoint-down(lg) {
            width: 100%;
            display: block;
            margin-bottom: .5rem;
        }
    }
}

// Mobile title
@include media-breakpoint-down(lg) {
    .mobile-question-title-section {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        .btn {
            margin-top: $spacer;
            margin-bottom: $spacer;
        }
    }
}

// match words section
.match-words-section {
    @include media-breakpoint-down(lg) {
        .btn {
            width: 100%;
        }
    }
}

// Game Quizeup page
// MCQ 
.AdminContainer,
.UserContainer {
    display: none;
}

#quizup_question_answer_preview .AdminContainer,
#quizup_question .UserContainer {
    display: block;
}

// Answers Poppup - good - poor -not bad
#question.User {
    .modal {
        @include media-breakpoint-up(lg) {
            max-width: 800px !important;
        }
    }
    .modal-confirm {
        @include media-breakpoint-up(lg) {
            max-width: 650px !important;
        }
    }
    .modal-body {
        //overflow: inherit;
        max-height: fit-content;
        .points-title {
            position: absolute;
            font-size: $h1-font-size;
            //font-weight: $font-weight-bold;
            font-family: 'Poppins Bold';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -34%);
            line-height: 1;
            width: 90%;
            text-align: center;
            @include media-breakpoint-down(lg) {
                font-size: $h2-font-size;
            }
            @include media-breakpoint-down(xs) {
                font-size: $h3-font-size;
            }
        }
        .points-title-poor {
            color: $danger;
        }
        .points-title-good {
            color: $success;
        }
        .points-title-not-bad {
            color: $warning;
        }
        .points-description {
            text-align: center;
            padding: 3rem 2rem;
            @include media-breakpoint-down(lg) {
                padding: 1.5rem 1rem;
                .text-xl {
                    font-size: $font-size-base;
                }
            }
            .points-description-text {
                font-size: $h2-font-size;
                //font-weight: $font-weight-medium;
                font-family: 'Poppins Medium';
                line-height: 1;
                @include media-breakpoint-down(lg) {
                    font-size: $h4-font-size;
                }
            }
            .points {
                font-size: $font-size-xxl;
                //font-weight: $font-weight-bold;
                font-family: 'Poppins Bold';
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 1;
                @include media-breakpoint-down(lg) {
                    font-size: $h3-font-size;
                }
            }
            .big {
                margin: 0 ($spacer*.5);
                /*@include media-breakpoint-down(lg) {
					font-size: $h3-font-size;
				}*/
            }
        }
    }
    /*.img-points {
		//padding: 0 ($spacer*3.5) 0;
		@include media-breakpoint-down(lg) {
			height: 200px;
			width: 100%;
			text-align: center;
			margin-bottom: $spacer;
			padding: $spacer $spacer 0;
		}
	}*/
    @include media-breakpoint-down(lg) {
        .breadcrumb-panel-fixed {
            .nav-pills {
                width: 100%;
                .nav-item {
                    margin-right: 0;
                    flex: 1;
                    .nav-link {
                        border-radius: 0;
                        text-align: center;
                    }
                    &:first-child {
                        .nav-link {
                            border-top-left-radius: $border-radius;
                            border-bottom-left-radius: $border-radius;
                        }
                    }
                    &:last-child {
                        .nav-link {
                            border-top-right-radius: $border-radius;
                            border-bottom-right-radius: $border-radius;
                        }
                    }
                }
                .nav-item:nth-of-type(2) {
                    border-left: $border-width solid $border-color;
                    border-right: $border-width solid $border-color;
                }
            }
        }
    }
    .documentView {
        min-height: 750px;
    }
    #activity-assessments-tab {
        @include media-breakpoint-down(lg) {
            min-width: 300px;
        }
    }
}

.correct-answer-explanation {
    text-align: center;
    padding: 2rem;
    @include media-breakpoint-down(lg) {
        padding: 1.5rem;
    }
    .correct-answer-header {
        font-size: $h4-font-size;
        //font-weight: $font-weight-bold;
        font-family: 'Poppins Bold';
        @include media-breakpoint-down(lg) {
            font-size: $h6-font-size;
        }
    }
    .correct-answer-text {
        font-size: $h4-font-size;
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
            font-size: $h6-font-size;
        }
    }
}

.list-question-status {
	list-style: none;
	padding: 0;
	margin: 0;
	.list-question-status-item {
		padding: 1rem 0;
		font-size: $h4-font-size;
		line-height: 1;
		border-bottom: $border-width solid $border-color;
		&:first-child {
			padding-top: 0;
		}
		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: $border-width solid transparent;
		}
	}
	.icon-question-item {
		background-color: $athens-gray;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		min-width: 40px;
		min-height: 40px;
		.feather {
			stroke: $gray-400;
		}
	}
	.list-question-status {			
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		padding-top: .5rem;
		font-size: $font-size-sm;
		@include media-breakpoint-up(lg) {
			min-width: 170px;	
			@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
		    text-align: center;
		}
	}
	.list-question-status-incomplete {
		color: $gray-400;
		@include media-breakpoint-up(lg) {			
			border: 1px solid $gray-400;
		}
	}
	.list-question-status-complete {
		color: $green;
		@include media-breakpoint-up(lg) {			
			border: 1px solid $green;
		}
	}
}

.list-question-block {
    display: flex;
    flex: 1;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
    }
    @include media-breakpoint-up(lg) {
        align-items: center;
    }
}

// Activity Score
.score-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    font-size: $font-size-xl;
    width: 80%;
    text-align: center;
    @include media-breakpoint-up(lg) {
        font-size: $h1-font-size;
    }
}

.score-subtitle {
    font-size: $font-size-xl;
    @include media-breakpoint-up(lg) {
        font-size: $h2-font-size;
    }
}

.img-activity-score {
    position: relative;
    width: 100%;
    @include media-breakpoint-up(lg) {
        height: 200px;
    }
}

//
.list-activity-score {
    list-style: none;
    padding: 0;
    margin: 0;
    .list-activity-score-item {
        //padding: 1rem 0;
        font-size: $font-size-lg;
        //line-height: 1;
        border-bottom: $border-width solid $border-color;
        padding: 1rem 0;
        &:first-child {
            //border-top: $border-width solid $border-color;
            padding-top: 0;
        }
        &:last-child {
            border-color: transparent;
            padding-bottom: 0;
        }
    }
    .icon-activity-item {
        background-color: $athens-gray;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        .feather {
            stroke: $gray-400;
        }
    }
    .list-activity-total-scores {
        line-height: 1.1;
        @include media-breakpoint-up(lg) {
            min-width: 200px;
        }
        @include media-breakpoint-down(lg) {
            font-size: $font-size-sm;
            padding-top: .5rem;
        }
    }
}

.breadcrumb-button-section {
    @include media-breakpoint-down(lg) {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
}


/*
Drag Labels To Picture
*/

.image-labels-block {
    text-align: center;
    width: 100%;
    height: 100%;
    float: left;
    padding-right: 7px;
    padding-left: 7px;
    box-sizing: border-box;
    @include media-breakpoint-up(lg) {
        max-width: 25%;
    }
}


/*
breadcrumb in mobile
*/

@include media-breakpoint-down(lg) {
    .breadcrumb-panel-mobile {
        display: block !important;
        position: inherit !important;
        .container {
            padding-right: 0;
            padding-left: 0;
        }
        .flipclock-sm {
            margin: 2.5rem 1rem 1rem;
        }
    }
}

.nav-tabs-questions-mobile {
    @include media-breakpoint-down(lg) {
        .nav-link.active {
            border: $nav-tabs-border-width solid $nav-tabs-border-color;
        }
    }
}

//
.show-answer-block {
    min-height: 227px;
}