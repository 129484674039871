.nav-link {
    transition-duration: 0.3s;
}

// Top Nav
.top-nav {
    background-color: $gray-600;
    height: 48px;
    padding-top: 6px;
    z-index: $zindex-top-navbar;
    .nav-link {
        color: $white;
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
        padding: $top-nav-link-padding-y $top-nav-link-padding-x;
        &:hover {
            opacity: .7;
        }
    }
    .dropdown-menu {
        //padding:0;			
        .dropdown-item {
            padding: ($spacer*.75) $dropdown-item-padding-x;
            text-overflow: ellipsis;
            overflow: hidden;
            .icor,
            .icos {
                font-size: $dropdown-icon-size;
                margin-right: $spacer;
            }
            &:active {
                .icor,
                .icos {
                    color: $white;
                }
            }
        }
        .dropdown-divider {
            margin: 0;
        }
    }
    .dropdown-toggle {
        color: $white;
        &::after {
            position: relative;
            opacity: 0.5;
        }
    }
    .dropdown-toggle.active {
        color: $white;
    }
    .non-avatar {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        padding-top: 3px;
    }
    .nav-right {
        .dropdown-menu {
            top: 9px !important;
            min-width: 190px;
            max-width: 27rem;
            /*&:after {
				bottom: 100%;
				right: 7%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-bottom-color: #fff;
				border-width: 5px;
				@include media-breakpoint-down(xxl) {
					right: 5%;
				}
			}*/
        }
    }
    .nav-notification {
        .dropdown-menu {
            top: 18px !important;
            transform: translate3d(-220px, 28px, 0px) !important; 
            padding: $dropdown-padding-y 0 0;
            @media (min-width: 1024px) and (max-width: 1366px) {
                //transform: translate3d(-74px, 28px, 0px) !important;
            }
            /*&:after {
				bottom: 100%;
				left: 20%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(136, 183, 213, 0);
				border-bottom-color: #fff;
				border-width: 5px;	
				@media (min-width: 1024px) and (max-width: 1366px) {
					left: 31%;
				}				
			}*/
            .dropdown-menu-size {
                max-height: 34.75rem;
                overflow-x: hidden;
            }
            .dropdown-item {
                white-space: inherit;
                //width: 24rem;
                color: $gray-600;
                padding: ($spacer*.75) $dropdown-item-padding-x 0;
                /*&:hover,
				&:focus {
					background-color: rgba($gray-500,.10);
				}*/
                .flex-grow-1 {
                    border-bottom: $border-width solid $border-color;
                    padding-bottom: ($spacer*.75);
                }
                .non-avatar {
                    width: $avatar-size !important;
                    height: $avatar-size !important;
                    min-width: $avatar-size !important;
                    min-height: $avatar-size !important;
                    max-width: $avatar-size !important;
                    max-height: $avatar-size !important;
                }
            }
            .un-read {
                background-color: #f4f4f4;
            }
            li.dropdown-item:last-child {
                .flex-grow-1 {
                    border-bottom: $border-width solid transparent;
                }
            }
        }
        .feather {
            width: 20px;
            height: 20px;
            stroke: $white;
        }
        .chevron-right {
            stroke: $navbar-light-color-icon;
            min-width: 14px;
            min-height: 14px;
            width: 14px;
            height: 14px;
        }
        .notification-block-icon {            
            stroke: $success !important;
            fill: $success !important;
            min-width: 8px;
            min-height: 8px;
            width: 8px;
            height: 8px;
        }
        &:hover {
            .notification-block-icon {
                stroke: $success !important;
                fill: $success !important;
            }
        }
    }
    .top-nav-username {
        max-width: 160px;
    }
}

.notification-more {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: $white;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    padding: 10px 0;
}
.notification-block {
    position: relative;
    max-height: 463px;
    min-width: 500px;
    overflow: hidden;
}
.notification-scroll {
    overflow-y: auto;
    max-height: 420px;
    margin-bottom: 68px;
    .flex-grow-1 {
        border-bottom: $border-width solid $border-color;
        padding-bottom: ($spacer*.75);
    }
    .chevron-right {
        stroke: $navbar-light-color-icon;
        min-width: 14px;
        min-height: 14px;
        width: 14px;
        height: 14px;
    }
    li.dropdown-item:last-child {
        .flex-grow-1 {
            border-bottom: $border-width solid transparent;
        }
    } 
}

.mobile-notification-block {
    position: relative;
    max-height: 463px;
    min-width: 355px;
    overflow: hidden;

}

.mobile-logo {
    @include media-breakpoint-down(lg) {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
    }
}
.mobile-end-nav {
    display: none;
    @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;
    }
}

/*
#notificationList {
    max-height: 470px;
    min-width: 355px;
    overflow: auto;
}
*/
.div-notification {
    @include media-breakpoint-down(lg) {
        //max-height: 333px;
        overflow: hidden;
        width: 95%;
        right: 50% !important;
        transform: translate(50%);
        top: 70px;
        .dropdown-item {
            white-space: inherit;
        }
    }
    @include media-breakpoint-between(md, lg) {
        width: 97%;
    }
}

// top nav end
.main-nav {
    top: 48px;
    display: flex !important;
    height: 100px;
    background-color: $body-bg;
    @include media-breakpoint-down(lg) {
        top: 0;
        height: 60px;
    }
    .dropright.dropdown-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        &:after {
            border-top: $caret-width solid transparent;
            border-right: 0;
            border-bottom: $caret-width solid transparent;
            border-left: $caret-width solid;
        }
    }
}

.navbar-brand {
    .img-fluid {
        height: 60px;
        @include media-breakpoint-down(lg) {
            height: 36px;
        }
    }
    @include media-breakpoint-down(lg) {
        margin-right: 0;
    }
}

.d-toggle-hide {
    &:after {
        content: none !important;
    }
}

.top-left-nav{
    max-width: 160px;
    white-space: nowrap;
}

.top-right-nav {
    padding-top: 3px;
    .nav-item {
        min-height: 30px;
        line-height: 1.5;
        display: flex;
        align-items: center;
        &:last-child {
            border-right: 0 solid transparent;
        }
        .nav-link {
            padding: 0 $spacer;
            @include media-breakpoint-up(xl) {
                padding: 0 $top-nav-link-padding-x;
            }
        }
    }
    .nav-vertical-divider {
        width: 1px;
        height: 15px;
        background-color: $nav-vertical-divider;
    }
    .feather {
        stroke: $navbar-light-color-icon;
    }
    .dropdown-item {
        &:focus {
            .feather {
                //stroke: $navbar-light-active-color-icon;
            }
        }
    }
}

.navbar-light {
    .navbar-nav {
        .nav-item {
            @include media-breakpoint-up(lg) {
                text-align: center;
            }
            .nav-link {
                padding: 3px 20px 0;
                //font-weight: $font-weight-medium;
                font-family: 'Poppins Medium';
                position: relative;
                transition-duration: 0.3s;
                @include media-breakpoint-between(lg, xl) {
                    padding: 3px 14px 0;
                }
                @include media-breakpoint-down(lg) {
                    padding: .75rem 0;
                    display: flex;
                    align-items: center;
                    font-size: $font-size-lg;
                }
                &:focus {
                    .nav-icon {
                        //background-color: rgba($navbar-light-active-color-icon, .3);			
                    }
                    .uil {
                        color: $navbar-light-active-color-icon;
                        transition-duration: 0.3s;
                    }
                    /*.feather {
					stroke: $navbar-light-active-color-icon;
				 }
				 &::after {
					 color:$link-hover-color;
				}*/
                }
                .feather {
                    use{
                        transition: 0.3s;
                    }
                    
                }
                &:hover{
                    .feather {
                        use{
                            stroke: $secondary;
                        }
                        
                    }
                }
            }
            .nav-icon {
                /*width: 24px;
			height: 24px;
			border-radius: 100%;*/
                margin: 0 auto 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition-duration: 0.3s;
                font-size: 24px;
                line-height: 1;
                @include media-breakpoint-down(lg) {
                    margin: 0 1.75rem 0 0;
                    font-size: 29px;
                }
            }
            .uil {
                color: $gray-500;
                transition-duration: 0.3s;
                @include media-breakpoint-up(lg) {
                    display: block;
                }
                @include media-breakpoint-down(lg) {
                    margin-bottom: 0;
                }
            }
            .active {
                .nav-icon {
                    //background-color: rgba($navbar-light-active-color-icon, .3);			
                }
            }
            .active {
                .nav-icon {
                    .uil {
                        color: $navbar-light-active-color-icon;
                        transition-duration: 0.3s;
                    }
                }
            }
            .active {
                color: $navbar-light-active-color;
            }
        }
        .dropdown-menu {
            margin-top: .75rem;
        }
    }
}

.menu-dropdown-toggle {
    position: relative;
    line-height: 1;
    &::after {
        content: $navbar-toggle-icon;
        border: none;
        width: 6px;
        height: 6px;
        //position: absolute;
        bottom: 8px;
        margin-left: 6px;
        stroke: $navbar-light-color-icon;
    }
}

.dropdown-toggle {
    &::after {
        content: $navbar-toggle-icon;
        border: none;
    }
}

.dropdown-toggle-none {
    &::after {
        content: none;
    }
}


/*
#mydiv:before {
    content: url("data:image/svg+xml; utf8, <svg.. code here</svg>");
    display:block;
    width:22px;
    height:10px;
    margin:10px 5px 0 10px;
}
*/

.nav-slidebar-top {
    display: none;
}

@include media-breakpoint-down(lg) {
    // Mobile Menu
    .navbar-mobile {
        display: flex;
        flex-wrap: wrap;
        z-index: $zindex-nav-mobile;
        position: fixed;
        width: 100%;
        padding: ($spacer*.5) 0;
        background-color: $white;
        /*box-shadow: 0 0px 0px transparent, 0 0px 8px rgba(0,0,0,0.25);*/
        .ico-list,
        .ico-bell {
            font-size: $h3-font-size;
            //color: $gray-500;
        }
        #left-toggle {
            //z-index: 1200;
            padding-left: .8rem;
        }
        #right-toggle {
            z-index: 1100;
            padding-right: 6px;
        }
        .nav-left {
            .dropdown-menu {
                width: 300px;
                left: -10px !important;
            }
        }
    }
    .navbar-mobile-toggleable {
        padding: 0.5714rem;
        &:focus {
            border-radius: 50%;
            background-color: $gray-200;
        }
    }
    /* Menu Button */
    /*.navbar-mobile-toggleable {
		position: relative;
		//z-index: 1200; // $zindex-nav-mobile-toggle
		border-color: transparent;
		background-color: transparent;
		margin-top: 10px;
		color: $gray-600;
	}*/
    .sidebar-wrapper {
        width: 0;
        background: $white;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1000;
        max-height: 100vh!important;
        height: 100vh;
        overflow-y: auto;
        transition: 0.5s;
        //@include transition($nav-transition-in);
        //box-shadow: ($nav-box-shadow);
        h4 {
            color: $gray-300;
            margin-top: .75rem;
            margin-bottom: 1.5rem;
        }
        .non-avatar {
            align-items: center;
            color: $white;
            justify-content: center !important;
            @include border-radius($card-border-radius);
            font-size: $font-size-lg;
        }
        .list-inline-item,
        .mobile-rank-border,
        .mobile-progress-bar {
            position: absolute;
            width: 100%;
            left: 0px;
            .progress {
                @include border-radius(0);
            }
        }
    }
    .fake-top-background {
        display: none;
        width: 0;
        height: 63px;
        position: fixed;
        top: 0;
        background-color: $white;
        .f-bg {
            position: fixed;
            width: 100%;
            height: 63px;
            background-color: $white;
        }
    }
    .nav-slidebar-top.active {
        display: flex;
        position: fixed;
        justify-content: space-between;
        width: 100%;
        padding: .431rem 0 0.5rem;
        background-color: #fff;
        align-items: center;
        z-index: 1020;
    }
    .left-active {
        //left: 100% !important;
        width: 100%;
        //@include transition($nav-transition-out);  
        //box-shadow: $nav-box-shadow;
        /*.fake-top-background {
			@include transition($nav-transition-out);  
			width: 100%;
			left: 0;
			display: block;
			z-index: 1;
		  }*/
    }
    .right-active {
        right: 100% !important;
        width: 100%;
        @include transition($nav-transition-out);
        box-shadow: $nav-box-shadow;
    }
    .sidebar-wrapper-left {
        margin-left: -100%;
        z-index: $zindex-nav-sidebar !important;
        left: 0;
        //overflow: hidden;
    }
    .sidebar-wrapper-right {
        margin-right: -100%;
        right: 0;
    }
    .nav-mobile {
        opacity: 0;
        .nav-item {
            //border-top: 1px rgba($gray-200,.2) solid; 
            .nav-link {
                //font-size: $font-size-xl;
                color: $gray-800;
                padding: .75rem 0;
            }
            .dropdown-menu {
                left: 0px;
                width: 100%;
            }
            .dropdown-menu.tree.show {
                border: transparent;
                box-shadow: none;
                padding: 0;
                margin: 0;
            }
            .dropdown-toggle::after {
                position: absolute;
                right: 11px;
                top: 23px;
                //color: $pickled-bluewood;
            }
            .dropdown-item {
                font-size: $h5-font-size;
                padding: .75rem .375rem .75rem 3.45rem;
            }
        }
        .tree {
            .nav-item {
                .nav-link {
                    //color: $gray-200;
                    padding: .75rem .375rem .75rem 4.5rem;
                }
            }
        }
    }
}

.nav-mobile.active {
    padding: 6em 1.5em 0 !important;
    animation-delay: 5s;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeIn;
    opacity: 1;
}

.nav-scroll {
    overflow: auto;
}

.nav-tabs {
    .nav-item {
        //box-shadow: $box-shadow;
        .nav-link {
            //border-color: $nav-tabs-border-color;
            //border-right-color: transparent;	
            border-radius: 0;
        }
        &:first-child {
            .nav-link {
                border-top-left-radius: $border-radius;
            }
            .nav-link.active {
                /*&:after {
					content: none;
				}*/
            }
        }
        &:last-child {
            .nav-link {
                border-top-right-radius: $border-radius;
                /*&:after {
					content: none;
				}*/
            }
        }
    }
    .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 185px;
        background-color: $alabaster;
        color: $gray-600;
        padding: 18px $nav-link-padding-x;
        @include media-breakpoint-down(xl) {
            min-width: 140px;
        }
        @include media-breakpoint-down(lg) {
            min-width: 150px;
        }
        @include media-breakpoint-down(sm) {
            min-width: auto;
            font-size: $font-size-sm;
        }
        /*&:after {
			display: inline-block;
			content: "";
			width: 1px;
			height: 45px;
			background-color: $border-color;			
			position: absolute;
			right: 0;
		}*/
    }
    .active {
        //font-weight: $font-weight-semibold;
        font-family: 'Poppins SemiBold';
    }
}

.tab-content {
    background-color: $white;
    box-shadow: $box-shadow;
    border-top-right-radius: $border-radius;
        border-bottom-right-radius:  $border-radius;
        border-bottom-left-radius:  $border-radius;
    .tab-pane {
        table {
            box-shadow: none !important;
        }
    }
}

//

/*
.nav-pills-group {
	.nav-item {
		border-top: $nav-tabs-border-width $nav-tabs-border-color solid;
		border-bottom: $nav-tabs-border-width $nav-tabs-border-color solid;
		border-right: $nav-tabs-border-width $nav-tabs-border-color solid;		
		&:first-child {
			border-left: $nav-tabs-border-width $nav-tabs-border-color solid;
			border-top-left-radius: $nav-tabs-border-radius;
			border-bottom-left-radius: $nav-tabs-border-radius;
		}
		&:last-child {			
			border-top-right-radius: $nav-tabs-border-radius;
			border-bottom-right-radius: $nav-tabs-border-radius;
		}
		.nav-link {
			color: $nav-pills-link-active-color;
			width: 100%;
			height: 100%;
			align-items: center;
			display: flex;
			justify-content: center;
		}
		.active {
			font-weight: $font-weight-medium;
			font-family: 'Poppins Light';	
		}
	}
}
*/

.nav-pills {
    .nav-item {
        //margin-right: 1rem;
        &:last-child {
            margin-right: 0;
        }
        &:first-child {
            .nav-link {
                border-top-left-radius: $nav-pills-border-radius;
                border-bottom-left-radius: $nav-pills-border-radius;
            }
        }
        &:last-child {
            .nav-link {
                border-top-right-radius: $nav-pills-border-radius;
                border-bottom-right-radius: $nav-pills-border-radius;
            }
        }
    }
    .nav-link {
        background-color: $gray-400;
        color: $white;
        line-height: 1;
        padding: .929rem 1.45rem;
        border-radius: 0;
        @include media-breakpoint-down(md) {
            font-size: $font-size-xs;
        }
    }
}

// nav pills mobile
.nav-pills-mobile {
    margin-right: -$grid-gutter-width * .5;
    margin-left: -$grid-gutter-width * .5;
    background: $white;
    .nav-link {
        border-radius: 0;
        border-bottom: 2px solid $white;
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
        //color: $bali-hai;
    }
    .active {
        background-color: $white !important;
        //border-bottom: 2px solid $pickled-bluewood;
        //color: $pickled-bluewood;
    }
}

.nav-pills-mobile-tab-content {
    margin-right: -$grid-gutter-width * .5;
    margin-left: -$grid-gutter-width * .5;
}

.nav-pills-outline-white {
    .nav-item {
        flex-grow: 1;
        &:first-child {
            .nav-link {
                border-left: 1px solid $ghost;
            }
        }
    }
    .nav-link {
        width: 100%;
        background-color: $white;
        color: $gray-400; 
        border-top: 1px solid $ghost;
        border-right: 1px solid $ghost;
        border-bottom: 1px solid $ghost;
        &.active {
            background-color: $athens-gray-three;
            color: $gray-600;
            //font-weight: $font-weight-medium !important;
            font-family: 'Poppins Medium';
        }     
    }
    .tab-content {
        box-shadow: inherit;
        border-bottom-right-radius: inherit;
    }
}

//
.nav-mobile-inner {
    display: none;
    @include media-breakpoint-down(lg) {
        background-color: $white;
        margin-bottom: $spacer;
        padding: ($spacer*1.125) $grid-gutter-width;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        z-index: $zindex-fixed;
        top: 0;
        left: 0;
        right: 0;
        position: fixed;
        height: 60px;
    }
    .btn-back {
        padding: 0.5714rem;
        &:focus {
            border-radius: 50%;
            background-color: $gray-200;
        }
    }
}

.nav-mobile-right-action-section {
    position: absolute;
    right: 1rem;
    top: 12px;
    display: flex;
    width: 80px;
    height: 40px;
    justify-content: space-between;
}

#emp_directory.User {
    .nav-mobile-right-action-section {
        .square {
            width: 400px !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    #emp_directory.User, //#quizup_user_list.User,
    //#quizup_list.User,
    #library.User, //#courses.User,
    #games.User {
        .nav-mobile-inner {
            z-index: 1040;
            background-color: transparent;
            width: auto;
            .nav-mobile-right-action-section {
                width: 0;
                z-index: 1024;
                position: fixed;
                top: 23px;
            }
        }
        .jplist-panel {
            .nav-mobile-right-action-section {
                display: none;
            }
        }
    }

    #library.library-items-view {
        .nav-mobile-inner {
            background-color: $white;
        }
    }

}

.user-notifications {
    position: relative;
    padding: 0.5714rem;
    &:focus {
        @include media-breakpoint-down(lg) {
            border-radius: 50%;
            background-color: $gray-200;
        }
    }
    .notification-count {
        position: absolute;
        right: 15px;
        top: -9px;
        width: 20px;
        height: 20px;
        border-radius: 45px;
        font-size: 10px;
        align-items: center;
        justify-content: center;
        display: flex;
        color: $white;
        @include media-breakpoint-up(lg) {
            border: 1px solid $gray-600;
        }
        @include media-breakpoint-down(lg) {
            right: 3px;
            top: -3px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .user-notifications {
        display: none;
    }
    #dashboard.User {
        .user-notifications {
            display: flex;
        }
    }
}

// Quetions
#question.User {
    @include media-breakpoint-down(lg) {
        .tab-content {
            box-shadow: none;
            border: $card-border-width solid $card-border-color;
            border-top-color: transparent;
        }
        .match-words-section {
            .btn {
                text-align: left;
            }
        }
    }
}

.nav-mobile-search,
.closebtn,
.btn-setting,
.btn-add {
    padding: 0.5714rem;
    &:focus {
        border-radius: 50%;
        background-color: $gray-200;
    }
}

// Mobile search bar
#dashboard,
#mobile_rewards,
#mobile_collaborate,
#question,
#comprehension,
#library_item,
#quizup_question,
#activity_score,
#user_settings,
#mobile_courses,
#layup_ai_chat,
#user_survey_view { 
    &.User {
        .nav-mobile-search {
            display: none;
        }
    }
}

#dashboard.User.due-dashboard {
    .nav-mobile-search {
        display: flex;
        position: absolute;
        right: 0px;
    }
    .user-notifications {
        position: absolute; 
        right: 40px;
    }
}

.nav-fake {
    //@include media-breakpoint-up(md) {
    display: none;
    //}
}
#dashboard.User.due-dashboard,
#mobile_rewards.User,
#mobile_collaborate.User,
#ideabox.User,
#question.User,
#comprehension.User,
#library_item.User,
#quizup_question.User,
#activity_score.User,
#user_settings.User,
#mobile_courses.User,
#library_item.User,
#rank_map.User,
#list_questions.User,
#courses.User,
#points.User,
#badges.User,
#global_search.User,
#user_survey_view.User {
    .nav-fake {
        display: flex;
        width: 40px;
    }
}

.nav-right-fake-div {
    width: 24px;
}

.btn-search {
    height: $input-height;
    //padding: $input-padding-y $input-padding-x;
    margin-left: $input-padding-x;
    font-size: $h3-font-size;
    color: $danger;
    overflow: hidden;
    width: 26px;
    height: 24px;
    min-width: 26px;
    min-height: 24px;
    max-width: 26px;
    max-height: 24px;
    line-height: 1;
}

.mobile-search-bar {
    opacity: 0;
    height: 0;
    width: 100%;
    position: fixed;
    z-index: 1041;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
    overflow-x: hidden;
    transition: 0.5s;
    overflow: hidden;
    box-shadow: $box-shadow;
    .mobile-search-bar-show {
        padding: ($spacer*.75) $spacer 0;
    }
}

// filter option
.nav-filter-post {
    .nav-item {
        min-width: 220px;
        margin: 0 $spacer $spacer 0;
        @include media-breakpoint-down(xl) {
            min-width: auto;
            width: 100%;
            margin: 0 0 $spacer 0;
        }
    }
    .nav-link {
        background-color: $white;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        padding: ($spacer * 1.072) $nav-link-padding-x;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        &:after {
            margin-left: 0.85em;
        }
    }
    .dropdown-menu {
        min-width: 220px;
        margin-top: 6px !important;
        @include media-breakpoint-down(lg) {
            min-width: auto;
            width: 100%;
        }
    }
    .dropdown-item.active {
        position: relative;
        &:after {
            content: $dropdown-active-icon;
            width: 12px;
            height: 12px;
            position: absolute;
            stroke: $secondary;
            bottom: 15px;
            right: 14px;
        }
    }
}