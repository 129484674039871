label {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
}
textarea {
  resize: none;
  overflow: hidden;
}

.form-check-inline {
    display: inline-block; // over write the css
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	line-height:normal!important;
}
input::-moz-placeholder { /* Firefox 19+ */
	line-height:normal!important;
}
input:-ms-input-placeholder { /* IE 10+ */
	line-height:normal!important;
}
input:-moz-placeholder { /* Firefox 18- */
	line-height:normal!important;
}

select.form-control {
  	padding: 0.895rem 0.75rem 0.678rem;
	background-image: escape-svg($dropdown-cart);
	background-repeat: no-repeat;
	background-position: $dropdown-cart-bg-position;
	background-size: $dropdown-cart-bg-size;
	appearance: none;
	option {
		padding: 6px;
	}
}

.form-control {
	word-break: break-all;
	padding: $input-btn-padding-y $input-btn-padding-x 0.45rem;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	//line-height: 40px;
	@media screen and (-webkit-device-pixel-ratio: 1.25) { 
		//padding: 1rem $input-btn-padding-x 0.45rem;
	}
	&:disabled,
	[readonly] {
		color: rgba($gray-600,.5);
	}
}

.form-control-send {
	@include media-breakpoint-down(lg) {
		padding: .765rem 6.75rem .45rem .45rem;
	}
}

.form-control-scroll {
	overflow-y: auto;
}

#SubmitShareInput {
	@media screen and (-webkit-device-pixel-ratio: 1.25) { 
		padding: $input-btn-padding-y $input-btn-padding-x 0.45rem;
	}
}

textarea.form-control {
	line-height: 1.5;
}

textarea:focus, input[type='text']:focus, input[type='password']:focus, input[type='datetime']:focus, input[type='datetime-local']:focus, input[type='date']:focus, input[type='month']:focus, input[type='time']:focus, input[type='week']:focus, input[type='number']:focus, input[type='email']:focus, input[type='url']:focus, input[type='search']:focus, input[type='tel']:focus, input[type='color']:focus, .uneditable-input:focus, input:focus:required:invalid:focus, textarea:focus:required:invalid:focus, select:focus:required:invalid:focus {
	border-color: $input-focus-border-color !important;
}
.form-control-comment {
	padding-right: 5rem !important;
	@include media-breakpoint-down(lg) {
		padding-right: 6.75rem !important;
	}
}
.form-control.country-code {
	//@include media-breakpoint-down(lg) {
		width: 80px !important;
		//margin-left: 0 !important;
	//}
}
.form-control-attachment {
	 padding: .65rem 120px .65rem $input-padding-x;
}

.form-control-comment,
.form-control-attachment {
	height: 40px !important;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
	+ label {
		display: inline-block;
		cursor: pointer; /* "hand" cursor */
	}
	+ label * {
		pointer-events: none;
	}
}

.file-upload {
	position: relative;
	margin-right: .75rem;
	input.form-control-file {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
		font-size: 20px;
		cursor: pointer;
		opacity: 0;
		filter: alpha(opacity=0);
		width: 118px;
		height: 40px;
	}
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}

#fileuploadurl{
	display: inline-block;
	border:none;
	vertical-align: middle;
	background: none;
	box-shadow: none;
	padding-left: 0;
	width:220px;
	padding-right: 0;
	@include media-breakpoint-down(lg) {
		width:300px;
	}
	@include media-breakpoint-down(sm) {
		width:100%;
	}
}

#fileuploadurl::placeholder {
	  color: $primary !important;
}

.search-filter {
	display: flex;	
}

.search-filter.input-group {
	    width: inherit;
		.form-control,
		.btn {
			background-color: $white;
		}
}

.form-control-search {
	padding: 0.715rem $input-btn-padding-x-lg 0.5rem 3.5rem;
    background-image: url(../../img/search.svg);
    background-repeat: no-repeat;
    background-position: left $input-height-inner-quarter center;
    background-size: $input-height-inner-half $input-height-inner-half;
	width: 385px;
}

.form-control-search-lg {
	padding:  0.715rem $input-btn-padding-x-lg 0.5rem 3.5rem;
}

input.form-control-search::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
	line-height: 3.5;
}

input.form-control-search::-ms-input-placeholder { /* Microsoft Edge */
   line-height: 3.5;
}

input.form-control-search:-ms-input-placeholder {/* IE 10+ */
	line-height: 3.5;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: $input-border-width solid $input-border-color;
  -webkit-text-fill-color: $input-color;
  -webkit-box-shadow: $input-box-shadow;
  transition: $input-transition;
}

.custom-checkbox {
	padding-left: $form-check-padding-start !important;
	.form-check-input {
		margin-top: ($line-height-base - $form-check-input-width) * .5 !important;
	}
}

.custom-checkbox-lg {
	min-height: 40px;
	.form-check-input {
		width: 40px;
		height: 40px;
	}
	.form-check-label {
		&:before,
		&:after {
			width: 40px;
			height: 40px;
		}
	}
}

.custom-checkbox-center {
	padding: 0 !important;
    margin: 0;
    display: flex;
    justify-content: center;
	.form-check-input {
		// left: 50%;
		// transform: translate(-50%);
		margin-left: inherit;
	}
}

// Select 2

.select2-container-multi .select2-choices .select2-search-field input {
	height: 18px;
	padding: 0 !important;
   margin: 0 !important;
}

.select2-container-multi .select2-choices {
	border: none !important;
	background-color: transparent !important;
	background-image: none !important;
}

.select2-container-multi.select2-container-active .select2-choices {
	box-shadow: none !important;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
    background: transparent !important;
}

.select2-container-multi .select2-choices .select2-search-choice {
	padding: 3px 5px 3px 22px !important;
     margin: 0  .5rem 0 0 !important;
	 border: 1px solid $input-border-color !important;
}
.select2-search-choice-close {
	background: none !important;
	font-family: 'icomoon' !important;
	color: $gray-600;
    font-size: 12px !important;
	&:before {
		content: "\e91c";
	}
	&:hover {
		text-decoration: none !important;
		color: $danger;
	}
}

.select2-container {
	width: 100% !important;
	height: 40px !important;
	z-index: $zindex-select2 !important;
	.select2-selection--single {
		height: 40px !important;
	}
}

.select2-container .select2-choice {
	line-height: 33px !important;
	height: 35px !important;
	background-image: none !important;
	background-color: $input-bg !important;
	border: $input-border-width solid $input-border-color !important;
	border-radius: $input-border-radius !important;

  @include box-shadow($input-box-shadow);
  @include transition($input-transition);
}

.select2-container .select2-choice .select2-arrow {
	border-left: none !important;
	background: none !important;
	background-image: none !important;
}

.select2-container .select2-choice .select2-arrow b {
	background: none !important;
}

.select2-container--default {
	.select2-selection--single {
		.select2-selection__rendered {
			line-height: 38px !important;
		}
		.select2-selection__arrow {
			height: 38px !important;
		}
	}
}

//
.logo-thumbnail-preview {
	width:360px;
	height: 160px;
	//border:$input-border-width solid $input-border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	border: $card-border-width solid $card-border-color;
   @include border-radius($card-border-radius);
	i {
		//color: $bali-hai;
		font-size: $h2-font-size;
	}
}

.login-thumbnail-preview {
@extend .logo-thumbnail-preview;
	height: 200px;
}


// switches
//
// Tweak a few things for switches

.custom-switch {
    padding-right: 4.25rem;
	padding-left: inherit;
	width: 100%;
  .form-check-label {	  
	  right: 0; 
	  width: 100%;
    &::before {
      top: 0;
	   left: inherit;
	  right: -4rem;
      width: 40px;
	  height: 20px;
    }

    &::after {
      top: calc(.23rem + 0px);
      //right: calc(#{-($custom-switch-width + $custom-control-gutter)} - #{$custom-control-indicator-border-width * 2});
	  left: inherit;
    }
  }
 .form-check-input:checked ~ .form-check-label {
    &::after {
      transform: translateX(1.35rem);
    }
  }
}

.list-admin-setting {
	.custom-switch-size,
	.col-3 {
		flex: 0 0 31.33333%;
		max-width: 31.33333%;
	}
}

.pick-a-color-markup {
		display: flex;
	    align-items: center;
	.hex-pound {
	    padding: $input-padding-y $input-padding-x;
		background-color: $input-group-addon-bg;
		border: $input-border-width solid $input-group-addon-border-color;
		border-right: 0;
	}
	.btn-group {
	    padding: .15rem 0;
		background-color: $input-group-addon-bg;
		border: $input-border-width solid $input-group-addon-border-color;
		border-left: 0;
	}
	.pick-a-color {
		height: $input-height;
	  padding: $input-padding-y $input-padding-x;
	  font-family: $input-font-family;
	  @include font-size($input-font-size);
	  //font-weight: $input-font-weight;
	  line-height: $input-line-height;
	  color: $input-color;
	  background-color: $input-bg;
	  background-clip: padding-box;
	  border: $input-border-width solid $input-border-color;
	}
}

//
.tokenfield {
	display: flex;
	flex-wrap: wrap;
    overflow: auto;
    font-size: 13px;
	border-radius: $border-radius-sm;
	.ui-autocomplete-input {
		background-color: transparent;
		border: none;
	}
	.token {
		display: flex;
		align-items: center;
		background-color: $white;
		padding: 0 5px;
		margin: 0 5px 3px 0;
		border-radius: $border-radius-sm;		
		.close {			
			width: 13px;
			height: 12px;		
			margin-left: 8px;
			margin-top: 3px;
			font-size: 12px;
			background-color: transparent !important;
			&:hover,
			&:focus {
				background-color: transparent !important;
			}
		}
		.token-label {
			font-size: $font-size-sm;
			//font-weight: $font-weight-medium !important;    
    		font-family: 'Poppins Medium';
			line-height: 1;
		}
	}
	&.form-control {
		padding-top: .622rem;
		min-height: 40px;
	}
}

// JP List
.jplist-select {
	height: $input-height !important;
	box-shadow: $input-box-shadow !important;
	padding: $input-padding-y $input-padding-x !important;
	background-color: $white !important;
	border: $input-border-width solid $input-border-color !important;
	border-radius: $input-border-radius !important;
	color: $input-color !important;
	margin: 0 .5rem 0 0 !important;
}

.text-filter-box {
	height: auto !important;
	margin: inherit !important;
}

// datepicker
.input-picker {
	.input-datepicker {
			.form-control {
				background: $input-bg url(../img/img-calendar.png) right no-repeat;		
			}
			.form-control-sm {
				background: $input-bg url(../img/img-calendar.png) right no-repeat;
				background-size: 40px auto;
			}
	}

	.datetimepicker {
		.form-control {
			background: $input-bg url(../img/img-time.png) right no-repeat;
		}
		.form-control-sm {
			background: $input-bg url(../img/img-time.png) right no-repeat;
			background-size: 40px auto;
		}
	}

	.form-control {
		padding-right: 3.75rem;
		cursor: pointer;
		word-break: break-all;
	}

	.input-group-text {
		background-color: $input-bg;
		cursor: pointer;
		&:before {
			content: ""; 
			width: 1px;
			height: 20px;
			background-color: $input-border-color;
			left: -11px;
			position: relative;
		}
	}
}

.input-group-text {
	padding: 0.62rem $input-padding-x;
}

// sell courses
.img-radio-check {
	display: flex;
	align-items: center;
	.btn-check {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		pointer-events: none;
		&:checked+.btn-outline-light {
    		border-color: $primary;
		}
	}
	.btn {
		min-width: 100px;
		height: 64px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		margin: 0;
	}
	.btn-outline-light {
		background-color: $athens-gray-two;
		border-color: $athens-gray-two;
	}
}

.form-radio-button {
	.btn,
	.btn-check {
		&:hover,
		&:active,
		&:active+ &,
		&:checked,
		&:checked+.btn-outline-secondary {
			color: $white !important;
		}
	}
}

.selAllCheck{
	border-radius: 4px !important;
	&:checked{
		background-color:$gray-500;
		border-color:$gray-500;
	}
}

.form-select-width {	
	@include media-breakpoint-up(md) {
		width: auto;
	}	
}