// card hover reaction view
.feed {
  position: relative; 
  width: 90px;
 height:20px;
  .like-btn {    
    position: absolute;
    cursor: pointer;
    z-index: 1;
    &::before {
      content: "."; opacity: 0;
      display: block;
      width: 48px; height: 12px;
      position: absolute; 
      top: -12px; left: 0;
    }
	.feather {
		width: 20px;
		height: 20px;
	}
    
    .reaction-box {
      position: absolute;
      width: 207px; height: 40px;
      background: $white;
      border-radius: 28px;
      left: -4px; bottom: 24px; 
      display: none;
	 box-shadow: $box-shadow;
      
      .reaction-icon {
        width: 30px;
		height: 30px;
        display: inline-block;
        //background: #8959A8;
        border-radius: 20px;
        margin: 5px -1px 0 8px;
        text-align: center;
        // Animation
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        opacity: 0;
        transform: translate(0, 100px) scale(0);
        
        label {
          padding: 3px 5px 3px 5px;
          position: relative;
          top: -16px;
          border-radius: 10px;
          font-size: 11px;
          color: #FFF;
          background: #333;
          visibility: hidden;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    &:hover {
      //background: #718C00;
      text-decoration: none;
      .reaction-box {
        //display: block;  
                
        .reaction-icon {
          
          &.show {
            opacity: 1;
            transform: translate(0, 0) scale(1);
          }
          
          &:hover {
            transform: scale(1.4);
            transform-origin: bottom;

            label {
              visibility: visible;
            }
          }
        }
      }
    }
  }
  .reaction-like {
	  background:url(../../img/reaction/like.svg);
  }
  .reaction-love {
	  background:url(../../img/reaction/love.svg);
  }
  .reaction-funny {
	  background:url(../../img/reaction/funny.svg);
  }
  .reaction-celebrate {
	  background:url(../../img/reaction/celebrate.svg);
  }
  .reaction-insightful {
	  background:url(../../img/reaction/insightful.svg);
  }
}

// card reaction list view
.list-icon-reaction {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	.list-icon-reaction {		
		margin-left:5px;
		&:first-child {
			margin-left:10px;
		}
		img {
			width: 15px;
			height: 15px;
		}
	}
}

.popup-icons {
  height: 20px;
  width: 20px;
}

// like popup reaction list
.list-reaction {
  list-style: none;
  padding: 0;
  margin: 0;
  .list-reaction-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: $border-width solid $border-color;
    &:last-child {
      margin-bottom:0;
      padding-bottom: 0;
      border-color: transparent;
    }
  }
  .popup-icons {
    height: 25px;
    width: 25px;
  }
}

.mobile-like-modal {
  @include media-breakpoint-down(lg) {
    display: flex;
    flex-direction: column;
    height: 100%;
    .list-reaction-show {
      .underline-reset {
        font-size: 11px;
      }
      li {
        margin-right: 12px !important;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

/*
@yohan need to code this properly
*/
.tabbable-line {
  div {
    .nav-tabs {
      border: none;
      margin: 0px;
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li {
        margin-right: 20px;
        padding-bottom: 1rem;
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li {
        a {
          border: 0;
          margin-right: 0;
          color: #737373;
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li {
        a {
          i {
            color: #a6a6a6;
          }
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.open, .tabbable-line {
        .nav-tabs {
          li:hover {
            border-bottom: 2px solid $primary;
          }
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.open {
        a, .tabbable-line {
          .nav-tabs {
            li:hover {
              a {
                border: 0;
                background: none !important;
                color: #333333;
              }
            }
          }
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.open {
        a {
          i, .tabbable-line {
            .nav-tabs {
              li:hover {
                a {
                  i {
                    color: #a6a6a6;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.open .dropdown-menu, .tabbable-line {
        .nav-tabs {
          li:hover .dropdown-menu {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.active {
        border-bottom: 2px solid $primary;
        position: relative;
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.active {
        a {
          border: 0;
          color: #333333;
        }
      }
    }
  }
}

.tabbable-line {
  div {
    .nav-tabs {
      li.active {
        a {
          i {
            color: #404040;
          }
        }
      }
    }
  }
}

.tabbable-line {
  .tab-content {
    margin-top: -3px;
    background-color: #fff;
    border: 0;
    border-top: 1px solid #eee;
    padding: 15px 0 0 0;
    box-shadow: 0 0px 0px 0px rgba(140,155,172,.15);
  }
}

//



.portlet .tabbable-line {
  .tab-content {
    padding-bottom: 0;
  }
}

.like-popup-img{
  height: 54px;
  width: 54px;
  border-radius: 10%;
}

.like-pop-non-avatar {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex!important;
  color: $white;
  justify-content: center !important;
  @include border-radius($card-border-radius);
  &:focus {
    color: $white !important;
    text-decoration: none;
  }
}

.like-popup-scroll {
  max-height: 454px;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-breakpoint-down(lg) {
    margin-bottom: 1rem;
  }
}