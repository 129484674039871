.rank-title {
    font-size: $h3-font-size;
    //font-weight: $font-weight-semibold;
    font-family: 'Poppins SemiBold';
    margin-top: 2rem;
}

.rank-map {
    .swiper-container {
        width: 100%;
        padding-top: 150px;
        padding-bottom: 50px;
        cursor: inherit !important;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        @include media-breakpoint-down(md) {
            background-color: $white;
            border-radius: $border-radius;
        }
    }
    .card-swiper {
        @include media-breakpoint-up(lg) {
            box-shadow: $box-shadow-lg;
            background-color: $white;
        }
        padding: 1rem;        
        border-radius: 20px;
        text-align: center;
        cursor: grab !important;
        @include media-breakpoint-down(lg) {
            img {
                height: 250px;
            }
        }
    }
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
        background-image: none !important;
    }
    .swiper-slide {
        .item-img,
        .rank-title {
            opacity: .27;
        }
        .item-img {
            @include media-breakpoint-up(lg) {
                min-height: 32vh;
            }
        }
    }
    .swiper-slide-prev,
    .swiper-slide-next {
        .item-img,
        .rank-title {
            opacity: .54;
        }
        .item-img {
            @include media-breakpoint-up(lg) {
                min-height: 32vh;
            }
        }
    }
    .swiper-slide-active {
        .rank-map-sub-title,
        .rank-details {
            @include media-breakpoint-up(md) {
                display: block;
            }
        }
        .item-img,
        .rank-title {
            opacity: 1;
        }
        .item-img {
            @include media-breakpoint-up(lg) {
                min-height: 33vh;
            }
        }
        .text-scale {
            transform: scale(1);
        }
        .rank-details {
            padding: 0 1rem 1rem;
            position: relative;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            //margin-top: 1rem;
            @include media-breakpoint-up(lg) {
                margin-top: 4rem;
                padding: 1rem;
            }
            .rank-status {
                //font-weight: $font-weight-semibold;
                font-family: 'Poppins SemiBold';
                align-items: center;
                .feather {
                    stroke: $gray-400;
                    stroke-width: 1.5;
                    max-width: 24px;
                    max-height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                }
            }
        }
    }
.points-show-text {
    font-size: $font-size-xl; // 20
    @include media-breakpoint-down(lg) {
        font-size: $font-size-lg; // 16
    }
}
    /*.owl-carousel {
		width: 95%;
		margin: 0 auto;
	}
	.owl-stage-outer {
		    height: 320px;
			@include media-breakpoint-up(md) {
				height: 380px;
			}
			@include media-breakpoint-up(xl) {
				height: 500px;
			}			

	}
	.owl-item { 
		.item {
		   transform: translate3d(0, 0, 0); 
		  margin:0; 
			img {
				transition: 0.3s;
				transform: scale(0.60);
				height: 290px;
				@include media-breakpoint-down(xxl) {
					height: 250px;
				}
				@include media-breakpoint-down(xl) {
					height: 170px;
				}
			}
		}	
	}
	.owl-item.center {
		.item {
			img {
				transform: scale(1);
				//margin-bottom: 1rem;
			}
			.rank-title {
				font-size: $h2-font-size;
			}
		}
	}
	.owl-item.active {
		//transform: scale(0.60);
	}*/
}

.swiper-custom-control {
    position: relative;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    //align-items: flex-end;
    justify-content: center;
    z-index: $zindex-sticky;
    /*top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/
    .swiper-button-next,
    .swiper-button-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        background-color: rgba($ghost, .2);
        margin: 0 7px;
        left: 0;
        right: 0;
        position: relative;
        top: 0;
        @include border-radius($border-radius-lg);
        .feather {
            stroke: $gray-400;
        }
        &:after {
            content: none;
        }
    }
}

.rank-map-sub-title,
.rank-details {
    display: none;
}

.text-scale {
    //transition: 0.3s;
    transform: scale(0.2);
}


/*
.center {	
	.rank-map-sub-title {
		//display: flex;
		justify-content: center;
		position: relative;		
		left: 50%;
		//bottom: -45px;
		width: 85vw;
		transform: translate(-50%);
		display: none;
		margin: 1rem 0;
		@include media-breakpoint-up(xl) {
			width: 28vw;
		}
		@include media-breakpoint-up(md) {
			display: flex;
		}
	}
}
*/

.rank-progress {
    background-color: rgba($gray-400, .2);
    box-shadow: none;
    height: 10px;
    //display: none;
    //@include media-breakpoint-up(md) {
    display: flex;
    //}
}

.rankmap-user-list {
    max-height: 510px;
    overflow-y: auto;
    overflow-x: hidden;
}

.rank-map {
    .swiper-wrapper {
        .swiper-slide {
            .card-swiper,
            .text-scale {
                @media (min-width: 640px) and (max-width: 991px) {
                    min-width: 250px !important;
                }
            }
        }
    }
}

#rank_map {
    &.User {
        .modal-body {
            overflow: initial;
        }
    }
}