.icons-media {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.icons-media-lg {
	width: 60px;
	height: 60px;
}