.bootstrapWizard {
    display: block;
    list-style: none;
    padding: 0;
    position: relative;
    width: 100%
}

.bootstrapWizard a:hover,.bootstrapWizard a:active,.bootstrapWizard a:focus {
    text-decoration: none
}

.bootstrapWizard li {
    display: block;
    float: left;
    width: 20%;
    text-align: center;
    padding-left: 0
}

.bootstrapWizard li:before {
    border-top: 2px solid #1AB3B2;
    content: "";
    display: block;
    font-size: 0;
    overflow: hidden;
    position: relative;
    top: 11px;
    right: 1px;
    width: 100%;
    z-index: 1
}

.bootstrapWizard li:first-child:before {
    left: 50%;
    max-width: 50%
}

.bootstrapWizard li:last-child:before {
    max-width: 50%;
    width: 50%
}

/*.bootstrapWizard li.complete .step {
    background: #0aa66e;
    padding: 1px 6px;
    border: 1px solid #338fff;
}*/

.bootstrapWizard li .step i {
    font-size: 18px;
    //font-weight: 400;
    position: relative;
    top: 2.5px
}

.bootstrapWizard li .step {
    background: white;
    color: #00D664;
    display: inline;
    font-size: 15px;
    font-weight: 700;
    font-family: 'Poppins Bold';
    line-height: 12px;
    padding: 7px 13px;
    border: 2px solid #00D664;
    border-radius: 50%;
    line-height: normal;
    position: relative;
    text-align: center;
    z-index: 2;
    transition: all .1s linear 0s
}

.bootstrapWizard li.active-one .step,.bootstrapWizard li.active-one.complete .step {
    background: #338fff;
    color: #fff;
    font-weight: 700;
    font-family: 'Poppins Bold';
    padding: 7px 13px;
    border-radius: 50%;
    border: 1px solid #338fff;
}

.bootstrapWizard li.previous-active .step,.bootstrapWizard li.previous-active.complete .step {
	background: #338fff;
    color: #fff;
    font-weight: 700;
    font-family: 'Poppins Bold';
    padding: 7px 8px;
    border-radius: 50%;
    border: 1px solid #338fff;
}

.bootstrapWizard li.complete .title,.bootstrapWizard li.active-one .title {
    color: #338fff;
}

.bootstrapWizard li .title {
    color: #00d664;
    display: block;
    line-height: 15px;
    max-width: 100%;
    position: relative;
    table-layout: fixed;
    text-align: center;
    top: 20px;
    word-wrap: break-word;
    z-index: 104
}

.bootstrapWizard li .title-previous {
    color: #338fff;
    display: block;
    line-height: 15px;
    max-width: 100%;
    position: relative;
    table-layout: fixed;
    text-align: center;
    top: 20px;
    word-wrap: break-word;
    z-index: 104
}

.wizard-actions {
    display: block;
    list-style: none;
    padding: 0;
    position: relative;
    width: 100%
}

.wizard-actions li {
    display: inline
}

.tab-content.transparent {
    background-color: transparent
}