// stylelint-disable declaration-no-important

// MAX Width

@each $prop, $abbrev in (max-width) {
  @each $size, $length in $size {
    .mw#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

// MIN Width
@each $prop, $abbrev in (min-width) {
  @each $size, $length in $size {
    .min#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}