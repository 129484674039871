.modal {
     display: none;
     top: 10%;
     margin: 0 0 0 -280px;
}

.modal.loading .modal-header .loader {
    height: 36px;
    width: 100%;    
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: no-repeat scroll url('../img/preloader.svg') center center;
}