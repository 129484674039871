@import "admin/components/date-range-picker";

#scheduledSave{
    width: 100px;
}

.modal-share {
    .modal-header.noneImport{
        display: none !important;
        @include media-breakpoint-down(lg) {
            display: none !important;
        }
    }
}

#scheduledView{
    .card-body{
        padding-bottom: 0;
        padding-top: 0;
    }

    .sched-calender{
        &.mobCalDisable{
            width: 100% !important;
            display: none;
        }

        &.mobCalEnable{
            width: 100% !important;
            display: block;

            .daterangepicker{
                max-width: 100% !important;

                .drp-calendar{
                    width: 100%;
                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                        padding-right: 0;
                    }

    
                    td.active{
                        &::before{
                            width: 100%;
                        }
                    }
                }
            }
            
        }
    }

    .sched-custom{
        &.mobCusEnable{
            width: 100% !important;
            border-right: 0;
        }

        &.mobCusDisable{
            width: 100% !important;
            display: none;
        }

        border-right: 1px solid $athens-gray;
        padding-bottom: 1rem;

        .sched-custom-header{
            padding-top: 1rem;
        }

        .btn-outline-trout{

            border-color: $ghost;

            .label-text{
                width: calc(100% - 124px);
            }

        }

        
        .or-spacer{

            margin: 3rem 0;

            .hr-line {
                border-top: 1px solid $ghost;
                width: 4rem;
                position: absolute;
                top: 12px;
                opacity: 0.2;

                &.left{
                    left: 100px;
                    @include media-breakpoint-down(lg) {
                        left: 25%;
                    }
                }

                &.right{
                    right: 100px;
                    @include media-breakpoint-down(lg) {
                        right: 25%;
                    }
                }
            }
        }
        
    }

    #schedCustomRange{

        .daterangepicker {
            display: block !important;
            top: 0 !important;
            left: 0 !important;
            position: relative !important;
            box-shadow: none;
            margin: 0;
            transform: translate(0, 0)  !important;

            .disable-calendar{
                pointer-events: none;
                opacity: 0.4;
            }

                .calendar-table{
                    thead{
                        tr:first-child{
                            th{
                                padding-top: 10px;
                                vertical-align: top;

                                &.available{
                                    padding-top: 7px;
                                }
                            }
                        }
                    }
                    th{
                        
                        font-weight: 600;
                        font-family: "Poppins SemiBold";

                        @media screen and (-webkit-device-pixel-ratio: 1.25){
                            min-width: 50px;
                            width: 50px;
                            height: 50px;
                            font-size: 1.143rem;
                        }

                        &.available{

                            &:hover{
                                background-color: transparent;
                            }
                        }

                    }
                    td{
                        //font-weight: 500;
                        font-family: "Poppins medium";

                        @media screen and (-webkit-device-pixel-ratio: 1.25){
                            min-width: 50px;
                            width: 50px;
                            height: 50px;
                            font-size: 1.143rem;
                        }

                        &.active {
                            color: $white;

                            &::before{
                                @media screen and (-webkit-device-pixel-ratio: 1.25){
                                    width: 50px;
                                    height: 50px;
                                }
                            }
                        }
                    }
                }
            
                .calendar-time{
                    display: flex;
                    width: 80%;
                    align-items: center;

                    select{
                        //font-weight: 500;
                        font-family: "Poppins medium";
                        width: 80px;
                        padding: 6px 12px;
                        font-size: 1.143rem;
                        border: 0;
                        color: $primary ;
                        background: $athens-gray;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                        background-repeat: no-repeat;
                        background-position-x: 100%;
                        background-position-y: 8px;
                        border-radius: 4px;

                        @include media-breakpoint-down(sm) {
                            width: 60px;
                            font-size: 1rem;
                        }

                        option{
                            padding: 1rem;
                        }
                    }
                }


            .drp-buttons{
                display: none;
            }
        }
    }

  
}