.popover {
    max-width: 650px;
	border-radius: $border-radius;
    border: none;
    box-shadow: $box-shadow;
	@include media-breakpoint-down(lg) {
		max-width: 80%;
	}
	@include media-breakpoint-down(lg) {
		max-width: 60%;
	}    
	.arrow {
		display: none;
	}    
}

#courses {
    &.User {
        .popover .popover-arrow {
            display: none !important;
        }
    }
}

.popover-header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
	//font-weight: $font-weight-medium !important;
	font-family: 'Poppins Medium';
}

.popover-body {
    border: none;
    padding: 1rem;
    font-size: $font-size-lg;
    z-index: 2;
    .popover-close {
        position: absolute;
        top: 5px;
        right: 10px;
        opacity: 1;
    }
}

//curriculum popover - no need
// .unlock-details{

//     max-width: 100% !important;   

//     .popover-arrow{
//         display: none;
//     }

//     .popover-header{
//         padding-left:0 ;
//         padding-right:0 ;
//         margin-left: 14px;
//         margin-right: 14px;
//         border-color: #F0F2F5 !important;
//     }

//     .popover-body{

//         .icon-popover-warpper{
//             width: 40px;
//             height: 40px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             background-color: $french-pass;
//             border-radius: 100%;
//             padding: 1.4rem;
//         }
    
//         .curc-details{
//             font-size: 1.143rem;
//         }
    
//     }
// }



