$maxSizeBreakpoint: 600px;
$padding: 40px;
$leafFontsize: 60px;
$leafHeight: 40px;

$leafWidth2Digits: 120px;
$leafWidth3Digits: 148px;
$leafBorderRadius: 8px;
$leafShadowOpacity: 0.8;
$gapSize: .10px;
$foldSize: .10px;
$perspective: 300px;
$labelMarginTop: 8px;
$duration: 0.3s;

$padding-sm: 22px;
$leafFontsize-sm: 30px;
$leafHeight-sm: 20px;
$leafWidth2Digits-sm: 70px;
$leafWidth3Digits-sm: 76px;
$leafBorderRadius-sm: 4px;
$perspective-sm: 150px;

/// Flipclock Looks
.flipclock {
  display: flex;
  justify-content: center;
	.flipclock * {
	  box-sizing: inherit;
	}
	.leaf {
	  display: flex;
	  flex-direction: column;
	  margin: 0 1rem;
	  perspective: $perspective;
	}

	.leaf::before {
	  content: attr(data-label);
	  position: absolute;
	  top: -24px;
	  left: 50%;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
	  transform: translateX(-50%);
	  font-size: $font-size-sm;
	}

	.top,
	.bottom,
	.leaf-front,
	.leaf-back {
	  position: relative;
	  display: block;
	  height: $leafHeight;
	  width: $leafWidth2Digits;
	  background-color: $white;
	  color: $red;
	  overflow: hidden;
	  border-color: $ghost;

	  span {
		position: absolute;
		left: 50%;
		width: 100%;
		height: $leafHeight * 2;
		text-align: center;
		//font-weight: $font-weight-semibold;
		font-family: 'Poppins SemiBold';
		font-size: $h1-font-size;
		line-height: 90px;
		transform: translateX(-50%);
	  }
	}

	.leaf._3-digits {
	  .top,
	  .bottom,
	  .leaf-front,
	  .leaf-back {
		width: $leafWidth3Digits;
	  }
	}

	.top,
	.leaf-front {
	  border-top-left-radius: $border-radius;
	  border-top-right-radius: $border-radius;
	  margin-bottom: 0;
	  border-bottom-style: solid;
	  border-bottom-width: 0;
	  
	  span {
		top: 0;
	  }
	}

	.leaf-front {
	  position: absolute;
	  z-index: 10;
	}

	.bottom,
	.leaf-back {
	  border-bottom-left-radius: $border-radius;
	  border-bottom-right-radius: $border-radius;
	  border-top-style: solid;
	  border-top-width: ($foldSize * .5);
	  span {
		bottom: 0;
	  }
	}

	.leaf-back {
	  position: absolute;
	  top: $leafHeight;
	  z-index: 10;
	}
}

.flipclock-sm {
	display: flex;
   justify-content: center;
	.flipclock * {
	  box-sizing: inherit;
	}
	.leaf {
	  display: flex;
	  flex-direction: column;
	  margin: 0 .5rem;
	  perspective: $perspective;
	}

	@include media-breakpoint-down(lg) {
		.leaf::before {
		  content: attr(data-label);
		  position: absolute;
		  top: -24px;
		  left: 50%;
			//font-weight: $font-weight-medium;
			font-family: 'Poppins Medium';
		  transform: translateX(-50%);
		  font-size: 10px;
      min-width: 70px;
      text-align: center;
		}
	}
	.top,
	.bottom,
	.leaf-front,
	.leaf-back {
	  position: relative;
	  display: block;
	  height: $leafHeight-sm;
	  width: $leafWidth2Digits-sm;
	  background-color: $white;
	  color: $red;
	  overflow: hidden;
	  border-color: $ghost;
@include media-breakpoint-down(lg) {
      height: 30px;
      width: 80px;
    }
	  span {
		position: absolute;
		left: 50%;
		width: 100%;
		height: $leafHeight-sm * 2;
		text-align: center;
		//font-weight: $font-weight-semibold;
		font-family: 'Poppins SemiBold';
		font-size: $h2-font-size;
		line-height: 45px;
		transform: translateX(-50%);
@include media-breakpoint-down(lg) {
        height: 60px;
        font-size: 2.75rem;
        line-height: 60px;
      }
	  }
	}

	.leaf._3-digits {
	  .top,
	  .bottom,
	  .leaf-front,
	  .leaf-back {
		width: $leafWidth3Digits-sm;
	  }
	}

	.top,
	.leaf-front {
	  border-top-left-radius: $border-radius-sm;
	  border-top-right-radius: $border-radius-sm;
	  margin-bottom: 0;
	  border-bottom-style: solid;
	  border-bottom-width: 0;
	  
	  span {
		top: 0;
	  }
	}

	.leaf-front {
	  position: absolute;
	  z-index: 10;
	}

	.bottom,
	.leaf-back {
	  border-bottom-left-radius: $border-radius-sm;
	  border-bottom-right-radius: $border-radius-sm;
	  border-top-style: solid;
	  border-top-width: ($foldSize * .5);
	  span {
		bottom: 0;
	  }
	}

	.leaf-back {
	  position: absolute;
	  top: $leafHeight-sm;
	  z-index: 10;
@include media-breakpoint-down(lg) {
      top: 30px;
    }
	}
}

.flipclock-title {
	//position: absolute;
}

@media screen and (max-width: $maxSizeBreakpoint) {
  .flipclock {
    //padding: (($padding * 100) / $maxSizeBreakpoint) * 1vw;
  }

  .leaf {
    //margin: 0 (($gapSize * 100) / $maxSizeBreakpoint) * 1vw;
  }

  .leaf::before {    
    font-size: $font-size-sm;
  }

  .top,
  .bottom,
  .leaf-front,
  .leaf-back {
    //height: (($leafHeight * 100) / $maxSizeBreakpoint) * 1vw;
    //width: (($leafWidth2Digits * 100) / $maxSizeBreakpoint) * 1vw;

    span {
      /*height: (($leafHeight * 100) / $maxSizeBreakpoint) * 1vw * 2;
      font-size: (($leafFontsize * 100) / $maxSizeBreakpoint) * 1vw;
      line-height: (($leafHeight * 100) / $maxSizeBreakpoint) * 1vw * 2;*/
    }
  }

  .leaf._3-digits {
    .top,
    .bottom,
    .leaf-front,
    .leaf-back {
      //width: (($leafWidth3Digits * 100) / $maxSizeBreakpoint) * 1vw;
    }
  }

  .top,
  .leaf-front {
    /*border-top-left-radius: (($leafBorderRadius * 100) / $maxSizeBreakpoint) *
      1vw;
    border-top-right-radius: (($leafBorderRadius * 100) / $maxSizeBreakpoint) *
      1vw;*/
  }

  .bottom,
  .leaf-back {
    /*border-bottom-left-radius: (($leafBorderRadius * 100) / $maxSizeBreakpoint) *
      1vw;
    border-bottom-right-radius: (($leafBorderRadius * 100) / $maxSizeBreakpoint) *
      1vw;*/
  }

  .leaf-back {
    //top: (($leafHeight * 100) / $maxSizeBreakpoint) * 1vw;
  }
}

/// Flipclock Animation
.leaf-front {
  transform-origin: bottom center;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  transition-delay: $duration;
}

.leaf-back {
  transform-origin: top center;
  transform: rotateX(90deg);
  transform-style: preserve-3d;
  transition-delay: 0s;
}

.bottom::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, $leafShadowOpacity) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: blur(10px);
}

.flip {
  .leaf-front {
    transform: rotateX(-90deg);
    transition: transform $duration ease-in, background-color $duration ease-in,
      color $duration ease-in;
    transition-delay: 0s;

    color: darken($red, 50%);
    background-color: darken($white, 50%);
  }
  .leaf-back {
    transform: rotateX(0deg);
    transition: transform $duration ease-in, background-color $duration ease-in,
      color $duration ease-in;
    transition-delay: $duration;
  }

  .bottom::before {
    transition: height $duration ease-in-out;
    transition-delay: $duration * .5;
    height: 100%;
  }
}


.ots-session-timer{
	@include media-breakpoint-up(md) {
	.leaf{
		perspective: 375px;

		
			&::before{
				content: attr(data-label);
				font-size: 1.25rem;
				top: -32px;
				left: 50%;
				position: absolute;
				//font-weight: 500;
				font-family: "Poppins Medium";
				transform: translateX(-50%);
			}
	
		
		.top, .bottom{
			height:50px;
			width: 150px;

			span {
				height: 100px;
				font-size: 5.240rem;
				line-height: 110px;
			}
		}

		.leaf-front, .leaf-back{
			height:50px;
			width: 150px;

			span {
				height: 100px;
				font-size: 5.240rem;
				line-height: 110px;
			}
		}

		.leaf-back{
			top: 50px;
		}
	}
}
}

//Ots filp clock

/* Reset */
.flip-clock-wrapper * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-clock-wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: $white; }

.flip-clock-wrapper a:hover {
  color: $white; }

.flip-clock-wrapper ul {
  list-style: none; }

.flip-clock-wrapper.clearfix:before,
.flip-clock-wrapper.clearfix:after {
  content: " ";
  display: table; }

.flip-clock-wrapper.clearfix:after {
  clear: both; }

.flip-clock-wrapper.clearfix {
  *zoom: 1; }

/* Main */
.flip-clock-wrapper {
  font-family:"Poppins-Medium", sans-serif;
  -webkit-user-select: none; }

.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 36px !important; }

.flip-clock-meridium a { color: #313333; }

.flip-clock-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
}

.flip-clock-wrapper:before,
.flip-clock-wrapper:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}
.flip-clock-wrapper:after {
    clear: both;
}

/* Skeleton */
.flip-clock-wrapper ul {
  position: relative;
  float: left;
  margin: 5px;
  width: 80px;
  height: 90px;
  font-size: 80px;
  font-weight: bold;
  line-height: 87px;
  border-radius: 12px;
  background: $white;
  @include media-breakpoint-down(sm) {
	margin: 2px;
	width: 60px;
	height: 60px;
	font-size: 36px;
  }
}

.flip-clock-wrapper ul li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 87px;
  text-decoration: none !important;
  @include media-breakpoint-down(sm) {
	line-height: 64px;
  }
}

.flip-clock-wrapper ul li:first-child {
  z-index: 2; }

.flip-clock-wrapper ul li a {
  display: block;
  height: 100%;
  -webkit-perspective: 200px;
  -moz-perspective: 200px;
  perspective: 200px;
  margin: 0 !important;
  overflow: visible !important;
  cursor: default !important; }

.flip-clock-wrapper ul li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  font-size: 80px;
  overflow: hidden; 
  outline: 1px solid transparent; 

}

.flip-clock-wrapper ul li a div .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2; }

.flip-clock-wrapper ul li a div.up {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  top: 0; }

.flip-clock-wrapper ul li a div.up:after {
  content: "";
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background-color: $ghost;
 }

.flip-clock-wrapper ul li a div.down {
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  transform-origin: 50% 0;
  bottom: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.flip-clock-wrapper ul li a div div.inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: $danger;
  //text-shadow: 0 1px 2px #000;
  text-align: center;
  background-color: $white;
  border-radius: 6px;
  font-size: 70px; 
  @include media-breakpoint-down(sm) {
	font-size: 36px;
  }
}

.flip-clock-wrapper ul li a div.up div.inn {
  top: 0; }

.flip-clock-wrapper ul li a div.down div.inn {
  bottom: 0; }

/* PLAY */
.flip-clock-wrapper ul.play li.flip-clock-before {
  z-index: 3; }

.flip-clock-wrapper .flip {   box-shadow: 0 2px 5px rgba( $light, 0.2); }

.flip-clock-wrapper ul.play li.flip-clock-active {
  -webkit-animation: asd 0.5s 0.5s linear both;
  -moz-animation: asd 0.5s 0.5s linear both;
  animation: asd 0.5s 0.5s linear both;
  z-index: 5; }

.flip-clock-divider {
  float: left;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 100px; }

.flip-clock-divider:first-child {
  width: 0; }

.flip-clock-dot {
  display: none !important;
}

.flip-clock-divider .flip-clock-label {
  position: absolute;
  top: -2em;
  right: -110px;
  color: $primary;
  //font-weight: 500;
	font-family: "Poppins Medium";
	font-size: 1rem;
  text-shadow: none; 
  @include media-breakpoint-down(sm) {
	font-size: 0.825rem;
	right: -60px;
  }
}

.flip-clock-divider.minutes .flip-clock-label {
  right: -116px; 
  @include media-breakpoint-down(sm) {
	right: -70px;
  }
}

.flip-clock-divider.seconds .flip-clock-label {
  right: -116px; 
  @include media-breakpoint-down(sm) {
	right: -70px;
  }
}

.flip-clock-dot.top {
  top: 30px; }

.flip-clock-dot.bottom {
  bottom: 30px; }

@-webkit-keyframes asd {
  0% {
    z-index: 2; }

  20% {
    z-index: 4; }

  100% {
    z-index: 4; } }

@-moz-keyframes asd {
  0% {
    z-index: 2; }

  20% {
    z-index: 4; }

  100% {
    z-index: 4; } }

@-o-keyframes asd {
  0% {
    z-index: 2; }

  20% {
    z-index: 4; }

  100% {
    z-index: 4; } }

@keyframes asd {
  0% {
    z-index: 2; }

  20% {
    z-index: 4; }

  100% {
    z-index: 4; } }

.flip-clock-wrapper ul.play li.flip-clock-active .down {
  z-index: 2;
  -webkit-animation: turn 0.5s 0.5s linear both;
  -moz-animation: turn 0.5s 0.5s linear both;
  animation: turn 0.5s 0.5s linear both; }

@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg); }

  100% {
    -webkit-transform: rotateX(0deg); } }

@-moz-keyframes turn {
  0% {
    -moz-transform: rotateX(90deg); }

  100% {
    -moz-transform: rotateX(0deg); } }

@-o-keyframes turn {
  0% {
    -o-transform: rotateX(90deg); }

  100% {
    -o-transform: rotateX(0deg); } }

@keyframes turn {
  0% {
    transform: rotateX(90deg); }

  100% {
    transform: rotateX(0deg); } }

.flip-clock-wrapper ul.play li.flip-clock-before .up {
  z-index: 2;
  -webkit-animation: turn2 0.5s linear both;
  -moz-animation: turn2 0.5s linear both;
  animation: turn2 0.5s linear both; }

@-webkit-keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg); }

  100% {
    -webkit-transform: rotateX(-90deg); } }

@-moz-keyframes turn2 {
  0% {
    -moz-transform: rotateX(0deg); }

  100% {
    -moz-transform: rotateX(-90deg); } }

@-o-keyframes turn2 {
  0% {
    -o-transform: rotateX(0deg); }

  100% {
    -o-transform: rotateX(-90deg); } }

@keyframes turn2 {
  0% {
    transform: rotateX(0deg); }

  100% {
    transform: rotateX(-90deg); } }

.flip-clock-wrapper ul li.flip-clock-active {
  z-index: 3; }

@-webkit-keyframes show {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-moz-keyframes show {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-o-keyframes show {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@keyframes show {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }

@-webkit-keyframes hide {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@-moz-keyframes hide {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@-o-keyframes hide {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }

@keyframes hide {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }