@media (max-width: 1023px) {
	.container {
		max-width: 100%;
	}
}
@include media-breakpoint-up(xs) {
	  .main-wrapper {
		//padding-top: 70px;
		//padding-bottom: 20px;
		 //height: 100%; 
	  }	
	  .tip-header {
		margin-right: 0;
		width: 100%;
		text-align: center;
	}
}
@include media-breakpoint-up(lg) {
	  .main-wrapper {
		padding-top: 217px;
		position: relative;  
		//padding-bottom: 2rem;
		min-height: 100vh;
	  }
	  .tip-header {
		margin-right: auto;
		width: 60%;
		text-align: left;
	}
	#pca_experience {
		&.User {
			.main-wrapper {
				padding-top: 148px;
			}
			.scrollFixed {
				top: 148px;
			}
		}
	}
}
@include media-breakpoint-up(xl) {
	.xl-3 {
		flex: 0 0 20%;
		max-width: 20%;
	}	
	.xl-4 {
		flex: 0 0 30%;
		max-width: 30%;
	}
	.xl-5 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.xl-9 {
		flex: 0 0 80%;
		max-width: 80%;
	}
}
/*  max-width: 767px */
@include media-breakpoint-down(sm) {
.mobile-user-card {
	padding-bottom: $spacer;
}	 

    body#dashboard.User .main-wrapper .container.container-mobile .row {
            margin-right: 0;
            margin-left: 0;
        
	}
    body#dashboard.User .main-wrapper .container.container-mobile .row > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        
	}
	
	.mobile-thumb {
		width: 100px;
		height: 100px;
		border-radius: 50%;
		font-size: $h2-font-size;
		color: $white;
	}
    
    .points-gain {
        padding: 0;
    }
    .points-gain-num, 
    .points-gain-text {
        font-size: $font-size-base;
    }
    
	h4 {
		font-size: $font-size-lg;
		//color: $gray-300;
		//font-weight: $font-weight-normal;
	}	
  .container-mobile {
   	padding: 6rem 15px 3rem 15px;
  }
 
	.jplist-pagination {
		justify-content: center !important;
		align-items: center;
	}
	.course-table-according {
		h4 {
			text-align:  left;
            //font-size: $font-size-md;
            @include text-truncate;
            color: $gray-500 !important;
            max-width: 300px;
		}
        .accordion tbody {
            display: table;
            width: 100%;
        }
	}
	.justify-content-end {
		flex-direction: column;
	}

    .table-mobile {
        td {
           //border-left: 1px solid rgba(0,0,0,0); 
           //border-right: 1px solid rgba(0,0,0,0);
		   padding: 0.9rem; 
		   &:first-child {
				//border-top: none;
		   }
        }
    }
	
	  .sm-text-center {
		text-align: center;
	  }
	.course-right-section {
		h4 {
			margin-bottom: $spacer !important;
		}
		.btn {
			margin: .25rem 0  !important;
		}
	}
	.dataTables_info {
		text-align: center;
		display: block;
		position: absolute;
	}
	.table-module {
		.course-points-gain {
			padding: 0 0 $spacer !important;
		}
		.course-time {
			color: $gray-300;
			margin-bottom: $spacer * .75;
		}		
	}
	/*.mobile-col-p-0 {
		padding: 0;
	}*/
}

@include media-breakpoint-down(lg) {	
    .md-text-center {
		text-align: center;
	  }
	  
	  .table-module {
		  .card-row {
			min-height: inherit !important;
			max-height: inherit !important;
		}
		.card-body-left-bg {
			background-color: transparent !important;
		}
		.card-body-left-course-icon {
			padding-top:  $spacer * 2;
			}
			.course-points-gain {
				padding: 0 0 ($spacer * 2);
				border-left: transparent !important;
			}
			.card-body-course-description {
				margin: 0 !important;
		}
	}
	#discussions {
		.dataTables_wrapper {
			.float-end {
				flex-grow: 1;
				max-width: 100%;
				flex-basis: 0;
				position: relative;
				width: 100%;
				min-height: 1px; // Prevent columns from collapsing when empty
				padding-right: 7.5px;
				padding-left: 7.5px;
				.dataTables_filter {
					margin-left: 0;					
				}
				label,
				.form-control {
						width: 100%;
				}
			}
		}
	}
	.discussion-attachment {
		display: block;
		.attachment {
			display: block;
			width: 100%;
			margin-bottom: $spacer;
		}
		.attachment-list {
			.list-inline-item {
				
			}
		}
	}
	.voted-user {
		display: inline-block;
		margin: $spacer !important; 
	}
	.tag {
		margin-bottom: $spacer ;
	}
	.publish-date {
		display: block;
	}
	nav.jplist-panel {
		display: block !important;
	}
	
	.jplist-pagingprev,
	.jplist-pagingnext {
		display: none;
	}
	.tip-content {
		top: 26px !important;
		left: -30vw !important;
	}
}

@include media-breakpoint-down(lg) {
	.container {
		padding-right: $grid-gutter-width;
		padding-left: $grid-gutter-width;
	}
	.twitterActive {
			display: none;
	}
	.text-md-small {
		font-size: 86%;
	}
}


.page-h {
	width: 50%;
	float: left;
	flex-grow: 1;
}

/*
Disable landscape orientation for mobile users
*/
/*
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
	html {
		transform: rotate(-90deg);
		transform-origin: left top;
		width: 100vh;
		overflow-x: inherit;
		position: absolute;
		top: 100%;
		left: 0;
	}
}  */