// Employee Directory
.jplist-pagination {
    .jplist-pagingprev {
        margin-left: 0;
        border-top-left-radius: ($border-radius);
        border-bottom-left-radius: ($border-radius);
    }
    .jplist-pagingnext {
        border-bottom-right-radius: ($border-radius);
        border-bottom-left-radius: ($border-radius);
    }
    &.active button {
        z-index: 2;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
    }
    .jplist-hidden {
        button {
            color: $pagination-disabled-color;
            pointer-events: none;
            //cursor: $cursor-disabled; // While `pointer-events: none` removes the cursor in modern browsers, we provide a disabled cursor as a fallback.
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border-color;
            //display: none;
        }
    }
    .jplist-pagesbox {
        button {
            margin-left: -5px;
        }
    }
    button {
        position: relative;
        display: inline-block;
        padding: $pagination-padding-y $pagination-padding-x;
        margin-left: -1px;
        line-height: $pagination-line-height;
        color: $pagination-color;
        background-color: $pagination-bg;
        border: $pagination-border-width solid $pagination-border-color;
        &:focus {
            color: $pagination-hover-color;
            text-decoration: none;
            background-color: $pagination-hover-bg;
            border-color: $pagination-hover-border-color;
        }
    }
}


.pagination {
    justify-content: end;
    .page-item {
        position: relative;
        &::after {
            content: "";
            width: 1px;
            height: 19px;
            background-color: $pagination-border-color;
            position: absolute;
            right: 0;
            top: 12px;
        }
        &:not(:first-child) .page-link {
            margin-left: 0;
        }

        &.active {
            .page-link {
                //font-weight: $font-weight-semibold;
                font-family: 'Poppins SemiBold';
                border-right: $pagination-border-width solid transparent;
                border-left: $pagination-border-width solid transparent;
                margin-left: -1px;
            }
        }
        &:first-child,
        &:last-child {
            .page-link {
                padding-top: .643rem;
                padding-bottom: .643rem;
                border-left: $pagination-border-width solid $pagination-border-color;
                border-right: $pagination-border-width solid $pagination-border-color;
            }
        }
        &:last-child {
            .page-link {
                margin-left: -1px;
            }
        }
        &.disabled {
            .page-link {
                border-top: $pagination-border-width solid $pagination-border-color;
                border-bottom: $pagination-border-width solid $pagination-border-color;
            }
        }
    }
    .page-link {
        //font-weight: $font-weight-medium;
        font-family: 'Poppins Medium';
        border-top: $pagination-border-width solid $pagination-border-color;
        border-right: $pagination-border-width solid transparent;
        border-bottom: $pagination-border-width solid $pagination-border-color;
        border-left: $pagination-border-width solid transparent;
        min-width: 44px;
        text-align: center;
    }    
}

//
/*
.dataTables_paginate {
    justify-content: center;
    @include media-breakpoint-up(md) {
        justify-content: flex-end !important;
    }
    .pagination {            
        display: flex;
        @include list-unstyled();
        @include border-radius();
        justify-content: flex-end;
        .page-item {
            list-style: none;
            .page-link {                
                position: relative;
                display: block;
                padding: $pagination-padding-y $pagination-padding-x;
                margin-left: -$pagination-border-width;
                line-height: $pagination-line-height;
                color: $pagination-color;
                background-color: $pagination-bg;
                border-color: $pagination-border-color;
                border-style: solid;
                border-width: $pagination-border-width;
                &:hover {
                    color: $pagination-hover-color;
                    text-decoration: none;
                    background-color: $pagination-hover-bg;
                    border-color: $pagination-hover-border-color;
                }
                &:focus {
                    z-index: 2;
                    outline: 0;
                    box-shadow: $pagination-focus-box-shadow;
                }
                // Opinionated: add "hand" cursor to non-disabled .page-link elements
                &:not(:disabled):not(.disabled) {
                    cursor: pointer;
                }
            }
        }
    }
    .active a {
        z-index: 1;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
    }
    .disabled {
        a {
            color: $pagination-disabled-color;
            pointer-events: none;
            background-color: $pagination-disabled-bg;
            border-color: $pagination-disabled-border-color;
        }
    }
    @include media-breakpoint-down(sm) {
        justify-content: center !important;
        width: 100%;
        margin-top: $spacer * 4;
    }
}
*/
/*
.pagination {
    ul {
        .prev,
        .next {
            a {
                display: flex;
                align-items: center;
            }
        }
    }
}
*/