.event-tab-section {
    min-width: 302px;
    .nav-item {
        min-width: 145px;
        .nav-link {
            text-align: center;
        }
    }
}

#events.User {
    .form-control-search {
        width: 385px;
    }
    @include media-breakpoint-down(lg) {
        .nav-mobile-search {
            width: 24px;
        }
    }
}

// fullcalendar
.fc th {
    text-align: left !important;
    //font-weight: $font-weight-medium !important;
    font-family: 'Poppins Medium' !important;
    font-size: $font-size-base !important;
    @include media-breakpoint-down(lg) {
        text-align: center !important;
        padding: 5px 0 !important;
    }
}

.fc-more-popover {
    .fc-widget-header {
        padding: 5px !important;
    }
    .fc-event-container {
        padding: 0 !important;
    }
    .fc-day-grid-event {
        margin: 4px 2px 0;
        padding: 4px 3px;
    }
}

.fc-day-header {
    @include media-breakpoint-up(md) {
        padding: 12px 1rem !important;
    }
    line-height: 1 !important;
    color: $gray-500;
}

.fc-basic-view {
    .fc-body {
        .fc-row {
            min-height: 56px;
        }
    }
    td.fc-day-number,
    td.fc-week-number span {
        //padding-top: 4px;
        padding-bottom: 0px !important;
        margin-bottom: 1rem;
        /*@include media-breakpoint-up(md) {
            padding-top: 15px !important;
			padding-right: 15px;
			padding-bottom: 5px !important;
			padding-left: 15px;
        }*/
        @include media-breakpoint-down(lg) {
            margin-bottom: 0.5rem;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 0.3rem;
        }
    }
}

.fc-toolbar {
    .fc-button {
        background-image: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        border-color: transparent !important;
        opacity: 1;
        padding: .5rem 1.25rem;
        line-height: 1;
        height: auto;
    }
    .fc-prev-button,
    .fc-next-button {
        background-color: rgba($ghost, .2);
        color: $gray-400;
        padding: .782rem;
    }
    .fc-prev-button {
        &::after {
            content: "";
            position: absolute;
            height: 20px;
            width: 1px;
            background-color: $white;
            right: 0;
            top: 9px;
        }
    }
    .fc-right {
        display: flex;
        align-items: center;
    }
    .fc-button-group>* {
        margin: 0 !important;
    }
    .fc-corner-left {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .fc-corner-right {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .fc-today-button {
        background-color: rgba($blue, .4);
        color: $blue;
        border-radius: $border-radius-sm !important;
    }
    @include media-breakpoint-down(lg) {
        /*.fc-left,
		.fc-right {
			display: none;
		}*/
        display: flex;
        align-items: center;
        width: 100%;
        h2 {
            font-size: $font-size-xl;
        }
        .fc-right {
            margin-left: auto;
        }
        .fc-button-group {
            //display: none;
        }
    }
}

.fc td {
    //padding-top: 4px !important;
}

.fc-more {}

.fc-more-cell {
    @include media-breakpoint-down(lg) {
        line-height: 1;
    }
}

.fc-day-number {
    //font-weight: $font-weight-medium !important;
    font-family: 'Poppins Medium' !important;
    @include media-breakpoint-up(md) {
        font-size: $h4-font-size !important;
    }
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover,
.fc-unthemed .fc-row,
.fc-unthemed tbody,
.fc-unthemed td,
.fc-unthemed th,
.fc-unthemed thead {
    border-color: $border-color !important;
}

.fc-unthemed .fc-divider,
.fc-unthemed .fc-popover .fc-header {
    background: $white !important;
}

.fc-view {
    background-color: $white !important;
}

.fc-past {
    @extend .fc-day-number;
    color: rgba($gray-600, .3) !important;
}

.fc-unthemed {
    .fc-today {
        background-color: transparent !important;
    }
}

.fc-content-skeleton {
    .fc-past,
    .fc-future {
        padding-top: 12px !important;
        padding-right: 14px !important;
    }
    .active-date {
        color: $white !important;
        //background-color: $blue !important;
        border-radius: 100% !important;
        line-height: 1;
        width: 22px;
        max-width: 22px;
        max-height: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        margin-top: 6px !important;
        margin-right: 6px !important;
        @include media-breakpoint-up(md) {
            width: 30px;
            max-width: 30px;
            max-height: 30px;
            height: 30px;
        }
    }
    /*.fc-today {
		position: relative;
		color: $white !important;
		&::after {			
			background-color: $blue !important;
			border-radius: 100% !important;			
			line-height: 1;
			padding: 0 !important;
			content: "";
			position: absolute;			
			z-index: -1;
			width: 22px;
			max-width: 22px;
			max-height: 22px;
			height: 22px;
			right: 0px;
    		top: 3px;
			@include media-breakpoint-up(md) {
				width: 30px;
				max-width: 30px;
				max-height: 30px;
				height: 30px;
				right: 11px;
    			top: 11px;
			}
		}
	}*/
    .fc-day-grid-event {
        @include media-breakpoint-up(md) {
            margin: 0px 15px 8px !important;
            padding: 8px 8px !important;
        }
    }
}

.fc-event {
    border: none !important;
    //background-color: rgba($blue, .4) !important;
    border-radius: $border-radius-sm !important;
}

.fc-event-today {
    background-color: rgba($blue, .4) !important;
    .fc-time {
        color: $blue !important;
    }
}

.fc-event-past {
    background-color: rgba($green, .4) !important;
    .fc-time {
        color: $green !important;
    }
}

.fc-center {
    h2 {
        font-size: $font-size-xl;
        @include media-breakpoint-up(md) {
            margin-top: $spacer !important;
        }
    }
}

.fc-day-grid-event,
.fc-time-grid-event {
    .fc-time,
    .fc-title {
        //font-weight: $font-weight-medium !important;
        font-family: 'Poppins Medium' !important;
        font-size: $font-size-sm !important;
    }
}

.fc-month-button,
.fc-agendaWeek-button,
.fc-agendaDay-button {
    color: $primary !important;
    border-color: $primary !important;
}

.fc-state-active {
    color: $white !important;
    border-color: $primary !important;
    background-color: $primary !important;
}

.event-icons {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc-scroller {
    @include media-breakpoint-down(lg) {
        height: 100% !important;
    }
}


/*
popup list event
*/

.list-event {
    list-style: none;
    margin: 2rem 0 0;
    padding: 0;
    .list-event-item {
        border-top: 1px solid $border-color;
        padding: 1rem 0;
        display: flex;
        align-items: center;
        //font-weight: $font-weight-medium !important;
        font-family: 'Poppins Medium' !important;
        font-size: $font-size-lg;
        &:last-child {
            padding-bottom: 0;
        }
    }
}

#Calender_month,
#Calender_week {
    .fc-toolbar {
        .fc-left {
            h2 {
                font-size: $h3-font-size !important;
            }
        }
    }
}