.admin-swiper {
    .active {
        border: 1px solid $secondary;
    }
}

.admin-rank {    
    .rank-item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 195px;
        cursor: pointer;
        .img-admin-rank {
            height: 195px;
        }
    }
    .swiper-slide-selected {
        .rank-item {
           background-color: $gray-100;
           position: relative;
           &::after {
            content: "";
            background-image: url(../../img/swiper/selected.svg);
            background-size: cover;
            width: 30px;
            height: 30px;
            z-index: 1;
            position: absolute;
            top: 10px;
            right: 10px;
           }
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    width: 44px !important;
    height: 44px !important;
    border-radius: $border-radius;
    background-color: $white;
    padding-top: 4px;
    &::after {
        font-size: 16px !important;;
        color: $gray-400 !important;;
    }
}

.swiper-button-prev {
    left: -28px !important;
    padding-right: 4px;
    padding-top: 4px;
}

.swiper-button-next {
    left: 28px !important;
    padding-left: 4px;
    padding-top: 4px;    
}