// Kill the scroll on the body
.modal-open {
    overflow: hidden;
}

// Container that the modal scrolls within
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
    position: relative;
    width: auto;
    margin: $modal-dialog-margin;
}

// Actual modal
.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: $modal-content-bg;
    background-clip: padding-box;
    //border: $modal-content-border-width solid $modal-content-border-color;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    //	top: 10% !important;
    @include border-radius($border-radius);
    //@include box-shadow($modal-content-box-shadow-xs);
    // Remove focus outline from opened modal
    outline: 0;
    margin: 0 auto !important;
    bottom: inherit;
    right: 0;
    height: initial;
    overflow-y: hidden;
    // top: 25vh !important;
    //top: calc(100% - (1.75rem * 25)) !important;
    @include media-breakpoint-down(sm) {
        width: 93%;
        //margin: 30px 20px !important;
        //top: calc(100% - (1.75rem * 20)) !important;
    }
    @media only screen and (orientation: landscape) {
        margin: 0 !important;
    }
    @include media-breakpoint-down(lg) {
        @include border-radius($border-radius-xl);
        //width: inherit;
    }
}

// Modal background
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zindex-modal-backdrop;
    background-color: $modal-backdrop-bg;
    opacity: $modal-backdrop-opacity;
    // Fade for backdrop
    &.fade {
        opacity: 0;
    }
    &.show {
        opacity: $modal-backdrop-opacity;
    }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    //display: flex;
    align-items: center; // vertically center it
    //justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
    //padding: $modal-header-padding;
    //border-bottom: $modal-header-border-width solid $modal-header-border-color;
    flex-direction: row-reverse !important;
    //background-color: $porcelain;
    //background-color: darken(theme-color("primary"), 15%);
    @include border-top-radius($border-radius);
    h3 {
        width: 90%;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
    }
    h3,
    .close {
        color: $gray-600 !important;
        font-size: $font-size-base;
        margin-bottom: 0;
        //font-weight: $font-weight-semibold;
        font-family: 'Poppins SemiBold';
    }
    .close {
        overflow: hidden;
        //color: $black !important;
        padding: 0 !important;
        font-size: 0;
        margin-top: -.05rem !important;
        margin-right: -.05rem !important;
        z-index: $zindex-modal;
        &:before {
            background-image: url(../../img/x.svg);
            background-size: 18px 26px;
            display: inline-block;
            width: 17px;
            height: 26px;
            content: "";
            stroke: #8C9BAC;
        }
    }
    .loader {
        display: none;
    }
}

// Title text within header
.modal-title {
    margin-bottom: 0;
    line-height: $modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when should there be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: $modal-inner-padding;
    //max-height: 400px;
    overflow: initial;
    width: 100%;
    background-color: $white;
    @include border-radius($border-radius);
    @include media-breakpoint-down(lg) {
        //border-top-left-radius: $border-radius-xl;
        // border-top-right-radius: $border-radius-xl;
        @include border-radius($border-radius-xl);
    }
    @media only screen and (max-device-width: 768px) and (orientation: landscape) {
        max-height: 260px !important;
        overflow: auto !important;
    }
    @media only screen and (max-device-width: 668px) and (orientation: landscape) {
        max-height: 200px !important;
    }
    @media only screen and (max-device-width: 569px) and (orientation: landscape) {
        max-height: 180px !important;
    }
}

.modal-description-body-scroll-sm {
    max-height: 18vh;
    overflow: auto;
}

.modal-description-body-scroll {
    max-height: 30vh;
    overflow: auto;
}

.modal-form-body-scroll {
    max-height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
    @include media-breakpoint-up(lg) {
        max-height: 70vh;
    }
}

// Footer (for actions)
.modal-footer {
    display: flex;
    align-items: center; // vertically center
    justify-content: center; // Right align buttons with flex property because text-align doesn't work on flex items
    padding: $modal-inner-padding;
    border-top: $modal-footer-border-width solid transparent;
    background-color: $white;
    // Easily place margin between footer elements
    > :not(:first-child) {
        margin-left: .25rem;
    }
    > :not(:last-child) {
        margin-right: .25rem;
    }
    @include media-breakpoint-down(lg) {
        border-bottom-left-radius: $border-radius-xl;
        border-bottom-right-radius: $border-radius-xl;
    }
}

// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

.modal-dialog-scrollable {
    .modal-content {
        @include media-breakpoint-down(lg) {
            max-height: subtract(100vh, $modal-dialog-margin * 18);
        }
    }
}

// Scale up the modal
@include media-breakpoint-up(lg) {
    // Automatically set modal's width for larger viewports
    /*.modal {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }*/
    .modal-content {
        @include box-shadow($box-shadow);
    }
    .modal-sm {
        max-width: $modal-sm;
    }
}

@include media-breakpoint-up(lg) {
    // Automatically set modal's width for larger viewports
    .modal-sm {
        max-width: $modal-sm;
    }
}


@include media-breakpoint-up(sm) {
    // Automatically set modal's width for larger viewports from 576px
    .modal {
        max-width: $modal-md;
        margin: $modal-dialog-margin-y-sm-up auto;
    }

}

//delete model width between 576 - 992px
@include media-breakpoint-between(sm, lg) { 
    .modal {
        width: 80%;
    }
 }

// Answers popups
.card-score {
    flex: 1 1 auto;
    padding: $card-spacer-x;
    text-align: left;
    color: $white;
    .bg-success {
        background-color: #3EB317;
    }
    .bg-warning {
        background-color: #FFA800;
    }
    .bg-danger {
        background-color: #FF1E00;
    }
    .points-gain {
        display: inline-block !important;
        /*.points-gain-num {
			display: inline-block !important;
			font-size: $h2-font-size;
			font-weight: 700;
			line-height: normal;
		}
		 .points-gain-text {
			display: inline-block !important;
			font-size: $font-size-sm;
			padding: 0 3px;
		}*/
    }
}

.card-score-footer {
    form {
        display: inline-block;
    }
}

.modal-lg {
    max-width: $modal-lg;
}

.modal-xl {
    max-width: $modal-xl;
    width: 90%;
    @include media-breakpoint-up(xxxl) {
        width: 80%;
    }
    .modal-dialog {
        max-width: 100%;
    }
}

// modal lg size

/*
#admin_users, #admin_courses, #admin_modules, #admin_quotes, #admin_whats_new, #admin_ranks, #admin_milestones, #admin_activities, #admin_certificates, #admin_questions, #add_activity, #admin_ideabox_announcements, #admin_ideabox_winners,  #ideabox_discussions.User {
	.modal {		
		@include media-breakpoint-up(lg) {
		    max-width: 770px;
		}
		@include media-breakpoint-up(xl) {
		    max-width: 1140px;
		}
	}
}
*/

#emp_directory.User {
    @media only screen and (max-device-width: 768px) and (orientation: landscape) {
        .modal-body {
            max-height: 220px !important;
        }
    }
}

// Modal Full With in Mobile

/*#admin_users,
#admin_media,
#admin_groups,
#admin_events,
#admin_library_groups,
#admin_reminders,
#admin_quotes,
#admin_whats_new,
#admin_ranks,
#discussions.User,
#ideabox_discussions.User,
#ideas.User,
#badges.User {*/

@include media-breakpoint-down(lg) {
    .modal-backdrop {
        //background-color: $athens-gray;
        opacity: 1;
    }
    .modal {
        //width: 100%;
        //border-radius: 0;
        //border: transparent;
        //box-shadow: none;
        //top: 0 !important;
        //height: 100%;
        //left: 0;
        //transform: none;
    }
    .modal-header {
        display: none;
    }
    .modal-body {
        max-height: inherit;
        //height: fit-content;
        overflow-y: scroll;
        border-radius: 0;
        margin-bottom: auto;
        flex-grow: 1;
    }
    .modal-footer {
        flex-direction: column-reverse;
        display: flex;
        //background-color: $athens-gray;
        padding-top: 2rem;
        border-radius: 0;
        //max-height: 150px;
        //min-height: 150px;
        //height: 150px;
        .btn {
            margin: 0;
            width: 90%;
            font-size: $font-size-lg;
            padding: .65rem 1.75rem;
            //&:last-child {
            margin-bottom: $spacer;
            //}
        }
    }
    // confimation modal mobile
    .modal-confirm {
        .modal-dialog {
            margin: 0;
            height: 100%;
            display: flex;
        }
        .modal-content {
            box-shadow: none;
            border: transparent;
        }
        .modal-footer {
            @include media-breakpoint-down(lg) {
                border-color: transparent !important;
            }
        }
    }
}

@media only screen and (max-device-width: 768px) and (orientation: landscape) {
    .modal-body {
        max-height: inherit;
    }
}

//}
.modal-center {
    position: fixed;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-top-fixed {
    position: fixed;
    top: 120px !important;
    left: 50%;
    transform: translate(-50%, 0);
    @include media-breakpoint-down(lg) {
        top: 42px !important;
    }
}

.modal-boostrap-replace {
    .modal-body {
        border-radius: $border-radius;
    }
    .tab-content {
        overflow: auto;
        max-height: 333px;
        .tab-pane {
            max-height: 333px;
        }
    }
}

.modal-top {
    top: 30.5% !important;
}

// user details popup close button
.btn-modal-header {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, -50%);
    .close {
        width: 50px;
        height: 51px;
        opacity: 1 !important;
        background-color: $white !important;
        &:before {
            content: none;
        }
        .feather {
            width: 24px !important;
            height: 24px !important;
        }
    }
}

#quizup_user_list.User,
#ideabox.User {
    .modal-footer {
        display: none;
    }
}

// Crop image
#uploadimageModal {
    p {
        display: none;
    }
}

.croppie-container {
    @include media-breakpoint-down(lg) {
        height: 70vh !important;
    }
    .cr-slider {
        width: 415px !important;
    }
    .cr-boundary {
        border-radius: $border-radius !important;
        @include media-breakpoint-down(lg) {
            width: 360px !important;
            height: 360px !important;
        }
    }
    .cr-image {
        border-radius: $border-radius !important;
        overflow: hidden !important;
    }
    .cr-slider-wrap {
        margin: 38px auto 1rem !important;
        position: relative;
        width: 90% !important;
        &:before {
            content: "-";
        }
        &:after {
            content: "+";
            right: 2px;
        }
        &:before,
        &:after {
            position: absolute;
            top: -30px;
            font-size: $h3-font-size;
            color: $gray-400;
        }
    }
    .cr-viewport {
        border: none !important;
        border-radius: $border-radius !important;
        box-shadow: 0 0 2000px 2000px rgba($primary, 0.5) !important;
    }
}

.cr-slider::-webkit-slider-runnable-track {
    height: 1px !important;
    background: $gray-400 !important;
}

.cr-slider::-moz-range-track {
    height: 1px !important;
    background: $gray-400 !important;
}

.cr-slider::-ms-fill-lower,
.cr-slider::-ms-fill-upper,
.cr-slider:focus::-ms-fill-lower,
.cr-slider:focus::-ms-fill-upper {
    background: $gray-400 !important;
}

.cr-slider::-webkit-slider-thumb {
    height: 24px !important;
    width: 24px !important;
    background: $primary !important;
    margin-top: -12px !important;
}

.cr-slider::-moz-range-thumb {
    height: 24px !important;
    width: 24px !important;
    background: $primary !important;
    margin-top: -12px !important;
}

.cr-slider::-ms-thumb {
    height: 24px !important;
    width: 24px !important;
    background: $primary !important;
    margin-top: -12px !important;
}

// modal confimation
.modal-confirm {
    /*max-width: $modal-md !important;
	background: none;
    box-shadow: none;
    border: none;*/
    padding: 0 !important;
    .modal-dialog {
        margin: 0  !important;
    }
    .modal-header {
        flex-direction: row !important;
    }
    .modal-footer {
        display: flex !important;
        .btn {
            margin-top: 0;
            margin-bottom: 0;
        }
        .btn-secondary {
            color: $white;
        }
    }
}

@include media-breakpoint-down(lg) {
    .modal[style*='display: block'] {
        display: flex !important;
    }
}


//dialog 2 modal change to bootstrap modal

.modal-dialog{
    &.bs{
        margin: 0;

        .modal-header{
            display: flex;
            flex-direction: row !important;

            .btn-close{
                width: 35px;
                height: 35px;
            }
        }

        .bs-title{
            display: none;
        }

    }
}

#sharePostModal{
    .modal-dialog.bs{
        max-width: 100%;
        .btn-close{
            margin: 0;
        }
    }
}
//
#dashboard {
    &.User {
        .dashboard-certificate-popup {
            .modal-header {
                border-bottom: $border-width solid $border-color;
                padding: .5rem 1rem;
                .close {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}

// qoute modal
.modal-quote-img {
    @include media-breakpoint-down(lg) {
        max-width: inherit;
        width: 100%;
        height: 100vh;
        border-radius: 0;
    }
    @include media-breakpoint-up(lg) {
        max-width: 80%;
    }
    @include media-breakpoint-up(xxxl) {
        max-width: 1400px;
    }
}

.bg-img-popup-right {
    //background-image: url(""); image url added  to header file
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100%;
    min-height: 30vh;
    @include media-breakpoint-up(lg) {
        min-height: 80vh;
    }
}

// user list
.keypad-on {
    @include media-breakpoint-down(lg) {
        max-height: 182px;
    }
}