div#CoursesComplete,
div#CoursesPass,
div#CoursesAverageTime {
	width: 100%;
	min-height: 120px;
	@media (max-width: 1440px) {
		//height: 128px;
	}

}

// admin chats
div#coursesCompleted {
    width: 100%;
    height: 100px;
}

.highcharts-xaxis-labels {
	//font-weight: $font-weight-normal !important;
    font-family: 'Poppins Regular';
	color: $gray-500;
}

.highcharts-a11y-proxy-container {
	li {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 150px !important;
		max-width: 150px;
	}
}