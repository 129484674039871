// trending discussions
.list-trending-discussions {
	padding: 0;
	margin: 0;
}

.list-trending-discussions-item {
	@extend .list-activity-group-item;
	.icon-message {
		width: 40px;
		height: 40px;
		  background-color: $gray-200;
		  border-radius: 100%;
		  display: flex;
		align-items: center;
		justify-content: center;
	}
	.feather {
		  stroke: $gray-400;
		  width: 20px;
		  height: 20px;
	  }
	  .feather.chevron-right {
		  stroke: $gray-400;
		  width: 12px;
		  height: 12px;
	  }
	  .trending-discussions-details {
		  width: 75%;
		  @include media-breakpoint-down(lg) {
			  width: 60%;
		  }
	  }
}

// common
.list-widget {
	padding: 0;
	margin: 0;
}

.list-widget-item {
	@extend .list-activity-group-item;
	.list-widget-icon {
		width: 40px;
		height: 40px;
		  background-color: $gray-200;
		  border-radius: 100%;
		  display: flex;
		align-items: center;
		justify-content: center;
	}
	.feather {
		  stroke: $gray-400;
		  width: 20px;
		  height: 20px;
	  }
	  .feather.chevron-right {
		  stroke: $gray-400;
		  width: 12px;
		  height: 12px;
	  }
	  .list-widget-details {
		  width: calc(100% - 180px);
		  /*@include media-breakpoint-down(lg) {
			  width: 60%;
		  }*/
	  }
}
.list-doted-order {
	list-style: none;
	margin: 0;
	.list-doted-order-items {
		position: relative;
		padding: 0.786rem 0; // 11px 0
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: $border-radius-pill;
			position: absolute;
			left: -18px;
    		top: 18px;
		}
	}
	.list-doted-order-items:nth-child(1) {
		&::before {
			background-color: $malibu;
		}
	}
	.list-doted-order-items:nth-child(2) {
		&::before {
			background-color: $green;
		}
	}
	.list-doted-order-items:nth-child(3) {
		&::before {
			background-color: $portage;
		}
	}
}