/*.vertical .carousel-item-next.carousel-item-left,
.vertical .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.vertical .carousel-item-next,
.vertical .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100% 0);
}

.vertical .carousel-item-prev,
.vertical .active.carousel-item-left {
-webkit-transform: translate3d(0,-100%, 0);
        transform: translate3d(0,-100%, 0);
}*/

.carousel-vertical {
	display: flex;
	width: 100%;
	.carousel-control {
		padding: .78rem;
		.feather {
			stroke: $gray-400;
			width:20px;
			height: 20px;
		}
	}
}


.carousel-item,
.carousel-item a {
    height:100%;	
    width:100%;
	.active {
		top: 0;
	}
}

.carousel-item {
	padding: 13px 0 9px;
}

.carousel-control-divider {
	width: 1px;
	height: 24px;
	background-color: $white;
}

.carousel-control-divider-gray {
	width: 1px;
	height: 24px;
	background-color: $ghost;
}

.carousel-control-section {
	background-color: $gray-100;
	display: flex;
	align-items: center;
	border-top-right-radius: $border-radius;
	border-bottom-right-radius: $border-radius;
}


.carousel-vertical .carousel-item-next.carousel-item-left,
.carousel-vertical .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.carousel-vertical .carousel-item-next,
.carousel-vertical .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100% 0);
}

.carousel-vertical .carousel-item-prev,
.carousel-vertical .active.carousel-item-left {
-webkit-transform: translate3d(0,-100%, 0);
        transform: translate3d(0,-100%, 0);
}
 

/*------------------------------ vertical bootstrap slider----------------------------*/
/*
.carousel-vertical {	
	.carousel-inner> .carousel-item.carousel-item-next ,
	.carousel-inner > .carousel-item.active.carousel-item-right{ 
		transform: translate3d(0, 100%, 0);
		top: 0;    
	}
	.carousel-inner > .carousel-item.carousel-item-prev ,
	.carousel-inner > .carousel-item.active.carousel-item-left{ 
		transform: translate3d(0,-100%, 0); 
		top: 0;
		
	}
	
	/.carousel-inner > .carousel-item.next.carousel-item-left ,
	.carousel-inner > .carousel-item.carousel-item-prev.carousel-item-right ,
	.carousel-inner > .carousel-item.active{
		transform:translate3d(0,0,0); 
		top:0;
		
	}
}*/
/*------------------------------- owl carousel ------------------------------*/

.owl-carousel {
	.owl-item {
		img {
			//width: fit-content !important;
			margin: 0 auto;
		}
		.item {
			text-decoration: none;
		}
	}
	.owl-stage {
		padding-left: 0px !important;
	}
}

.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top:  0px !important;
}

.owl-theme .owl-dots .owl-dot span {
	margin: 5px 3px !important;
	background: rgba($primary,.5) !important;
}

.owl-theme .owl-dots .owl-dot.active span {
	background: $primary !important;
}

/*------------------------------- vertical carousel indicators ------------------------------*/
/*
.carousel-indicators{
    position:absolute;
    top:0;
    bottom:0;
    margin:auto;
    height:20px;
    right:10px; left:auto;
    width:auto;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.carousel-indicators li{
    display:block; margin-bottom:5px; border:1px solid #00a199;     
}
.carousel-indicators li.active{
    margin-bottom:5px; background:#00a199;    
}
*/