.feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather-lg {
 @extend .feather;
  width: 40px !important;
  height: 40px !important;
}

.feather-icon-18 {
	@extend .feather;
	 width: 18px !important;
	 height: 18px !important;
}

.feather-md {
 @extend .feather;
  width: 16px !important;
  height: 16px !important;
}

.feather-sm {
 @extend .feather;
  width: 12px !important;
  height: 12px !important;
}

.feather-light {
	stroke-width: 1;
}

.feather-medium {
	stroke-width: 2.2;
}

.icons-color {
	stroke: $gray-400;
}

.icons-fill {
	fill: $gray-500 !important;
	color: transparent !important;
}

.modal-icons-lg {
	width: 90px;
	height: 90px;
	border-radius: 100%;
	display: flex;
    align-items: center;
    justify-content: center;
	.feather {
		width: 40px;
		height: 40px;
	}
}

.icons-athens-gray {
	background-color: $athens-gray;
	.feather {
		stroke: $gray-400;
	}
}

.icons-french-pass {
	background-color: $french-pass;
	.feather {
		stroke: $blue;
	}
}

.icons-jagged-ice {
	background-color: $jagged-ice;
	.feather {
		stroke: $green;
	}
}

.icons-tuft-bush {
	background-color: $tuft-bush;
	.feather {
		stroke: $red;
	}
}

.icons-assessments {
	background-color: $jagged-ice;
	.feather {
		stroke: $green;
	}
}

.icons-ots {
	background-color: $french-pass;
	.feather {
		stroke: $blue;
	}
}

.icons-ots-danger {
	background-color: $tuft-bush;
	.feather {
		stroke: $red;
	}
}

.icons-lock {
	background-color: $tuft-bush;
	.feather {
		stroke: $red;
	}
}

.icons-warning {
	background-color: $egg-white;
	.feather {
		stroke: $yellow;
	}
}

.icons-info {
	background-color: $cyan;
	.feather {
		stroke: $white;
	}
}

.icon-lesson-chapters {
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;
}