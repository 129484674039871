*:focus {
    outline: 0 !important;
    box-shadow: none !important;
}


html,
body {
    height: 100%;
    font-size: 14px;
    min-height: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    letter-spacing: normal;
}

.body-scroller-hide {
    overflow-y: hidden !important;
    #QuoteOfTheDayImage {
        &.modal-body {
            @include media-breakpoint-down(md) {
                overflow-y: hidden !important;
            }
        }
    }
}

/*
@media (-webkit-device-pixel-ratio: 1.25) {
    * {
      zoom: 0.99;
    }
}
*/


/*
@media screen and (-webkit-device-pixel-ratio: 1.25) { 
    html,
    body {
        font-size: 11.5px;
    }
}
*/

body {
    overflow-y: scroll;
}

a {
    cursor: pointer;
    &.text-secondary {
        &:hover,
        &:focus {
            color: inherit !important;
        }
    }
}

// img {
//     aspect-ratio: attr(width) / attr(height);
// }


/*
Custom scrollbar
*/


/* Thin Scrollbar */

:root {
    scrollbar-color: rgba($black, .4) transparent !important;
    scrollbar-width: thin !important;
}

// width
::-webkit-scrollbar {
    width: 4px;
    height: 2px;
}
#tableResponsiveWrapper {
    &.table-responsive {
        &::-webkit-scrollbar {
            width: 10px;
            height: 5px;
        }
    }
}

// Track
::-webkit-scrollbar-track {
    background: transparent;
}

#tableResponsiveWrapper {
    &.table-responsive {
        &::-webkit-scrollbar-track {
            background: rgba($black, .2);
        }
    }
}

// Handle
::-webkit-scrollbar-thumb {
    background: rgba($black, .4);
}

// Handle on hover 
::-webkit-scrollbar-thumb:hover {
    background: rgba($black, .4);
}

//@at-root
.d-contents {
    display: contents;
}

.underline-reset {
    text-decoration: none !important;
}

.text-reset {
    color: $gray-600 !important;
}

.line-height--75 {
    line-height: .75 !important;
}

.line-height-normal {
    line-height: 1 !important;
}

.line-height-medium {
    line-height: 1.2 !important;
}

.line-height-semi-medium {
    line-height: 1.3 !important;
}

.line-height-large {
    line-height: 1.5 !important;
}

.line-height-extra-large {
    line-height: 3 !important;
}

.text-sm {
    font-size: $font-size-sm; // 12
}

.text-md {
    font-size: $font-size-base; // 14
}

.text-lg {
    font-size: $font-size-lg; // 16
    @include media-breakpoint-down(lg) {
        font-size: $font-size-base;
    }
}

.fs-18 {
    font-size: 18px; // 18
}

h4,
.h4 {
    @include media-breakpoint-down(lg) {
        font-size: $font-size-base;
    }
}

.text-xl {
    font-size: $font-size-xl; // 20
}

.text-xxl {
    font-size: $font-size-xxl; // 40
} 

.font-weight-light {
    //font-weight: $font-weight-light;
    font-family: 'Poppins Light';
}

.font-weight-normal {
    //font-weight: $font-weight-normal !important;
    font-family: 'Poppins Regular';
}

.font-weight-medium {
    //font-weight: $font-weight-medium !important;
    font-family: 'Poppins Medium';
}

.font-weight-semibold {
    //font-weight: $font-weight-semibold !important;
    font-family: 'Poppins SemiBold';
}

.font-weight-bold {
    //font-weight: $font-weight-bold !important;
    font-family: 'Poppins Bold';
}

.border-radius-xl {
    border-radius: $border-radius-xl;
}

.border-none {
    border: $border-width solid transparent;
}

.border-xl {
    @include media-breakpoint-up(xl) {
        border: $border-width solid $border-color;
    }
}

.border-xl-start {
    @include media-breakpoint-up(xl) {
        border-left: $border-width solid $border-color;
    }
}

.border-lg-top {
    @include media-breakpoint-up(lg) {
        border-top: $border-width solid $border-color;
    }
}

.border-lg-end {
    @include media-breakpoint-up(lg) {
        border-right: $border-width solid $border-color;
    }
}

.rounded-top-start {
    border-top-left-radius: $border-radius;
}

.rounded-top-end {
    border-top-right-radius: $border-radius;
}

.overflow-inherit {
    overflow: inherit !important;
}

.overflow-initial {
    overflow: initial !important;
}

.overflow-unset {
    overflow: unset !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.overflow-y-auto {
    overflow-y: auto;
} 

.shadow-inset {
    box-shadow: $box-shadow-inset;
}

.border-dashed {
    border: $border-width dashed $border-color;
}

.shadow-none {
    box-shadow: none;
}

.min-width-100 {
    @include media-breakpoint-up(sm) {
        min-width: 100px;
    }
}

.one-lines-wrap {
    overflow: hidden;
    //display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.one-lines-wrap-min-height {
    height: 1.3em;
    line-height: 1.3;
}

.two-lines-wrap {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.5em;
    max-height: 3em;
}

.two-lines-wrap-min-height {
    height: 3em;
}

.three-lines-wrap {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.5em;
    max-height: 4.5em;
}

.three-lines-wrap-min-height {
    height: 4.5em;
}

.word-break-all {
    word-break: break-all;
}

.word-break-word {
    word-break: break-word;
}

.active-scroll {
    overflow-y: auto;
    height: -moz-calc(100vh - 15rem);
    height: -webkit-calc(100vh - 15rem);
    height: calc(100vh - 15rem);
    display: block;
}

.z-index-0 {
    z-index: 0 !important;
}

.w-lg-50 {
    @include media-breakpoint-up(lg) {
        width: 50%;
    }
}

.w-70 {
    @include media-breakpoint-down(lg) {
        width: 100%;
    }
}

// main layout
.main-wrapper {
    //padding-top: 148px;
    @include media-breakpoint-down(lg) {
        padding-top: 74px;
    }
}

.whats-new {
    min-width: 150px;
    display: flex;
    align-items: center;
    padding: ($spacer* .822) 0 ($spacer* .822) $spacer;
    z-index: $zindex-top-navbar;
}

footer {
    background-color: $gray-600;
    color: $white;
    padding: $spacer;
    //text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 46px;
    z-index: $zindex-fixed;
}

#opt-code.User {
    background-color: $white;
}

#index.User {
    footer {
        position: fixed;
        margin-top: 46px;
    }
}

.scrollFixed {
    position: sticky;
    z-index: 1020;
    @include media-breakpoint-up(lg) {
        top: 217px;
    }
    /*@include media-breakpoint-up(xxxl) {
			top: 148px;
		}*/
    @include media-breakpoint-down(lg) {
        top: 70px;
    }
}

#discussions.User,
#ideabox_discussions.User,
#ideas.User,
#ideabox.User {
    .scrollFixed {
        @include media-breakpoint-up(lg) {
            top: 217px;
            @media screen and (-webkit-device-pixel-ratio: 1.25) {
                top: 214px;
            }
        }
    }
    @media screen and (-webkit-device-pixel-ratio: 1.25) {
		.main-wrapper {
            padding-top: 214px;
        }
	}
}

#ideabox.User {
    .scrollFixed {
        @include media-breakpoint-down(lg) {
            top: 0;
            position: inherit;
        }
    }
}

.addChannelView {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.addChannelView-md {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.video-mask {
    -webkit-mask-box-image: url('../../img/video-animation/video-mask.svg');
    mask-image: url('../../img/video-animation/video-mask.svg');
}

.video-js {
    margin-bottom: 1rem;
}

@include media-breakpoint-down(lg) {
    .btn-control {
        display: block;
    }
}

.object-view {
    position: fixed;
    right: 0;
    top: 158px;
    background-color: $white;
    box-shadow: $box-shadow;
    padding: .7rem 1.4rem;
    z-index: $zindex-object-view;
    cursor: pointer;
    border-top-left-radius: $border-radius-sm;
    border-bottom-left-radius: $border-radius-sm;
    .feather {
        color: $gray-400;
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.course-object-view {
    @include media-breakpoint-down(lg) {
        display: none;
    }
}


/*
.maininfo-sec{
	height: 200px;
}
*/

.q-type-section {
    @include media-breakpoint-up(lg) {
        height: 230px;
    }
    @include media-breakpoint-down(lg) {
        height: 260px;
    }
}

.activity-points-view {
    text-align: right;
    flex: 1;
    min-width: 200px;
}

.manage-m-d {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

// mobile notification backdrop
.backdrop-notification {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(35,49,67,.4);
    display: none;
    z-index: 999;
    opacity: .5;
}

// admin tab notification
.tab-notification {
    display: flex;
    justify-content: space-between;
    .tab-notification-bubble {
        display: flex;
        justify-content: space-between;
        padding: $badge-padding-y $badge-padding-x-sm;
        @include font-size($badge-font-size-sm);
        font-weight: $badge-font-weight;
        line-height: 1;
        text-align: center;
        align-items: center;
        white-space: nowrap;
        vertical-align: baseline;
        background-color: $warning;
        color: $white;
        border-radius: 50%;
    }
    &.active {
        .tab-notification-bubble {
            background-color: $white;
            color: $primary;
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.pointer-event {
    pointer-events: none;
}

.mobile-pointer-event {
    @include media-breakpoint-down(lg) {
        pointer-events: none;
    }
}

.opacity-50 {
    opacity: .5;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    //border-top: 1px solid $ghost;
    margin: 1em 0;
    padding: 0;
}

.box2 {
    width: 100%;
}

.choosefile-field[type="file"] {
    display: none;
}

.choosefile-label {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-asign-dep {
    //border-left: 1px solid $bali-hai;
}

.border-asign-extra {
    @include media-breakpoint-up(sm) {
        //border-left: 1px solid $bali-hai;
    }
}

#preloader {
    display: none;
}

.line {
    //border: 1px dotted $ghost;
}

.divider-section-radius {
    border-radius: 20px;
}

//choose file part
.uploadFile {
    width: 100%;
    margin: auto;
    height: auto;
    padding: 10px;
    text-align: center;
}

.camera {
    //background:url('badge-icon.png');
    height: 200px;
    //width:150px;
    //display:block;
    cursor: pointer;
}

.addimgfield[type="file"] {
    display: block;
    width: 150px;
    height: 150px;
    display: none;
}

.submitI {
    padding: 5px 10px 5px 10px;
    background: #0000ff;
    color: white;
    width: 120px;
    margin: auto;
    cursor: pointer;
}

.imageReader {
    max-height: 150px;
    max-width: 150px;
}

.activiy-description {
    iframe {
        //width: 100%;
        //height: 600px;
        @include media-breakpoint-down(sm) {
            height: 300px;
        }
    }
}

.video-emberd-iframe {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    @media screen and (-webkit-device-pixel-ratio: 1.25) {
        padding-top: 36.25%;
    }
    iframe {
        //width: auto;
        //height: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

//
.badge-primary-lighten {
    color: $primary;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    background-color: rgba($primary, .3);
    padding: $input-btn-padding-y 1.85rem;
    display: inline-block;
    border-radius: $border-radius-lg;
}

.badge-scooter-lighten {
    //color: $scooter;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    //background-color: rgba($scooter,.3);
    padding: $input-btn-padding-y 1.85rem;
    display: inline-block;
    border-radius: $border-radius-lg;
}

.badge-jungle-green-lighten {
    //color: $jungle-green;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    //background-color: rgba($jungle-green,.3);
    padding: $input-btn-padding-y 1.85rem;
    display: inline-block;
    border-radius: $border-radius-lg;
}

.badge-dodger-blue-lighten {
    //color: $dodger-blue;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    //background-color: rgba($dodger-blue,.3);
    padding: $input-btn-padding-y 1.85rem;
    display: inline-block;
    border-radius: $border-radius-lg;
}

.badge-fern-lighten {
    //color: $fern;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    //background-color: rgba($fern,.3);
    padding: $input-btn-padding-y 1.85rem;
    display: inline-block;
    border-radius: $border-radius-lg;
}

.active-link {
    p {
        color: $primary !important;
    }
}

// Default navigation hide in mobile
#discussions.User,
#ideabox_discussions.User,
#ideas.User,
#quizup_user_list.User,
#quizup_list.User,
#badges.User,
#rank_map.User,
#points.User,
#library_item.User,
#library.library-items-view,
#ideabox.User,
#courses.User,
#quizup_category.User,
#question.User,
#comprehension.User,
#list_questions.User,
#trophy.User,
#user_survey_view.User,
#ots_training,
#ots_training_items,
#ots_assign_training,
#ots_training_session,
#ots_confirm_users {
    .dataTables_filter {
        display: none;
    }
    @include media-breakpoint-down(lg) {
        .main-wrapper {
            padding-top: 74px;
        }
        .navbar-mobile {
            display: none !important;
        }
    }
}

#layup_ai_chat {
    .main-wrapper {
        padding-top: 148px;
        @include media-breakpoint-down(lg) {
            padding-top: 74px;
        }
    }
}

#library.User {
    .nav-mobile-inner {
        display: none;
    }
}

#library.library-items-view {
    .nav-mobile-inner {
        @include media-breakpoint-down(lg) {
            display: flex;
        }
    }
}

.singleDiscussion {
    display: block;
    width: 100%;
    overflow-x: auto;
}


/* Tabs panel
.tabbable-panel {
  border:1px solid #eee;
  padding: 10px;
}*/

.cal-icon-settings {
    color: $gray-500 !important;
    width: 20px;
    height: 20px;
}


/* Default mode */

.list-content {
    //	border-bottom: 1px solid $athens-gray;
    padding-bottom: 10px;
}

//
.badge-lg {
    padding: $btn-padding-y $btn-padding-x;
    font-size: $btn-font-size;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    border: $btn-border-width solid transparent;
}

.card-discussion-table {
    .discussion-item {
        &:hover {
            background-color: $gray-100;
        }
    }
}

#idea_data_table,
.discuss_data_table {
    .idea-item,
    .discussion-item {
        &:hover {
            background-color: $gray-100;
        }
    }
}

.user-list-scroll {
    #ListContainer {
        .user-list-item {
            &:hover {
                background-color: $gray-100;
            }
        }
    }
}

#externalModuleContent {
    .discussion-form {
        .form-actions {
            .btn-primary {
                background-color: $gray-500;
                border-color: $gray-500;
            }
        }
    }
}

#notificationList {
    .dropdown-item {
        &:hover {
            background-color: $gray-100;
        }
    }
}

.icons-winner-size {
    font-size: 38px;
}

.tick-on {
    .ico-check-circle {
        color: $success;
    }
}

.nav-card-header {
    .nav-link {
        //font-weight: $font-weight-medium !important;
        font-family: 'Poppins SemiBold' !important;
    }
    .active {
        font-family: 'Poppins SemiBold' !important;
    }
}

.pe-sm-none {
    @include media-breakpoint-down(lg) {
        pointer-events: none!important;
    }
}

//logout-icon mobile nav slider
.logout-icon{
    width: 29px !important;
    height: 29px !important;
    stroke: #4f5a69 !important;
    stroke-width:2.1 !important;
}


//swiper slider fade effect at the end and start

.fade-left{

    &::after{
        background: linear-gradient(to left, $athens-gray, rgba(255, 255, 255, 0));
        width: 100px;
        z-index: 100;
        content: '';
        height:100%;
        position: absolute;
        top: -7px;
        box-sizing: border-box;
        right: 0;

    }
   
}


.fade-right {


    &::before {
        background: linear-gradient(to right, $athens-gray, rgba(255, 255, 255, 0));
        width: 100px;
        z-index: 100;
        content: '';
        height: 100%;
        position: absolute;
        top: -7px;
        box-sizing: border-box;
        left: 0;

    }

}


.mw-lg-280{
    @include media-breakpoint-up(lg) {
        max-width: 180px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 280px;
    }
}


.mw-lg-360{
    @include media-breakpoint-up(lg) {
        max-width: 260px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 360px;
    }
}

.min-lg-120{
    @include media-breakpoint-up(lg) {
        min-width: 120px;
    }
}

/*help center*/

.iframeWrapper {
    
    min-height: calc(100vh - 290px);

    #adminHelpCenter{
        border:none;
    }
}


#toTop{
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-bottom: 3rem;
    margin-right: 4rem;

    img{
        transition: 0.3s;

        &:hover{
            margin-bottom: 4px;
        }
    }
}

.max-width-quizTitle{
    max-width: calc(100% - 74px);
}


#scorm-iframe {
    height: calc(100vh - 140px);
}

.ots-select-user-name, .ots-select-user-email{

    max-width: 200px;
    
    @include media-breakpoint-down(sm) {
        max-width: 120px;
    }

    @include media-breakpoint-down(xs) {
        max-width: 80px;
    }

}

//Curriculums lock courses img

.course-img-lock {
    //filter: blur(7px);
}

.ots-training-progress {
    .container{
        @include media-breakpoint-down(lg) {
            height: calc(100vh - 74px);
            display: flex;
            flex-direction: column;
            justify-content: space-around;
        }
    }
}

.text-help-login{
    position: absolute;
    right: 8.7%;
    left: auto;
    bottom: 5%;
    color: #fff;
    @include media-breakpoint-down(lg) {
        width: 100%;
        font-size: .8571rem;
        text-align: center;
        left: auto;
        right: auto;
        bottom: 2%;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }

}

#system_error_pickme, #system_error_pickmeqe{
    background-color: $dark;
}

//remove pop-out option

.ndfHFb-c4YZDc-Wrql6b {
    display: none;
}

#backDropLoader {
    display: none; /* Hide initially */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1091;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
}