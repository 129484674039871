.btn-close,
.close {
	width: 35px;
	height: 35px;
    border-radius: 100%;
	padding: 0 !important;
	background-color: $gray-200 !important;
	display: flex;
    justify-content: center;
    align-items: center;
	opacity: 1 !important;
	border: 0;
	.feather {
		width: 16px;
		height: 16px;
		stroke: $gray-400;
	}
	&:hover,
	&:focus {
		background-color: #E9E9E9 !important;
		cursor: pointer;
	}
}

.close-sm {
	width: 25px;
	height: 25px;
}

.close-xs {
	width: 20px;
	height: 20px;
	background-size: 0.5em;
}

.modal-header .close {
	&:hover,
	&:focus {
		background-color: #E9E9E9 !important;
	}
}

.alert{
	.close {
		&:hover,
		&:focus {
			background-color: transparent !important;
		}
	}
}