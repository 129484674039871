.dropdown-menu {
	margin: .125rem 0 0;

	//discussion notify me alignment issue
	.form-check-input{
		margin-right: 1rem;
	}

}

.dropdown-item {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	transition-duration: 0.3s;
	&.discussItem {
		line-height: 1.2;
	}
}

// Custom Select
.select-country-code {
	min-width: 78px;
}
/*the container must be positioned relative:*/
.customs-select {
	position: relative;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	&.country { // this code for user setting page - select country code
		.form-control-search {
			width: 100%;
		}
	}
}
  
  .customs-select select {
	display: none; /*hide original SELECT element:*/
  }
  
  .select-selected {
	background-color: $input-bg;
	color: $input-color;
	padding: $input-btn-padding-y $input-btn-padding-x .593rem;
	cursor: pointer;
	user-select: none;
	border-radius: $input-border-radius;
	//font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
  }
  
  /*style the arrow inside the select element:*/
  .select-selected:after {
	position: absolute;
	content: $navbar-toggle-icon;
	top: 8px;
	right: 18px;
  }
  
  /*point the arrow upwards when the select box is open (active):*/
  .select-selected.select-arrow-active:after {
	/*border-color: transparent transparent #fff transparent;
	top: 7px;*/
  }
  
  /*style items (options):*/
  .select-items {
	position: absolute;
	background-color: $input-bg;
	top: 100%;
	left: 0;
	right: 0;
	z-index: 99;
	margin-top: 8px;
	border-radius: $border-radius-sm;
    box-shadow: $box-shadow-sm;
	max-height: 200px;
    overflow-y: auto;
	div {
		color: $input-color;
		padding: .601rem $input-btn-padding-x .4rem;
		cursor: pointer;
		user-select: none;
		border-radius: $input-border-radius;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
	}
	&:hover {
		color: $secondary;
	}
  }

  .modal-form-body-scroll {
	.select-items {
		max-height: inherit;
	}
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
	display: none;
  }
  
  .same-as-selected {
	color: $secondary; 
  }

  //user settings country code

  .select-country-code{

	#countryCode{
		
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='6' viewBox='0 0 5.414 3.207'%3E%3Cpath data-name='Path 766' d='M1162.662,123l-2,2-2-2' transform='translate(-1157.955 -122.293)' fill='none' stroke='%238C9BAC' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E%3C/svg%3E");
		background-repeat: no-repeat;
		background-position: 90% 50%;
	}

	.select-items {
		max-height: 320px;
		width: 390px;
		@include media-breakpoint-down(sm) {
			width: calc(100vw - 56px);
			max-height: 280px;
		}
	}
  }


// dashboard ShareModal
.dropdown-menu-select-instance {
    min-width: 300px !important;
    .dropdown-item-toggle {
        display: flex;
        align-items: center;
        &::after {
            content: $navbar-toggle-icon;
            border: none;
            display: inline-block;
            margin-left: 10px;
            transform: rotate(270deg);
        }
    }
}

// instance list
.dropdown-instance-list {
	&::after {
		position: absolute;
		right: -10px;
	}
}

.dropdown-menu-scroller {
    max-height: 245px;
    overflow-y: auto;
    overflow-x: hidden;
}

// course tag
.tag-dropdown-menu {
	min-width: 43rem;
	@include media-breakpoint-down(lg) {
		min-width: 96vw;
	}
	@include media-breakpoint-down(md) {
		min-width: 93vw;
	}
	padding: 0;
	.tag-dropdown-menu-item {
		display: inline-flex;
	}
	.btn {
		margin-bottom: 10px;
    	margin-right: 6px;
	}
	.tag-dropdown-menu-scroller {
		overflow-y: scroll;
		overflow-x: hidden !important;
		max-height: 500px;
		@media screen and (-webkit-device-pixel-ratio: 1.25) {
			max-height: 340px;
		}
		@include media-breakpoint-down(md) {
			max-height: 420px;
		}
	}
}