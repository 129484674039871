/**
 * Firefox specific rule
 */

 @-moz-document url-prefix() {
    body {
        font-weight: lighter !important;
    }
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}