#index.User {
    @include media-breakpoint-down(lg) {
        background-color: $white;
    }
}

.login-section {
    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 100vh;
        overflow-y: auto;
    }
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: start;
        overflow-y: auto;
    }
}

.login-form {
    padding: 1rem;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
        margin-right: 8.7%;
    }
    @include media-breakpoint-up(md) {
        background: $white;
        border-radius: $border-radius;
        width: 400px;
        box-shadow: $box-shadow;
        padding: 2rem;
    }
    @include media-breakpoint-down(md) {
        width: 100%;
        height: 100%;
        margin-bottom: auto;
    }
}

.text-copy-rights {
    position: absolute;
    left: 8.7%;
    bottom: 5%;
    color: $white;
    @include media-breakpoint-down(lg) {
        width: 100%;
        font-size: $font-size-sm;
        text-align: center;
        left: auto;
    }
    @include media-breakpoint-down(md) {
        /*margin-bottom: 20px;
        position: unset;        
        color: $gray-600; */
        display: none;       
    }
}
.mobile-signup-mt {
    @include media-breakpoint-down(sm) { 
        margin-top: 30vh;
    }
}

.mobile-first-view {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
}

// payment login
.login-card {
    min-width: 640px;
}