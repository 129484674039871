.chat-block {
    height: 81vh;
    @include media-breakpoint-down(lg) {
        height: 87vh;
    }
}

.chat-block-wrapper,
.chat-sample-block {
    position: relative;
    height: 100%;
}

.chat-sample-section {
    height: 85%;

}
.chat-examples-text {
    font-size: $h2-font-size; // 30px
}
.chat-sample-item {
    margin-bottom: 10px;
    @include media-breakpoint-up(md) {       
        margin: 10px;
    }
    border: $border-width solid $mercury;
    width: 100%;
    font-size: 1rem;    
}
.chat-sample-input {
        position: absolute;
        bottom: 0;
        width: 100%;
        width: -webkit-fill-available;
        width: -moz-available;
        padding: 0 20px 20px;    
}

.btn-chat-send {
        position: absolute;
        right: 20px;
        bottom: 20px;
        padding: 7px;
}
.list-chat-block {
    overflow-y: auto;
    height: calc(100% - 100px);
    padding-bottom: 30px;
    &::after {
        content: "";
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0, rgba(255,255,255,1) 50%);
        height: 60px;
        width: 100%;
        position: absolute;
        bottom: 60px;
    }
}
.list-chat {
    list-style: none;
    padding: 0;
    margin: 0;
    .list-chat-item {
        display: flex;
        &:first-child {
            margin-top: 20px;
        }
        &:nth-child(odd) {
            font-weight: 600;
        }
        &:nth-child(even) {
            background-color: $black-haze;
            font-weight: 500;
        }
        padding: 20px;
    }
    .list-chat-avator,
    .list-chat-non-avator {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .list-chat-non-avator {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
    }
    .list-chat-content {
        padding-top: 10px;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                padding: 5px 0;
            }
        }
    }
    .content-loader {
        position: relative;
        padding-top: 0 !important;
        &::before {
            content: "";
            width: 40px;
            height: 40px;
            position: absolute;
            background: url("../../img/chat/ai-loader.svg") no-repeat 0 0;
        }
    }
}