.module-block-size-70 {
	width: 100%;
	@include media-breakpoint-up(xl) {
		width: 70%;
	}
	@include media-breakpoint-up(lg) {
		width: 60%;
	}
	.two-lines-wrap {
		@include media-breakpoint-up(lg) {
			-webkit-line-clamp: 1;
		}
	}
}

.module-block-size-77 {
	width: 100%;
	@include media-breakpoint-up(lg) {
		width: 77%;
	}
	.two-lines-wrap {
		@include media-breakpoint-up(lg) {
			-webkit-line-clamp: 1;
		}
	}
}

.assessment-block-size-100 {
	width: 100%;
	.two-lines-wrap {
		@include media-breakpoint-up(lg) {
			-webkit-line-clamp: 1;
		}
	}
	.w-80 {
		@include media-breakpoint-down(lg) {
			width: 100% !important;
		}
	}
}
/*
#courses,
#user_survey {
	&.User {
			.tab-content {
				background-color: transparent;
				box-shadow: none;
			}

			.form-control-search {
				border: $border-width solid $white;
			}

			@include media-breakpoint-down(lg) {
				.mobile-breadcrumb-title,
				.mobile-breadcrumb-search {
					display: none;
				}
			}
	}
}*/

#courses,
#rolebase_courses,
#ots_item_assign_training,
#ots_item_confirm_users,
#user_survey,
#external_courses {
	&.User {
		.tab-content {
			background-color: transparent;
			box-shadow: none;
		}
		.form-control-search {
			width: 385px;
			border: $border-width solid $white;
			@include media-breakpoint-only(lg) {
				width: 320px;
			}
		}
		@include media-breakpoint-down(lg) {
			.mobile-breadcrumb-title,
			.mobile-breadcrumb-search {
				display: none;
			}
			.breadcrumb-panel-fixed {
				display: block;
				top: 60px;
				padding: 15px 0;
				#course-tab {
					@include media-breakpoint-down(lg) {
						width: 100%;
					}
					.nav-item {
						flex: 1 1 auto!important;
						.nav-link{
							@include media-breakpoint-down(lg) {
								min-width: 100%;
							}
						}						
					}
				}
			}
			.main-wrapper {
				padding-top: 130px;
			}
			.dropdown {
				width: 100%;
			}
		}
	}
}

#ots_item_assign_training,
#ots_item_confirm_users {
	&.User {
		.assign-user-ots-tab-nav {
			@include media-breakpoint-down(lg) {
				width: 100%;
				.nav-pills-assign-user-ots {
					.nav-item {
						flex: 1 1 auto;
						.nav-link {
							text-align: center;
						}
					}
				}
			}
		}
		.main-wrapper {
			@include media-breakpoint-down(lg) {
				padding-top: 194px;
			}
		}
	}
}

#ots_item_confirm_users {
	&.User {
		.main-wrapper {
			@include media-breakpoint-down(lg) {
				padding-top: 130px !important;
			}
		}
	}
}

#courses.User.module-view {
	@include media-breakpoint-down(lg) {
		.breadcrumb-panel-fixed {
			display: none;			
		}
		.main-wrapper {
			padding-top: 74px;
		}
	}
}

#comprehension.User {
	.form-control-search {
		border: $border-width solid $white;
	}
	#tabPanel .nav-item {
		flex: 1 1 auto!important;
	}
	.tab-content {
		@include media-breakpoint-down(lg) {
			background-color: transparent;
			box-shadow: none;
		}
	}
}

// activity page 
.img-activity {
	max-width: 100%;
	height: auto;
	overflow: hidden;
	@include border-radius($border-radius-sm);
}

.img-activity-secondary {
	fill: rgba($secondary, 1)
}

.card-activity-mobile {
	@include media-breakpoint-down(lg) {
		//box-shadow: none !important;
		//margin-bottom: 0;	
	}
}

.activtie-title {
	display: flex;
	align-items: center;
	@include media-breakpoint-down(lg) {
		flex-direction: column-reverse;
		.partContent {
			//font-weight: $font-weight-medium;
			font-family: 'Poppins Medium';
			margin-bottom: $spacer * .5;
		}
	}
}

.activtie-points {
	display: table;
	font-size: $h6-font-size;
	color: $gray-500;
	//background-color: $green;
	//border-radius: $border-radius-sm;
	//padding: $btn-padding-y $btn-padding-x;
	white-space: normal;
	@include media-breakpoint-down(sm) {
		//padding: $btn-padding-y;
		//font-size: 11px;
	}
	@include media-breakpoint-down(xs) {
		//font-size: 10px;
	}
}

.card-activity-scroll {
	max-height: 580px;
	overflow-y: auto;
	@include media-breakpoint-down(xl) {
		max-height: 480px;
	}
}

.md-course-progress {
	@include media-breakpoint-down(lg) {
		border: 1px solid $success;
		color: $success;
		display: flex;
		//padding: .5rem;
		border-radius: $border-radius-lg;
		overflow: hidden;
		min-width: 150px;
		height: 34px;
		i {
			color: $success !important;
		}
	}
}

.md-course-progress-bar {
	display: flex;
	align-items: center;
	@include media-breakpoint-up(lg) {
		width: 100% !important;
	}
	@include media-breakpoint-down(lg) {
		white-space: nowrap;
		background-color: rgba($success,.2);
		transition: width .6s ease;
	}
}

.list-activties-view {
	background-color: rgba($ghost, .1);
}

.md-assement-pass {
	color: $success;
	@include media-breakpoint-down(lg) {
		color: $white;
		//font-size: $font-size-xs;
		padding: ($spacer * .25) ($spacer * .85);
		border-radius: $border-radius-lg;
		text-transform: uppercase;
		background-color: $success;
	}
}
.md-assement-fail {
	//color: $bali-hai;
	@include media-breakpoint-down(lg) {
		color: $white;
		//font-size: $font-size-xs;
		padding: ($spacer * .25) ($spacer * .85);
		border-radius: $border-radius-lg;
		text-transform: uppercase;
		//background-color: $bali-hai;
	}
}

/*.activity-percentage {
	display: flex;
	font-size: $font-size-base;
	font-weight: $font-weight-medium;
	@include media-breakpoint-down(lg) {
		font-size: $font-size-sm;
	}
}*/

.activity-percentage-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	border-radius: 100%;		
	.feather {
		stroke-width: 1.5;
	}
}

.table-inner-icon-size {
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	.feather {
		width: 22px;
		height: 22px;
	}
	.table-icons {
		width: 40px;
		min-width: 40px;
		height: 40px;
		min-height: 40px;
		.feather {
			width: 22px;
			height: 22px;
		}
	}
}

.activity-percentage {
	text-align: center;
	min-width: 170px;
	max-width: 250px;
	width: auto;
	height: 40px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	line-height: 1;
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

.btn-assessment {
	//@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);   
	@include media-breakpoint-up(lg) {
		min-width: 170px;
		max-width: 250px;
		width: auto;
		//@include button-size(8.5px, $btn-padding-x-lg, $btn-font-size-lg, $line-height-normal, $btn-border-radius-lg);
		//height: 40px;
	}
}

.activity-status {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	font-size: $font-size-lg;
	text-transform: uppercase;
}

.content-locked {
	display: flex;
	align-items: center;
	//color: $trout;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	@include media-breakpoint-down(lg) {
		//border: 1px solid $athens-gray;
		//color: $bali-hai;
		//background-color: $athens-gray;
		border-radius: $border-radius-lg;
		overflow: hidden;
		//min-width: 150px;
		height: 40px;
		font-size: $font-size-sm;
	}
	.content-icon-locked {
		background: $gray-200;
		.feather {
			stroke: $gray-400;
		}
	}
}



.course-img-block {	
	//max-height: 305px;    
	//background: url('../../img/preloader.svg') center center no-repeat;
	overflow: hidden;
	position: relative;
	padding-bottom: calc(50%);
	@include media-breakpoint-up(sm) {
		//min-height: 170px;
	}
	@include media-breakpoint-down(lg) {
		//min-height: 185px;
		//padding-bottom: calc(54%);
	}
}

.course-img {
	width: 100%;	
	height: 100%;
	object-fit: cover;
    object-position: center center;
	left: 0;
    top: 0;
    position: absolute;    
    visibility: visible;
	@include media-breakpoint-up(sm) {
		//height: 170px;
	}
	@include media-breakpoint-down(lg) {
		//height: 185px;
		height: auto;
	}
}
.course-icon-default {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	left:50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 80px;
	height: 80px;
	background-color: rgba($white, .3);
	border-radius: 100%;
	.feather {
		stroke: $white;
		width: 40px;
		height: 40px;
		stroke-width: 2;
	}
}

/*
.course-dummy-img2 {
	height: 164px;
	width: 316px;
}
*/
.course-img-inner-block {
	padding-bottom: calc(11%);
	min-width: 357px;
	min-height: 180px;
	margin-bottom: 0 !important;
	margin-right: 2rem;
	overflow: hidden;
    position: relative;
	.course-inner-section-img {
		width: 100%;	
		height: 100%;
		object-fit: cover;
		object-position: center center;
		left: 0;
		top: 0;
		position: absolute;    
		visibility: visible;		
	}
}

.list-course-module-item,
.course-description-badge,
.list-estimated-time {
	.feather {
		color: $gray-400 !important;
	}
}

.course-right-arrow-more {
	stroke: #8C9BAC;
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.earned-points-text,
.earned-points-text-list {
	position: absolute;
	right: 16px;
	top: 16px;
	background-color: $purple;
	color: $white;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	font-size: $font-size-sm;
	cursor: pointer;
	@include media-breakpoint-only(xxl) {
		font-size: 10px;
	}
	.earned-points-view {
		display: none;
		opacity: 0;
	}
	.feather {
		width: 18px;
		height: 18px;
		min-width: 18px;
		min-height: 18px;
		max-width: 18px;
		max-height: 18px;
		stroke: $white;
		stroke-width: 2;
	}
}

.earned-points-text-list {
	display: none;
}

.earned-points-text.active {
	@include media-breakpoint-down(xs) {
		width: 90%;
	}
	.earned-points-view {
		display: block;
		opacity: 1;
		width: 100%;
	}
}

.earned-points-text-inner-section {
	// position: absolute;
	// right: 0;
	// top: 0;
	//background-color: $purple;
	//color: $white;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	//font-size: $font-size-lg;
}

.deadline-text {
	position: absolute;
	right: 16px;
	bottom: 16px;
	background-color: $red !important;
	color: $white;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

.deadline-text-inner-section {
	position: absolute;
	right: 16px;
	bottom: 16px;
	background-color: $red !important;
	color: $white;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

.earned-points-text-mobile {
	position: absolute;
	right: 30px;
	top: 30px;
	background-color: $purple !important;
	color: $white;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
}

.deadline-text-mobile {
	position: absolute;
	right: 30px;
	top: 130px;
	background-color: $white !important;
	color: $red;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	padding-top: 0.7rem;
	padding-bottom: 0.7rem;
}

.points-gained {
	background-color: $success !important;
}

.list-course-module-item,
.course-description-badge {
	.module-icon,
	.assessment-icon {
		height: 25px;
		width: 25px;
	}
}

.course-skills {
	min-height: 63px;
	max-height: 63px;
	overflow: hidden;
}

.skills-outer {
	padding: ($spacer * .7) ($spacer * .8);
	margin-right: ($spacer * .3);
}
/*
.course-inner-section-view {
	position: relative;
    display: flex;
    justify-content: space-between;
}

.course-inner-section-view {
	.media {
		max-width: 100%;
	}
}

.course-inner-section-view {
	.media {
		.flex-grow-1 {
			margin-left: ($spacer * 2.3); .earned-points-text
		}
	}
}
*/
.inner-round-icons {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	border-radius: 100%;
	.feather {
		stroke-width: 1.5;
	}
}
/*
.inner-round-icons-bg {
	@extend .inner-round-icons;
	background-color:$gray-200;
	.feather {
		stroke: $gray-400;
	}
}
*/
.list-course-tags {
	list-style: none;
	padding: 0;
	margin: 1.15rem 0 0;
	.list-course-tags-item {
		display: inline-block;
		border: $border-width solid $border-color;
		border-radius: $border-radius-sm;
		font-size: $font-size-sm;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		padding: .572rem 1rem;
		line-height: 1;
		color: $gray-400;
		margin-bottom: .5rem;
	}
}

.card-button-section {
	display: flex;
	justify-content: space-between;
}

.course-status-section {
	display: flex;
	flex-direction: column;
}

.dotted-spacer {
	display: none;	
	line-height: 1;	
}

//
#quizup_category.User {
	.scrollFixed {
		top: 218px;
		.quizup-tab-content {
			.tab-pane {
				max-height: 494px;
				overflow-y: auto;
				@include media-breakpoint-down(xxl) {
					//max-height: 526px;
				}
				@include media-breakpoint-down(lg) {
					max-height: 400px;
				}
			}
		}
	}	
}
.quizup-tab-nav {
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}
.nav-pills-quizup {
	@include media-breakpoint-down(lg) {
		width: 100%;
	}
	.nav-item {
		@include media-breakpoint-up(md) {
			min-width: 185px;
		}
		.nav-link {
			text-align: center;
		}
		@include media-breakpoint-down(lg) {
			margin: 0;
			flex: 1;
			text-align: center;
			&:first-child {
				.nav-link {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:last-child {
				.nav-link {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}

.quizup-tab-content {
	background-color: transparent;
	box-shadow: none;
}

.course-body-section {
	width: 100%;
}

.list-description {
	display: none;
}

.course-ribbon {
	position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    overflow: hidden;
    width: 80px;
    height: 80px;

	span {
		font-size: $font-size-sm;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		color: $white;
		text-align: center;
		line-height: 28px;
		transform: rotate(-45deg);
		width: 113px;
		display: block;
		background: $success;
		position: absolute;
		top: 15px;
		left: -26px;
	}

	&.mandatory{
		width: 104px;
    	height: 104px;

		span{
			width: 138px;
			background: $red;
			top: 25px;
    		left: -30px;
			box-shadow: 2px 2px 10px 0px rgba($color: #000000, $alpha: 0.2);
		}
	}
}

.course-ribbon-right {
	right: 0;
	left: inherit;
	span {
		transform: rotate(45deg);
    	left: -3px !important;
	}
}

.list-deadline-view {
	display: none;
}

.list-estimated-time {
	display: none;
}

/*
Course List and Grid view
*/

.course-list-view {
	.card-list-body {
		display: flex;
		align-items: center;
		@include media-breakpoint-down(xxl) {
			align-items: flex-start;
		}
		.course-img-block {
			padding-bottom: calc(11%);
			//width: 355px;
			min-width: 357px;
			min-height: 180px;
			margin-bottom: 0 !important;
			margin-right: 2rem;
			//position: inherit;
			@media (max-width: 1024px) {
				// width: 255px;
				// min-width: 255px;
				// min-height: 230px;
			}
		}
		.course-img {
			//height: auto !important;
		}
		.earned-points-view {
			display: block;
			opacity: 1;
		}
		.line-divider,
		.earned-points-text {
			display: none !important;
		}
		.earned-points-text-list {
			display: flex;
			cursor: default;
		}
		.btn-block {
			width: 170px;
			margin-top: auto !important;
		}
		.course-description {
			width: 100%;			
			@include media-breakpoint-up(xl) {
				height: 183px;
			}
			@include media-breakpoint-down(xxl) {
				height: 165px;
			}
		}
		.list-description {
			display: block;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		.list-estimated-time {
			display: flex;
			align-items: center;
		}
		.mb-auto {
			padding-top: 1.5rem;
			@include media-breakpoint-down(xxl) {
				padding-top: 0;
			}
		}
		.card-button-section {
			position: absolute;
			right: 1rem;
			bottom: 1rem;
			width: 540px;
			align-items: center;
			justify-content: flex-end;
			//@media (max-width: 1024px) {
			@include media-breakpoint-down(xxl) {
				position: relative;
				width: 100%;
				flex-direction: row-reverse;
				right: 0;
				bottom: 0;
				padding-top: 1rem;			
			}
			.btn {
				min-width: 135px;
			}
			.dropdown-toggle-split {
				min-width: auto;
			}
		}
		.course-status-section {
			flex-direction: row;
			align-items: center;
			//font-size: $font-size-lg;
			margin-right: 1rem;
			@include media-breakpoint-down(xxl) {
				margin-left: 1rem;
			}
		}
		.dotted-spacer {
			display: flex;
			margin: 0 ($spacer * .5);
		}
	}
	.two-lines-wrap {
		-webkit-line-clamp: 1;
		max-height: inherit;
		height: auto;
	}
	.list-deadline-view {
		display: contents;
	}
	.list-lg-course-deadline-text {
		@include media-breakpoint-down(xxl) {
			//display: none !important;
		}
	}
	.card-button-list-section {
		@extend .card-button-section;
		flex-direction: row !important;
		.btn {
			margin: 0 0 0 1rem !important;
		}
	}
}

.mobile-module-title {
	@include media-breakpoint-down(lg) {
		padding-top: 12px;
	}
}

.mobile-two-lines-wrap-disable {
	@include media-breakpoint-down(lg) {
		-webkit-line-clamp: inherit;
		line-height: inherit;
		max-height: inherit;
	}
}

.mobile-module-title {
	@include media-breakpoint-down(lg) {
		padding-top: 12px;
	}
}

.mobile-two-lines-wrap-disable {
	@include media-breakpoint-down(lg) {
		-webkit-line-clamp: inherit;
		line-height: inherit;
		max-height: inherit;
	}
}

/*
Course Feed
*/
.custom-control-img {
	position: relative;
	z-index: 1;
	min-height: 1.5rem;
	padding: 0 1rem;
	min-width: 170px;
	.custom-control-img-label {
		position: relative;
		margin-bottom: 0;
		vertical-align: top;
	}
	.custom-control-img-input {
		width: 1.45rem;
		height: 1.475rem;
	}
}


/*
Online Courses
*/
#online_courses,
#assignment_review,
#curriculum {
	&.User {
		.form-control-search {
			width: 385px;
		}
	}
}

#assignment_review {
	&.User {
		.documentView {
			height: 78vh!important;
		}
	}
}

.activity_image {
	max-width: 100%;
	height: auto;
} 


/*extarnal courses*/

.library-description{
	.course-title{
		min-height: auto;
		@include media-breakpoint-up(sm) {
			min-height: 3rem;
		}
		@include media-breakpoint-up(lg) {
			min-height: 4rem !important;
		}
	}
}

.library-list-view {
	.course-img-block {
		padding-bottom: calc(11%);
		min-width: 357px;
		min-height: 180px;
		margin-bottom: 0 !important;
		margin-right: 2rem;
	}

	.library-description{
		.course-title{
			min-height: inherit;
		}
	}

}


//ots list

.ots-wrap.library-list-view, .ots-course-wrap.library-list-view{

	padding-left: 0;
	padding-right: 0;

	.card{
		.module-img-games {
			padding-bottom: calc(11%);
			min-width: 357px;
			min-height: 180px;
			margin-bottom: 0 !important;
			margin-right: 2rem;
		}

		.description-wrapper{
			display: flex;
			width: 100%;
			flex-direction: column;

			.library-description{
				width: 100%;
    			padding-right: 0;
				height: 132px;

				.mb-auto{
					padding-top: 0;
				}

				.library-item-description{
					-webkit-line-clamp: 2;
				}
				
			}

			.line-divider{
				display: none;
			}
		}

	}

}

/*.library-list-view.ots-list-view{

	.line-divider{
		border-top: none;
		margin-top: 1rem !important;
	}

	.card-button-section {
		width: 100%;
		align-items: center;
		justify-content: space-between;		

		.btn {
			min-width: 135px;
		}
	}

}*/

//library

.library-list-view{
	.course-title.two-lines-wrap{
		-webkit-line-clamp: 1;
		max-height: inherit;
		min-height: auto !important;
	}
	.ots-description.two-lines-wrap{
		-webkit-line-clamp: 2;
		min-height: 48px;
	}
}


//ots

.training-mob-footer{
	bottom: 80px;
}

.training-start{
	@include media-breakpoint-down(lg) {
		width: 100%;

		.train-btn{
			width: 100%;

			.btn{
				width: 100%;
			}
		}

		
	}
}

.ots-training-progress{
	.ots-page-title{
		@include media-breakpoint-down(lg) {
			font-size: 2rem;
		}
	}
}

// premium
.label-premium {
	top: 1rem;
	right: 1rem;
	background-color: $tree-poppy;
	padding: .59rem 1.3rem;
	border-radius: $border-radius;
	cursor: context-menu;
	span {
		font-size: $font-size-sm;
		font-family: 'Poppins Medium';
		color: $white;
	}
}

@include media-breakpoint-down(md) {
	.breadcrumb-premium-courses-enabled {
		.dropdown-premium-courses-enabled {
			width: 100%;
			display: flex;
        	justify-content: space-between;
		}
	}
	.tab-premium-courses-enabled {
    	padding-top: 58px;
	}
}
// course tagging
.dashboard-card-wall,
.discussion-form,
.discussion-item-single {
	.course-taging {
		position: relative;
		img {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			height: 100px;
			@include media-breakpoint-down(sm) {
				border-radius: 8px !important;
				max-width: 100%;
				height: 212px;
			}
		}
		.course-taging-del-icon {
			@include media-breakpoint-down(sm) {
				position: absolute;
				right: 16px;
				top: 16px;
				background-color: $white;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				align-items: center;
			}
		}		
	}
}

.mobile-hide {
	@include media-breakpoint-down(md) {
		display: none;
	}	
}

.mobile-view {
	@include media-breakpoint-up(md) {
		display: none;
	}
}