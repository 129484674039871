#ideabox_discussions,
#ideabox {
	&.User {
		.tab-content {
			background-color: transparent;
			box-shadow: none;
		}
		.breadcrumb-panel-fixed {
			@include media-breakpoint-down(md) {
				display: flex;
				position: inherit;
				padding-top: 0;
				.container {
					padding-left: 0;
					padding-right: 0;
				}
				.nav-pills {
					width: 100%;
					.nav-item {
						margin-right: 0;
						flex: 1;
						.nav-link {
							border-radius: 0;	
							text-align: center;						
						}
						&:first-child {
							.nav-link {
								border-top-left-radius: $border-radius;
								border-bottom-left-radius: $border-radius;
							}
						}
						&:last-child {
							.nav-link {
								border-top-right-radius: $border-radius;
								border-bottom-right-radius: $border-radius;
							}
						}
					}	
					.nav-item:nth-of-type(2) {
						border-left: $border-width solid $border-color;
						border-right: $border-width solid $border-color;
					}		
				}
			}
		}
		.modal-body {
			overflow: initial;			
		}
	}

}

.btn-discussion {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	color: $secondary;
	padding: 0;
    font-size: inherit;
	.feather {
		stroke: $secondary;
		width: 12px;
		height: 12px;
	}
	&:hover,
	&:focus {
		.feather {
			stroke: $gray-600;
		}
	}
}

.ideabox-feed-scroll {
	overflow-y: auto;	
	max-height: 76vh;
	scrollbar-width: thin !important;
	@include media-breakpoint-up(xl) {
		max-height: 58vh;
	}
	@include media-breakpoint-up(lg) {
		max-height: 54vh;
	}
	@media screen and (-webkit-device-pixel-ratio: 1.25) {
		max-height: 50vh;
	}
}

// discusstion
.discussion-item-single {
    max-height: 607px;
    overflow-y: auto !important;
    margin: 0;
	@media screen and (-webkit-device-pixel-ratio: 1.25) {
		max-height: 462px;
	}
	@include media-breakpoint-down(xxl) {
		max-height: 540px;
	}
	@include media-breakpoint-down(xl) {
		max-height: 500px;
	}
	@include media-breakpoint-down(md) {
		max-height: 76vh;
	}
	.card-body {
		min-height: auto;
	}
}

.discussion-item {
	z-index: 900;
	.card-body {
		z-index: 900;
	}
}
.discussion-item.active {
	background-color: $gray-100;
}
.discussion-item-divider {
		width: inherit;
		height: 1px;
		background-color:$border-color;
		margin: 0 1rem;
}

.icon-discussion-item-right {
	position: absolute;
	right: 1rem;
	top: 1.75rem;
	.feather {
		width: 12px;
		height: 12px;
		stroke: $navbar-light-color-icon;
	}
}

.card-dropdown-nav {
	position: absolute;
	z-index: 1000;
	right: 0;
	.dropdown-menu-end {
		z-index: 1001;
	}
}

// Table
.card-discussion-table,
.card-idea-table {
	background-color: transparent;
	box-shadow: none;
	margin-bottom: 0;
	td {
		padding: 0 !important;
		border: 0;
		height: inherit !important;		
		vertical-align: top!important;		
	}
	tbody tr {
		&:last-child {
			.discussion-item-divider {
				display: none;
			}
		}
	}
}

.idea-item.active {
	background-color: $gray-100;
}

#discussions.User {
	.discussion-item-single {
		.alert {
			@include media-breakpoint-down(lg) {
				top: 74px;
			}
		}
	}
}

#ideabox.User {
	.alert {
		@include media-breakpoint-down(lg) {
			top: 74px;
		}
	}
}