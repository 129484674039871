// Styles copy from custom_style.css file
.activiy-description {
	position: relative;
	.activity_image {
		max-width: 100%;
		height: auto;
	}
}
/* 
.activiy-description .tutorialImage {
    width: 100%;
    height: 450px;
	background-color: $gray-500;
}

.playButton {
    background: url("../../img/Playbutton.png") no-repeat;
    padding: 130px 0px;
    position: absolute;
    width: 190px;
    cursor: pointer;
    z-index: 1030;
    
    background-position: 4px;
}
*/
.overlayDrawer {
    z-index: 1000; /*When this is removed, cannot draw the name area properly*/
    
    position: absolute;
}

#games.User .tutorialOverlay, 
#games.User .playButton{
	z-index: 8 !important;
}

#games.User #toppanel {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}

#games.User iframe.libraryView {
	@include media-breakpoint-down(lg) {
		height: 500px !important;
	}
}

#games.User,
#quizup_category.User,
#quizup_list.User {
	.modal-header {
		@include media-breakpoint-down(lg) {
			display: flex;
		}
	}	
	.modal-dialog {
		@include media-breakpoint-down(lg) {
			max-width: 100%;
		}
	}
}

.tutorialOverlay {
    text-align: center;
    background-color: #333333;
    opacity: .8;
}

#comprehension.User .tutorialOverlay {
    z-index: 1 !important;
}

@media screen and (max-device-width : 1024px) and (orientation : landscape)  {
	.tutorialOverlay {
		left: 0 !important;
	}
}

.module-img-games {
    overflow: hidden;
	position: relative;
	padding-bottom: calc(50%);
}

.games-img {
	width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    left: 0;
    top: 0;
    position: absolute;
    visibility: visible;
}

.player-name {
	@include media-breakpoint-down(lg) {
		width: 180px;
	}
}

/*
Quizup
*/
// quizup notification styles - lasith
.notifyjs-quiz-base {
    width: 320px;
    background: #fff;
    padding: 1rem;
    border-radius: $border-radius;
	box-shadow: $box-shadow;
	@include media-breakpoint-down(lg) {
		width: 299px;
	}
	 .title {
		font-size: 1rem;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
	}
	.buttons {
		display: flex;
		justify-content: flex-end;
		margin-top: 1rem;
		button {
			display: inline-block;
			  font-family: $btn-font-family;
			  //font-weight: $btn-font-weight;
			  color: $body-color;
			  text-align: center;
			  white-space: $btn-white-space;
			  vertical-align: middle;
			  //cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
			  user-select: none;
			  background-color: transparent;
			  border: $btn-border-width solid transparent;
			  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
			  @include transition($btn-transition);

			  &:hover {
				color: $body-color;
				text-decoration: none;
			  }

			  &:focus,
			  &.focus {
				outline: 0;
				box-shadow: $btn-focus-box-shadow;
			  }

			  // Disabled comes first so active can properly restyle
			  &.disabled,
			  &:disabled {
				opacity: $btn-disabled-opacity;
				@include box-shadow(none);
			  }

			  &:not(:disabled):not(.disabled):active,
			  &:not(:disabled):not(.disabled).active {
				@include box-shadow($btn-active-box-shadow);

				&:focus {
				  @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
				}
			  }
		}
		.yes {
			color: $white;
			background-color: $primary;
			border-color: $primary;	
			&:hover {
					color: $white;
					text-decoration: none;
			  }
		}
	}
}

.notifyjs-corner {
    z-index: 1080 !important;
}

// game
.game-title {
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
	font-size: $h4-font-size;
	margin-bottom: $spacer * .5;
	//line-height: 1.1;
	overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	@include media-breakpoint-down(lg) {
		font-size: $font-size-base;
		//color: $trout;
	}
}
.game-description {
	font-size: $font-size-lg;
	color: $gray-500;
	@include media-breakpoint-down(lg) {
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';
		font-size: $font-size-base;
		//color: $bali-hai;
		.two-lines-wrap-min-height {
			   height: 32px;
		}
	}
} 

.card-description {
	@extend .game-description;
}

.quizup-title {
	@extend .game-title;
}

.nav-card-header {
	display: flex;
	justify-content: space-between;
	.nav-link {
		width: 50%;
		text-align: center;
		//font-weight: $font-weight-normal;
		font-family: 'Poppins Regular';
		padding: 1.6rem 1rem;
		//border-bottom: 2px solid transparent;
		background-color: $alabaster;
		&:first-child {
			border-top-left-radius: $border-radius;
		}
		&:last-child {
			border-top-right-radius: $border-radius;
		}
	}
	.active {
		//border-bottom: 2px solid $gray-600;
		background-color: $white;
		//font-weight: $font-weight-medium;
		font-family: 'Poppins Medium';		
		&:first-child {
			box-shadow: 5px 0 5px -5px rgba($gray-400, .20) !important;
		}
		&:last-child {
			box-shadow: -5px 0 5px -5px rgba($gray-400, .20) !important;
		}
	}
}

.popover-block-container {
	@include media-breakpoint-down(lg) {
		display: none;
	}	
}

.modal-game {
	.modal-header {
		border-bottom: 1px solid $gray-300;
		padding: .5rem 0;
	}
	.btn-close {
		width: 25px;
		height: 25px;
		min-width: 25px;
		min-height: 25px;
		max-width: 25px;
		max-height: 25px;
		margin: ($modal-header-padding-y * -.5) 0 ($modal-header-padding-y * -.5) auto;
	}
	.modal-body {
		padding: 1rem 0;
		@include media-breakpoint-down(sm) {
			max-height: 400px;
		}
	}
	.modal-dialog {
		@include media-breakpoint-down(sm) {
			pointer-events: auto;
		}
	}
}

#quizup_category.User,
#games.User,
#ots_assign_training,
#ots_confirm_users {
	.breadcrumb-panel-fixed {
		@include media-breakpoint-down(lg) {
			display: flex;
			position: inherit;
			padding-top: 0;
			.container {
				padding-left: 0;
				padding-right: 0;

				.assign-user-ots-tab-nav{
					width: 100%;
				}
			}
			.nav-pills {
				width: 100%;
				.nav-item {
					margin-right: 0;
					flex: 1;
					.nav-link {
						border-radius: 0;	
						text-align: center;						
					}
					&:first-child {
						.nav-link {
							border-top-left-radius: $border-radius-sm;
							border-bottom-left-radius: $border-radius-sm;
						}
					}
					&:last-child {
						.nav-link {
							border-top-right-radius: $border-radius-sm;
							border-bottom-right-radius: $border-radius-sm;
						}
					}
				}	
				/*.nav-item:nth-of-type(2) {
					border-left: $border-width solid $border-color;
					border-right: $border-width solid $border-color;
				}*/	
			}
		}
	}
}
