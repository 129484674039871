.breadcrumb-item {
    display: inline-block;
    //font-weight: $font-weight-medium;
    font-family: 'Poppins Medium';
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 120px;
    &:before {
        position: relative;
        top: -1px;
    }
}


/*
breadcrumb and search fixed
*/

.breadcrumb-panel-fixed {
    position: fixed;
    z-index: $zindex-breadcrumb-fixed;
    top: 148px;
    left: 0;
    right: 0;
    background-color: $body-bg;
    padding: 10px 0 15px;
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

// library 
.library-group-breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 1rem 0;
    .breadcrumb-item {
        font-size: $font-size-xl;
        max-width: 350px;
        font-weight: 600;
        &::before {
            content: ">";
        }
        &:first-child {
            font-weight: 400;
            &::before {
                content: none;
            }
        }
    }
}