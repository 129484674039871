/*
.tutorialOverlay {
    text-align: center;
    background-color: #333;
    opacity: .8;
    left: 0 !important;
    z-index: 8 !important;
    width: 100%!important;
    height: 450px!important;
    position: absolute;
    top: 0!important;
}

.tutorialImage {
    box-shadow: none !important;
    width: 100%;
    height: 450px;
}
*/ 
.playButton {
    z-index: 8 !important;
    background: url("../../img/playButton.svg") no-repeat;
    position: absolute;
    width: 240px;
    height: 60px;
    cursor: pointer;
    top: 50% !important;  
    left: 50% !important; 
    transform: translate(-50%, -50%) !important;
    box-shadow: $box-shadow;
    border-radius: border-radius;
    @include media-breakpoint-down(lg) {
        background: url("../../img/playButton-sm.svg") no-repeat;
        width: 180px;
        height: 45px;
    }
}

.activiy-description{
    position: relative;
}