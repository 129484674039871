.progress-bar-loading {
  position: relative;
  
  &.card-image {
    border-radius: 0;
  }
  
  &::after {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background: linear-gradient(90deg, transparent, rgba($french-pass, .6), transparent);
    animation: loading 3s infinite;
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}