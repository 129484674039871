.card-badge {
	min-height: 308px;
	@include media-breakpoint-down(lg) {
		min-height: 245px;
	}
}
.badge-size {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
	background-color: $white;
	border: $card-border-width solid $card-border-color;
	position: relative;
	height: 195px;
	min-height: 195px;
	max-height: 195px;
	@include border-radius($card-border-radius);
    @include media-breakpoint-down(lg) {
		height: 150px;
		min-height: 150px;
		max-height: 150px;
		align-items: center;
		@include border-radius($border-radius-xl);
	}
}
.badge-image {
	padding: ($spacer*2.608) 0;
	@include media-breakpoint-down(lg) {
		padding: $spacer 0;
	}
}

.badge-lock {
	background-color: rgba($gray-600, .2);
	overflow: inherit; 
    img {
        //opacity: 0.2;
		filter: blur(4px);
    }
	.feather {
		width: 30px;
		height: 30px;
		stroke: $white;
		position: absolute;
		font-size: $font-size-xxl;
		-webkit-text-stroke: 0;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 2;		
	}
	@include media-breakpoint-down(lg) {
		//background-color: $bali-hai;
	}
}


.certificate-lock {
	@extend .badge-lock;
	&::before {
		content: "";
		//filter: blur(4px);
		background-color: rgba($gray-600, .2);
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		z-index: 1;
		@include border-radius($card-border-radius);
	}
}

.badge-name,
.certificate-name {
    font-size: $font-size-lg;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
    text-align: center;
	margin: ($spacer*1.75) 0 ($spacer*0.85);
    padding: 0 $spacer;
	 overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
    text-overflow: ellipsis;
	line-height: 1.5em;
    max-height: 3em;
	@include media-breakpoint-down(lg) {
		margin: ($spacer*0.85) 0  ($spacer*0.45);
	}
}

.card-certificate {
	min-height: 250px;
	@include media-breakpoint-down(lg) {
		min-height: 224px;
	}
}

.certificate-size {
	background: url(../../img/bg-rewards-sm.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8px;
    display: flex;
	justify-content: center;
	align-items: center;
	border-radius: $border-radius;
	position: relative;
	overflow: hidden;
	height: 136px;
	max-height: 136px;
	min-height: 136px;    
	@include media-breakpoint-down(lg) {
		height: 130px;
		max-height: 130px;
		min-height: 130px;
	}
}

.certificate-image {
	border-radius: $border-radius;
	overflow: hidden;
}

.btn-points-view {
	min-width: 100px;
	padding: .572rem;
	@include media-breakpoint-up(lg) {
		min-width: 150px;
	}
}

#badges.User,
#trophy.User {
	.modal {		
		@include media-breakpoint-up(lg) {
			max-width: $modal-lg;
		}
	}
	.modal-header {
		//display: none;
		border-bottom: $border-width solid $border-color;
		padding: .5rem 1rem;
		.close {
			width: 25px;
			height: 25px;
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	.modal-footer {
		@include media-breakpoint-up(lg) {
			display: none;
		}
		@include media-breakpoint-down(lg) {
			max-height: 80px;
			min-height: 80px;
			height: 80px;
		}
	}	
	.modal-body {
		@include media-breakpoint-down(lg) {
			//overflow: initial;
		}
		@media only screen and (max-device-width : 768px) and (orientation : landscape) {
			max-height: 200px!important;		
		}
	}
	.modal-share {
		.modal-header {
			border-bottom: 1px solid transparent !important;
			.close {
				width: 35px !important;
    			height: 35px !important;
			}
		}
		.modal-footer {
			display: flex !important;
		}
	}
}

#trophy.User {
	.modal {
		&.loading {
			position: fixed !important;
			&::after {
				content: none !important;
			}
		}
	}
}

#emp_directory.User {
	.user-img-popup {
		border: $border-width solid $border-color;
			@include media-breakpoint-down(lg) {
				/*position: absolute;
				top: -75px;
				left: 0;
				right: 0;*/
				margin: 0 auto;
				border: 2px solid $white;
				border-radius: 50%;
				width: 124px;
				height: 124px;				
				background-color: $white; 
		}
		@include media-breakpoint-up(xl) {
			width: 100%;
			height: auto;
		}
		.img-fluid {
			@include media-breakpoint-down(lg) {
				border-radius: 50%;
				width: 120px;
				height: 120px;	
			}			
		}
	}
	.user-details-popup {
		@include media-breakpoint-down(lg) {
			margin-top: ($spacer*0.5);
			display: block;
			width: 100%;
		}
	}
	@include media-breakpoint-down(xl) {
		.modal-body {
			/*height: auto;
			max-height: fit-content;*/
			border-radius: $border-radius;
		}
		.modal,
		.modal-body {
			overflow: auto;
		}
	}
}

.lockBadge {
	@extend .badge-size;
	width: auto;
    height: auto;
}

.badge-description {
	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
		text-align: center;
	}
}

.img-mask-badge {
  /*margin: 0 auto;
  display: block;
  width: 100%;
  max-width: 200px;
  height: 150px;*/
 /* -webkit-mask-image: url(../../img/img-mask-badge.png);
  mask-image: url(../../img/img-mask-badge.png);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;*/
  position: absolute;
    top: 16%;
    left: 50%;
    transform: translate(-50%);
	background: $white;
    padding: .5rem;
	border-radius: $border-radius;
	text-align: center;
}

.img-mask-badge-shadow {
	/*position:relative;
    z-index: 2;
    height: 330px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	&::after {
		content: url('../../img/img-drop-shadow.png');
		margin-top: 1rem;
	}*/
}

.img-bg-course {
/*	-webkit-mask-image: url(../../img/wall-card-mask.jpg);
  mask-image: url(../../img/wall-card-mask.jpg);
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;*/
  
	/* Add the blur effect */
/*  filter: blur(4px);
  -webkit-filter: blur(4px);*/

  /* Full height */
  //height: 160px; 

  /* Center and scale the image nicely */
 /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover;*/
  //position: absolute;
  //width: 100%;
  
  //z-index:	1;
}

.img-bg-rewards {
	background: url(../../img/wall-card/bg-rewards.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 2rem;
	display: flex;
    justify-content: center;
}

.img-bg-badge {
	background: url(../../img/wall-card/bg-rewards.svg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 2rem;
	display: flex;
    justify-content: center;
	@include media-breakpoint-down(lg) {
		display: none
	}
}

.post-badge-view {
	@include media-breakpoint-down(lg) {
		background: url(../../img/wall-card/bg-rewards.svg);
		background-position: center;
		background-size: cover;	
		border-radius: $border-radius;	
		.img-mask-badge {
			position: inherit;
			background-color: transparent;
			padding: 2rem;
		}
	}
}

.badge-size-popup {
	@extend .badge-size;
	width: 230px;
	height: 195px;
	min-width: 230px;
	min-height: 195px;
	margin-right: $spacer;
	@include media-breakpoint-down(md) {
		margin: 0 auto 1rem;
		width: 160px;
		height: 140px;
		min-width: 160px;
		min-height: 140px;
	}
}

.certificate-size-popup {
	@extend .badge-size;
	@extend .badge-size-popup;
	background: rgba($ghost, .2);
	padding: inherit;
    align-items: center;
    justify-content: center;
    display: flex;
	border: $card-border-width solid $card-border-color;
	position: relative;
	overflow: inherit;
	@include border-radius($card-border-radius);
    @include media-breakpoint-down(lg) {
		@include border-radius($border-radius-xl);
	}
}



.bg-certificate-border {
	@extend .img-bg-rewards;
	padding: .5rem;
	border-radius: $border-radius-sm;
}

.badge-pill {
	width: 20px;
    height: 20px;
	display: flex;
	align-items: center;
}

.badge_title {
    margin-right: 5px;
}

.complete-badge-list-view {
	max-height: calc(100% - 1rem);
}

.complete-badge-list-scrollable {
	@include media-breakpoint-up(md) {
		max-height: 186px;
		overflow-y: scroll;
		backface-visibility: inherit;
	}
	@media only screen and (max-device-width : 768px) and (orientation : landscape) {
		max-height: inherit;
		overflow-y: hidden;
	}
}

.list-badge-complete {
    overflow-y: auto;
	overflow-x: hidden;
}

.reward-popup-block-mobile {
	max-height: 70vh;
    overflow: auto;
}

// user details round icons
.user-earned-section {
	display: flex;
	flex-direction: column;
	//width: 100%;
	//flex-basis: 0;
    text-align: center;
	align-items: center;
	min-width: 50px;
	@include media-breakpoint-down(lg) {
		min-width: 70px;
	}
}
.user-round-icons {
	display: flex;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	width: 50px;
	height: 50px;
	//margin: 0 auto;	
	margin-bottom: .25rem;
}

//
.img-width-80 {
	width: 80%; 
}

.card-active {
	display: block!important;
}


// Points
#points {
	.dataTables_wrapper {
		.dataTables_length {
			//float: left;		
			//margin-bottom: $spacer;
			display: none;
		}
		.dataTables_filter {
			//float: right;
			//margin-bottom: $spacer;
			display: none;
		}
		.dataTables_info {
			float: left;	
			margin-top: $spacer*1.5;
		}
		.dataTables_paginate {
			@include media-breakpoint-up(md) {
				float: right;
			}
			margin-top: $spacer;
			ul {
				margin-bottom: 0;
			}
		}
		.dataTables_info {
			@include media-breakpoint-down(md) {
				display: none;
			}
		}
		
		label {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				select {
					margin: 0 $spacer;
				}
				input {
					margin-left: $spacer;
				}
		}
		
		select,
		input	{
			 display: block;
			  width: 100%;
			  height: $input-height;
			  padding: $input-padding-y $input-padding-x;
			  font-family: $input-font-family;
			  @include font-size($input-font-size);
			  font-weight: $input-font-weight;
			  line-height: $input-line-height;
			  color: $input-color;
			  background-color: $input-bg;
			  background-clip: padding-box;
			  border: $input-border-width solid $input-border-color;

			  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
			  @include border-radius($input-border-radius, 0);

			  @include box-shadow($input-box-shadow);
			  @include transition($input-transition);

			  // Unstyle the caret on `<select>`s in IE10+.
			  &::-ms-expand {
				background-color: transparent;
				border: 0;
			  }

			  // Remove select outline from select box in FF
			  &:-moz-focusring {
				color: transparent;
				text-shadow: 0 0 0 $input-color;
			  }

			  // Customize the `:focus` state to imitate native WebKit styles.
			  //@include form-control-focus($ignore-warning: true);

			  // Placeholder
			  &::placeholder {
				color: $input-placeholder-color;
				// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
				opacity: 1;
			  }

			  // Disabled and read-only inputs
			  //
			  // HTML5 says that controls under a fieldset > legend:first-child won't be
			  // disabled if the fieldset is disabled. Due to implementation difficulty, we
			  // don't honor that edge case; we style them as disabled anyway.
			  &:disabled,
			  &[readonly] {
				background-color: $input-disabled-bg;
				// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
				opacity: 1;
			  }

		}
		
	}
	
	.form-control-search {
		@include media-breakpoint-down(lg) {
			width: 200px;
		}
		@include media-breakpoint-down(lg) {
			display: none;
		}
	}
	@include media-breakpoint-down(lg) {
		.tab-content {
			border-radius: $border-radius;
		}
	}
}

.table-icons {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		min-width: 40px;
		height: 40px;
		min-height: 40px;
		border-radius: 100%;		
		.feather {
			stroke-width: 1.5; 
		}
}

.table-icons-course {
	@extend .table-icons;
	background-color:$gray-200;
	.feather {
		stroke: $gray-400;
	}
}

.table-icons-right {
	@extend .table-icons;
	background-color:$success;
	.feather {
		stroke: $white;
	}
}

.table-inner-icon-size {
	.table-icons-right {
		@extend .table-icons;
		width: 40px;
		min-width: 40px;
		height: 40px;
		min-height: 40px;
	}
} 

.points-table-states {	
	text-align: center;
	min-width: 170px;
	max-width: 170px;
	width: 170px;
	height: 40px;
	border-radius: $border-radius-sm;
	display: flex;
	align-items: center;
	justify-content: center;
	//font-weight: $font-weight-medium;
	font-family: 'Poppins Medium';
}

.table-states-pass {
	@extend .points-table-states; 
	//border: 1px solid $success;
	color: $success;
}
.table-states-failed {
	@extend .points-table-states;
	//border: 1px solid $gull-gray;
	color: $danger;
}
.table-states-prgress {
	@extend .points-table-states;
	//border: 1px solid $cyan;
	color: $gull-gray;
}

.points-table-inner-color {
	background-color: rgba($ghost, .1);
}

.mobile-table-hide {
    @include media-breakpoint-down(lg) {
		display: none;
	}
}


.unlock-rewards-scroll {
	max-height: 69vh;
	overflow-y: auto;
}

/* tab separator can't be added
.nav-points-tabs {
	li.nav-item:nth-child(1) {
		.nav-link {
			&:after {
				content: "";
				width: 1px;
				height: 45px;
				background-color: $ghost;
				position: absolute;
				right: -1px;
			}
		}
		.active {
			&:after {
				content: none;
			}
		}
	}
	li.nav-item:nth-child(2) {
		.nav-link {
			&:after {
				content: "";
				width: 1px;
				height: 45px;
				background-color: $ghost;
				position: absolute;
				right: -1px;
			}
		}
		.active {
			&:after {
				content: none;
			}
		}
	}
}*/

.certificate-wrapper {
	/*width: 400px;
	height: 200px;
	overflow: hidden;*/
	//flex: inherit;
	width: 850px;
	
	//height: 533px;
	@include media-breakpoint-up(md) {
		transform: scale(1) !important;	
	}

}

// Trophy
.card-trophy {
	@extend .card-badge;
}

.trophy-size {
	@extend .badge-size;
}

.trophy-name {
	@extend .badge-name;
}

.trophy-image {
	@extend .badge-image;
}

.nav-points-filter {
	&::after {
		margin-left: auto;
	}
}

// certificate share
.certificate-share-block {
    bottom: 16px;
    right: 16px;
	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.certificate-img-backdrop {
	&:hover,
	&:focus {
		&::before {
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
		.certificate-share-block {
			@include media-breakpoint-up(lg) {
				display: block;
			}
		}
	}
	&::before {
		@include media-breakpoint-up(lg) {
			display: none;
		}	
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background: $white;
			background: linear-gradient(100deg, rgba($white,0) 0%, rgba($black,0.3) 70%);
			border-radius: $border-radius;
		
	}
}