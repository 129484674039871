/* Course Hover tooltips */
.field-tip {
    position:relative;
    //cursor:help;
	display: inline-block;
	&:hover {
		.tip-content {
			right:0;
			opacity:1;
			display: block;
		}
	}
}

.tip-content {
		position: absolute;
		top: 0px;
		left: 80px;
		width: 530px;
		//min-height: 270px;
		max-height: 470px;
		overflow: auto;
		padding: ($spacer*1.25); 
		color: $gray-500;
		background: $white;
		z-index: $zindex-tooltip;
        @include border-radius($border-radius-sm);
		@include box-shadow($course-card-box-shadow);
        opacity:0;
		font-size: $font-size-base;
		border: $card-border-width solid $card-border-color;
        /*-webkit-transition:opacity 250ms ease-out;
           -moz-transition:opacity 250ms ease-out;
            -ms-transition:opacity 250ms ease-out;
             -o-transition:opacity 250ms ease-out;
                transition:opacity 250ms ease-out;*/
				display: none;
		@include media-breakpoint-between(lg, xxl) {
				width: 440px;
				//min-height: 250px;
		}		
}

.tip-header {
	//font-size: $font-size-xl;
	color: $gray-600;
}

.tooltip-arrow {
	width: 0;
	height: 0;
	border-width: 5px 4px 0 4px;		
	border-style: solid;
	margin: 0 auto;
}

.tooltip-course {
    .tooltip-inner {
        background-color: $cyan;    
		box-shadow:  0 .5rem 1rem rgba($cyan, .20);
    }
	.tooltip-arrow {
		@extend .tooltip-arrow;
		border-color: $cyan transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($cyan, .20);
		bottom: 1px;
	}
}

.tooltip-badges {
    .tooltip-inner {
        background-color: $blue;    
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
    }
	.tooltip-arrow {
		@extend .tooltip-arrow;
		border-color: $blue transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
		bottom: 1px;
	}
}

.tooltip-certificates {
    .tooltip-inner {
        background-color: $purple;    
		box-shadow:  0 .5rem 1rem rgba($purple, .20);
		
    }
	.tooltip-arrow {
		@extend .tooltip-arrow;
		border-color: $purple transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($purple, .20);
		bottom: 1px;
	}
}

.tooltip-progress-bar {
    .tooltip-inner {
        background-color: $blue;    
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
		z-index: 1090;
    }
	.tooltip-arrow {
		@extend .tooltip-arrow;
		border-width: 0 4px 5px 4px !important;
		border-color:  transparent transparent $blue transparent;
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
		top: 1px;
	}
}

.tooltip-top-nav {
    .tooltip-inner {
        background-color: $white;    
		box-shadow:  0 .5rem 1rem rgba($white, .20);
		z-index: 1090;
		color: $primary;
    }
	.tooltip-arrow {
		border-width: 0 4px 5px 4px !important;
		border-color:  transparent transparent $white transparent;
		box-shadow:  0 .5rem 1rem rgba($white, .20);
		top: 1px;
	}
}


.tooltip-photo {
    .tooltip-inner {
        background-color: $blue;    
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
		z-index: 1090;
		position: absolute;
    	top: 5px;
    	width: 100px;
    }
	.tooltip-arrow {
		@extend .tooltip-arrow;
		border-width: 0 4px 5px 4px !important;
		border-color:  transparent transparent $blue transparent;
		box-shadow:  0 .5rem 1rem rgba($blue, .20);
		top: 1px;
		transform: translate(22px, 0px) !important;
	}
}

.tooltip-notification{

	&.tagUserTip{
		.tooltip-inner {
			box-shadow: 0px 3px 10px 0px #8C9BAC66;
		}
	}

	.tooltip-inner {
        background-color: $white;    
		box-shadow: 0px -3px 10px 0px #8C9BAC66;
		color: $primary;
		font-family: 'Poppins SemiBold';
    }
	.tooltip-arrow {
		border-color:  transparent transparent $white transparent;
		box-shadow:  0 .5rem 1rem #8C9BAC66;
		border-width: 0px;
		&::before{
			border-width: .4rem .4rem 0 !important;
			border-top-color:  $white;
		}
	}
}

.tooltip-user-point {
    .tooltip-inner {
        background-color: $french-pass;    
		box-shadow:  0 .5rem 1rem rgba($french-pass, .20);
        color:$blue;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $french-pass transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($french-pass, .20);
		bottom: 1px;
	}
}

.tooltip-user-discuss {
    .tooltip-inner {
        background-color: $egg-white;    
		box-shadow:  0 .5rem 1rem rgba($egg-white, .20);
		color:$yellow;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $egg-white transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($egg-white, .20);
		bottom: 1px;
	}
}

.tooltip-user-course {
    .tooltip-inner {
        background-color: $french-pass-two;    
		box-shadow:  0 .5rem 1rem rgba($french-pass-two, .20);
		color:$cerulean;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $french-pass-two transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($french-pass-two, .20);
		bottom: 1px;
	}
}

.tooltip-user-quiz {
    .tooltip-inner {
        background-color: $moon-raker;    
		box-shadow:  0 .5rem 1rem rgba($moon-raker, .20);
		color: $purple;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $moon-raker transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($moon-raker, .20);
		bottom: 1px;
	}
}

.tooltip-user-library {
    .tooltip-inner {
        background-color: $jagged-ice;    
		box-shadow:  0 .5rem 1rem rgba($jagged-ice, .20);
		color: $green;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $jagged-ice transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($jagged-ice, .20);
		bottom: 1px;
	}
}

.tooltip-user-game {
    .tooltip-inner {
        background-color: $cinderella;    
		box-shadow:  0 .5rem 1rem rgba($cinderella, .20);
		color: $froly;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $cinderella transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($cinderella, .20);
		bottom: 1px;
	}
}

.tooltip-user-trophy{
    .tooltip-inner {
        background-color: $amaranth-light;    
		box-shadow:  0 .5rem 1rem rgba($amaranth-light, .20);
		color: $amaranth;
    }
	.tooltip-arrow {
		width: 0;
        height: 0;
        border-width: 5px 4px 0 4px;		
        border-style: solid;
        margin: 0 auto;
		border-color: $amaranth-light transparent transparent transparent;
		box-shadow:  0 .5rem 1rem rgba($amaranth-light, .20);
		bottom: 1px;
	}
}

