// Divider
.divider {
	background-color: $divider-bg;
	margin: $divider-item-margin;
	height: 1px;
}

.dotted-divider {
	height: 1px;
	border-bottom: 1.5px dotted $border-color;
	margin: $divider-item-margin;
}

.dotted-lg-divider {
	@include media-breakpoint-down(lg) {
		height: 1px;
		border-bottom: 1.5px dotted $border-color;
		margin: $divider-item-margin-md;
	}
}

.md-divider {
	@extend .divider;
	margin: $spacer;
	position: relative;
}  

.sm-divider {
	@extend .divider;
	margin: 0;
}

.h-divider {
	width: 1px;
	//background-color: $ghost;
	height: 100%;
}

.dashed-divider {
	border-top: $border-width dashed $border-color;
	height: 1px;
}
.line-divider {
	border-top: $border-width solid $border-color;
	height: 1px;
} 