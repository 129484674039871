.card-points { 
    .icon-points {
        display: flex;
        align-items: center;
        justify-content: center;        
        border-radius: 100%;        
    }
    .points-icons {
        width: 70px;
        min-width: 70px;
        height: 70px;
        min-height: 70px;
        .feather {
            width: 30px;
            height: 30px;
            stroke-width: 1.5;
        }
    }
    .points-icons-md {
        width: 60px;
        min-width: 60px;
        height: 60px;
        min-height: 60px;
    }
    .bg-french-pass {
        @extend .icon-points;
        background-color: $french-pass;
        .feather {
            stroke: $blue;
        }
    }
    .bg-french-pass-two {
        @extend .icon-points;
        background-color: $french-pass-two;
        .feather {
            stroke: $cerulean;
        }
    }
    .bg-moon-raker {
        @extend .icon-points;
        background-color: $moon-raker;
        .feather {
            stroke: $purple;
        }
    }
    .bg-jagged-ice {
        @extend .icon-points;
        background-color: $jagged-ice;
        .feather {
            stroke: $green;
        }
    }
    .bg-egg-white {
        @extend .icon-points;
        background-color: $egg-white;
        .feather {
            stroke: $yellow;
        }
    }
    .bg-cinderella {
        @extend .icon-points;
        background-color: $cinderella;
        .feather {
            stroke: $froly;
        }
    }
    .bg-amaranth {
        @extend .icon-points;
        background-color: rgba($amaranth, .3); // $amaranth
        .feather {
            stroke: $amaranth;
        }
    }
    .bg-light {
        @extend .icon-points;
        background-color: rgba($gray-400, $alpha: .3) !important;
        .feather {
            stroke: $gray-600;
        }
    }
}